import React from 'react';

import { compose } from 'redux';

import PERMISSIONS from 'constants/user/permissions';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import withConfigFlagGuard from 'shared/guard/withConfigFlagGuard';
import withRolesGuard from 'shared/guard/withRolesGuard';

import ForwardToCompeonWithConfirmation from './ForwardToCompeonWithConfirmation';
import ForwardToCompeonWithoutConfirmation from './ForwardToCompeonWithoutConfirmation';

const ForwardToCompeonManager = () => {
  const isConfirmationNecessary = useConfig(CONFIG.IS_INQUIRY_CUSTOMER_CONFIRMATION_ENABLED);

  return isConfirmationNecessary ? (
    <ForwardToCompeonWithConfirmation />
  ) : (
    <ForwardToCompeonWithoutConfirmation />
  );
};

export default compose<React.ComponentType>(
  withRolesGuard(PERMISSIONS.INQUIRY.FORWARD_TO_CUSTOMER),
  withConfigFlagGuard(CONFIG.IS_COMPEON_FORWARDING_ENABLED),
)(ForwardToCompeonManager);
