import React from 'react';

import {
  LOAN_TERM_IN_MONTHS,
  PURPOSE_KIND__GOODS,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__REAL_ESTATE,
  PURPOSE_KIND__OTHER,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
} from 'modules/Inquiry/Form/formFields';

import DurationInMonthYearDropDown from '../../shared/DurationInMonthYearDropDown/DurationInMonthYearDropDown';
import { PurposeKindSpecificValidationMap } from '../../shared/types';

const purposeKindSpecificValidationMap: PurposeKindSpecificValidationMap = {
  [PURPOSE_KIND__GOODS]: {
    min: 1,
    max: 6 * 12,
  },
  [PURPOSE_KIND__VEHICLE]: {
    min: 2 * 12,
    max: 6 * 12,
  },
  [PURPOSE_KIND__MACHINE]: {
    min: 2 * 12,
    max: 8 * 12,
  },
  [PURPOSE_KIND__REAL_ESTATE]: {
    min: 5 * 12,
    max: 35 * 12,
  },
  [PURPOSE_KIND__OTHER]: {
    min: 1 * 12,
    max: 6 * 12,
  },
};

const DvagLoanDuration = () => {
  return (
    <DurationInMonthYearDropDown
      SpecificValidationMap={purposeKindSpecificValidationMap}
      fieldName={LOAN_TERM_IN_MONTHS}
      data-testid={LOAN_TERM_IN_MONTHS}
      saveValueInFieldName={LOAN_INTEREST_PERIOD_IN_MONTHS}
    />
  );
};

export default DvagLoanDuration;
