import React from 'react';

import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import HirePurchaseAmortisationKind from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/HirePurchase/fields/HirePurchaseAmortisationKind';
import HirePurchaseDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/HirePurchase/fields/HirePurchaseDescription';
import HirePurchaseInterestedInInvestmentLoan from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/HirePurchase/fields/HirePurchaseInterestedInInvestmentLoan';
import HirePurchaseResidualValue from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/HirePurchase/fields/HirePurchaseResidualValue';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import HirePurchaseSummary from './HirePurchaseSummary';
import AdvancedDeposit from '../shared/AdvancedDeposit';

const HirePurchase = () => {
  const t = useTranslations();
  return (
    <FormSection
      name={FormSections.hirePurchase}
      title={t('pages.requestDetails.sections.hirePurchase.title')}
    >
      <AdvancedDeposit />
      <HirePurchaseSummary />

      <FormRow>
        <HirePurchaseAmortisationKind />
        <HirePurchaseResidualValue />
      </FormRow>
      <FormRow>
        <HirePurchaseDescription />
      </FormRow>
      <FormRow>
        <HirePurchaseInterestedInInvestmentLoan />
      </FormRow>
      <InterestedInInsurance />
    </FormSection>
  );
};

export default HirePurchase;
