import { useSelector } from 'react-redux';

import paths from 'constants/paths';
import { UserRole } from 'constants/user/userRoles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  getCompanyId,
  getInquiryFormTypeSelector,
  getInquiryIdSelector,
} from 'store/inquiryDetails/selectors';
import { useTranslations } from 'utils/hooks/useTranslations';
import { mapRouteDeclarationToNavLink } from 'utils/routeUtils';
import { hasPermission as hasPermissionSelector } from 'utils/user/conditionals';

enum InquiryDetailsCompanyDetailsRouteName {
  Overview = 'overview',
  AssociatedPersons = 'associatedPersons',
  RiskAnalysis = 'riskAnalysis',
  FinancialInformation = 'financialInformation',
}

export interface InquiryDetailsCompanyDetailsRouteDeclaration {
  name: InquiryDetailsCompanyDetailsRouteName;
  path: string;
  title: string;
  permission?: UserRole[];
}

const {
  operation: {
    inquiryDetails: { companyDetails: companyPaths },
  },
} = paths;

// FIXME: pass companyId from Navigation component and turn companisDetailsRoutes into function which returns routes with correct companyId in path
const companiesDetailsRoutes = [
  {
    name: InquiryDetailsCompanyDetailsRouteName.Overview,
    path: companyPaths.overview(),
    title: 'pages.companiesDetails.overview.title',
  },
  {
    name: InquiryDetailsCompanyDetailsRouteName.AssociatedPersons,
    path: companyPaths.associatedPersons(),
    title: 'pages.companiesDetails.associatedPerson.title',
  },
  {
    name: InquiryDetailsCompanyDetailsRouteName.RiskAnalysis,
    path: companyPaths.riskAnalysis(),
    title: 'pages.companiesDetails.riskAnalysis.title',
  },
  {
    name: InquiryDetailsCompanyDetailsRouteName.FinancialInformation,
    path: companyPaths.financialInformation(),
    title: 'pages.companiesDetails.financialInformation.title',
  },
];

const defaultRoutes = [
  InquiryDetailsCompanyDetailsRouteName.Overview,
  InquiryDetailsCompanyDetailsRouteName.AssociatedPersons,
  InquiryDetailsCompanyDetailsRouteName.RiskAnalysis,
  InquiryDetailsCompanyDetailsRouteName.FinancialInformation,
];

const inquiryTypeRoutes: {
  [type in InquiryType]: InquiryDetailsCompanyDetailsRouteName[];
} = {
  [InquiryType.default]: defaultRoutes,
  [InquiryType.bigPicture]: defaultRoutes,
  [InquiryType.onlinefactoring]: defaultRoutes,
  [InquiryType.leaseplan]: defaultRoutes,
  [InquiryType.bfs]: defaultRoutes,
  [InquiryType.profiRLL]: defaultRoutes,
  [InquiryType.profiMittweida]: defaultRoutes,
  [InquiryType.dvag]: defaultRoutes,
  [InquiryType.bfsService]: defaultRoutes,
  [InquiryType.mmv]: defaultRoutes,
  [InquiryType.dzb]: [
    InquiryDetailsCompanyDetailsRouteName.Overview,
    InquiryDetailsCompanyDetailsRouteName.AssociatedPersons,
  ],
  [InquiryType.hausbank]: [
    InquiryDetailsCompanyDetailsRouteName.Overview,
    InquiryDetailsCompanyDetailsRouteName.AssociatedPersons,
  ],
};

const getRouteDeclaration = (
  routeName: InquiryDetailsCompanyDetailsRouteName,
): InquiryDetailsCompanyDetailsRouteDeclaration | undefined =>
  companiesDetailsRoutes.find((route) => route.name === routeName);

export const useInquiryDetailsCompanyDetailsRoutes = () => {
  const inquiryType = useSelector(getInquiryFormTypeSelector) as InquiryType;
  const t = useTranslations();
  const hasPermission = useSelector(hasPermissionSelector);
  const inquiryId = useSelector(getInquiryIdSelector);
  const companyId = useSelector(getCompanyId);
  const routes = inquiryTypeRoutes[inquiryType] ?? inquiryTypeRoutes.default;
  const routeDeclarations = routes
    .map(getRouteDeclaration)
    .filter(Boolean) as InquiryDetailsCompanyDetailsRouteDeclaration[];

  return mapRouteDeclarationToNavLink(routeDeclarations, t, hasPermission, companyId, inquiryId);
};
