import * as React from 'react';

import { Box, GridItem } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { userIsCustomer } from 'utils/user/conditionals';

import { InquiryType } from '../../../../../modules/Inquiry/Inquiry.type';
import {
  chooseSelectedInquiryTypeSpecificValue,
  useSelectedInquiryTypeSpecificValue,
} from '../../../../../shared/chooseSelectedInquiryTypeSpecificComponent';
import { getExternalReference } from '../../../../../store/inquiryDetails/selectors';
import {
  DescriptionList,
  DescriptionListDescription,
  DescriptionListTerm,
} from '../../../../../theme/components/DescriptionList';
import { HeadingComponent } from '../../../../../theme/components/Heading';
import { useTranslations } from '../../../../../utils/hooks/useTranslations';
import { useSummaryCardData } from '../useSummaryCardData';

export const FinancingProductItem = () => {
  const t = useTranslations();
  const data = useSummaryCardData();
  const externalRef = useSelector(getExternalReference);
  const isCustomer = userIsCustomer();

  const financingProduct = useSelectedInquiryTypeSpecificValue({
    [InquiryType.profiMittweida]: data.role,
    [InquiryType.bfsService]: data.purpose,
    [InquiryType.dvag]: `${data.product}${t(
      'pages.inquiryDetails.dashboard.summary.purposeProductSeparator',
    )}${data.purpose}`,
    [InquiryType.hausbank]: data.product,
    default: `${data.product}${
      !isCustomer
        ? `${t('pages.inquiryDetails.dashboard.summary.purposeProductSeparator')}${data.purpose}`
        : ''
    }`,
  });

  const flexStyleProps = chooseSelectedInquiryTypeSpecificValue({
    [InquiryType.bfs]: {
      justifyContent: 'start',
    },
    [InquiryType.default]: {
      justifyContent: 'space-between',
    },
  });

  return (
    <GridItem>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        paddingBottom={3}
        {...flexStyleProps}
      >
        <HeadingComponent as="h4" variant="hidden">
          {t('pages.inquiryDetails.detailed.summary.title')}
        </HeadingComponent>

        <Box>
          <DescriptionList mb={1}>
            <DescriptionListTerm isHidden>
              {t('pages.inquiryDetails.dashboard.summary.financingProduct')}
            </DescriptionListTerm>
            <DescriptionListDescription>{financingProduct}</DescriptionListDescription>
          </DescriptionList>

          <DescriptionList size="large" mb={8}>
            <DescriptionListTerm isHidden>
              {t('pages.inquiryDetails.detailed.summary.financingRequirement')}
            </DescriptionListTerm>
            <DescriptionListDescription>{data.amount}</DescriptionListDescription>
          </DescriptionList>
        </Box>

        <DescriptionList size="small">
          <DescriptionListTerm mr={3}>
            {t('pages.inquiryDetails.dashboard.summary.id')}
          </DescriptionListTerm>
          <DescriptionListDescription>{data.id}</DescriptionListDescription>
        </DescriptionList>
        {!isCustomer && externalRef && (
          <DescriptionList size="small">
            <DescriptionListTerm mr={3}>
              {t('pages.inquiryDetails.dashboard.summary.externalReference')}
            </DescriptionListTerm>
            <DescriptionListDescription>{externalRef}</DescriptionListDescription>
          </DescriptionList>
        )}
      </Box>
    </GridItem>
  );
};
