import React from 'react';

import { useFormConfig } from 'config/formConfig/hooks';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import {
  INQUIRY_STATUSES,
  INQUIRY_SUBSTATUSES,
  useInquiryStatusTranslator,
} from 'modules/Inquiry/inquiryStatuses';
import { INQUIRY_TABLE_HEADERS } from 'modules/InquiryTable/constants';
import { formatDateDays, formatDateTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { formatPrice, formatPriceForFactoringLine } from 'utils/valueFormats';

import { translations } from '../../../new/form/common/types';

export const useInquiryTableHeaders = () => {
  const t = useTranslations();
  const { selectedInquiryType } = useFormConfig();
  const translateHeader = useTranslations('pages.inquiryList.table.headers');
  const translateProduct = useTranslations('data.financingProduct');
  const translateCountry = useTranslations('pages.inquiryList.table.countries');
  const translateRole = useTranslations('data.financingRole');
  const translateMittweidaRole = useTranslations(
    translations.inquiryType.mittweida.pages.financingNeed.sections.role.fields.projectFinancingRole
      .options.sectionString,
  );
  const translatePurpose = useTranslations('data.purposeKind');
  const translateStatus = useInquiryStatusTranslator();

  const notAvailable = t('other.notAvailable');
  const printProcessorData = (processor: { firstName: string; lastName: string }) =>
    `${processor.firstName} ${processor.lastName}`;

  const valueOrNotAvailable = (value: string) => value || notAvailable;

  const idRowValue = (id: string) => (
    <span title={id} data-testid="operation-inquiry-row-id">
      {id.split('-')[0]}
    </span>
  );

  const createdAtRowValue = (createdDate: Date) => (
    <p>
      {formatDateDays(createdDate)}
      <br />
      {formatDateTime(createdDate)}
    </p>
  );

  const defaultHeaders = {
    [INQUIRY_TABLE_HEADERS.ID]: {
      key: INQUIRY_TABLE_HEADERS.ID,
      text: translateHeader('id'),
      renderRow: (id: string) => idRowValue(id),
    },
    [INQUIRY_TABLE_HEADERS.CREATED_AT]: {
      key: INQUIRY_TABLE_HEADERS.CREATED_AT,
      text: translateHeader('createdAt'),
      renderRow: (createdDate: Date) => createdAtRowValue(createdDate),
    },
    [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: {
      key: INQUIRY_TABLE_HEADERS.COMPANY_NAME,
      text: translateHeader('companySpecialized'),
      renderRow: (companyName: string) => valueOrNotAvailable(companyName),
    },
    [INQUIRY_TABLE_HEADERS.INQUIRY_MANAGER]: {
      key: INQUIRY_TABLE_HEADERS.INQUIRY_MANAGER,
      text: t('pages.inquiryList.table.headers.inquiryManager'),
      renderRow: (inquiryManagerData: { firstName: string; lastName: string }) =>
        inquiryManagerData
          ? `${inquiryManagerData.firstName} ${inquiryManagerData.lastName}`
          : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: {
      key: INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
      text: translateHeader('usage'),
      renderRow: (purpose: { firstName: string; lastName: string }) =>
        purpose ? translatePurpose(purpose) : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT]: {
      key: INQUIRY_TABLE_HEADERS.FINANCING_PRODUCT,
      text: translateHeader('financingProduct'),
      renderRow: (product: string) => (product ? translateProduct(product) : notAvailable),
    },
    [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: {
      key: INQUIRY_TABLE_HEADERS.FINANCING_NEED,
      text: translateHeader('financingNeed'),
      renderRow: (amount: number) =>
        amount ? <strong>{formatPrice(amount)}</strong> : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.PROCESSOR]: {
      key: INQUIRY_TABLE_HEADERS.PROCESSOR,
      text: translateHeader('processor'),
      renderRow: (processor: { firstName: string; lastName: string }) =>
        processor ? printProcessorData(processor) : notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.STATUS]: {
      key: INQUIRY_TABLE_HEADERS.STATUS,
      text: translateHeader('status'),
      renderRow: (status: INQUIRY_STATUSES) => translateStatus(status),
    },
    [INQUIRY_TABLE_HEADERS.SUB_STATUS]: {
      key: INQUIRY_TABLE_HEADERS.SUB_STATUS,
      text: translateHeader('status'),
      renderRow: (status: INQUIRY_SUBSTATUSES) => translateStatus(status),
    },
    [INQUIRY_TABLE_HEADERS.EXPECTED_GRANT_AMOUNT]: {
      key: INQUIRY_TABLE_HEADERS.EXPECTED_GRANT_AMOUNT,
      text: translateHeader('expectedGrantAmount'),
      renderRow: (sum: number) => formatPrice(sum) ?? notAvailable,
    },
    [INQUIRY_TABLE_HEADERS.EXPECTED_ELIGIBLE_COSTS]: {
      key: INQUIRY_TABLE_HEADERS.EXPECTED_ELIGIBLE_COSTS,
      text: translateHeader('expectedEligibleCosts'),
      renderRow: (sum: number) => formatPrice(sum) ?? notAvailable,
    },

    [INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE]:
      selectedInquiryType === InquiryType.leaseplan
        ? {
            key: INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE,
            text: translateHeader('externalReference'),
            renderRow: (externalReference: string) => externalReference ?? notAvailable,
          }
        : {
            key: INQUIRY_TABLE_HEADERS.EXTERNAL_REFERENCE,
            text: translateHeader('externalReference'),
            renderRow: (sum: number) => formatPrice(sum) ?? notAvailable,
          },

    [INQUIRY_TABLE_HEADERS.VEHICLE_MANUFACTURER]: {
      key: INQUIRY_TABLE_HEADERS.VEHICLE_MANUFACTURER,
      text: translateHeader('vehicleManufacturer'),
      renderRow: (vehicleManufacturer: string) => vehicleManufacturer ?? notAvailable,
    },

    [INQUIRY_TABLE_HEADERS.VEHICLE_KIND]: {
      key: INQUIRY_TABLE_HEADERS.VEHICLE_KIND,
      text: translateHeader('vehicleKind'),
      renderRow: (vehicleKind: string) => vehicleKind ?? notAvailable,
    },

    [INQUIRY_TABLE_HEADERS.VEHICLE_DRIVE_TYPE]: {
      key: INQUIRY_TABLE_HEADERS.VEHICLE_DRIVE_TYPE,
      text: translateHeader('vehicleDriveType'),
      renderRow: (vehicleDriveType: string) => vehicleDriveType ?? notAvailable,
      notSortable: true,
    },
    [INQUIRY_TABLE_HEADERS.CONTRACT_NUMBER]: {
      key: INQUIRY_TABLE_HEADERS.CONTRACT_NUMBER,
      text: translateHeader('contractNumber'),
      renderRow: (contractNumber: string) => <b>{contractNumber}</b>,
    },
    [INQUIRY_TABLE_HEADERS.OBJECT_DESCRIPTION]: {
      key: INQUIRY_TABLE_HEADERS.OBJECT_DESCRIPTION,
      text: translateHeader('objectDescription'),
      renderRow: (objectDescription: string) => objectDescription,
    },
    [INQUIRY_TABLE_HEADERS.OBJECT_VALUE]: {
      key: INQUIRY_TABLE_HEADERS.OBJECT_VALUE,
      text: translateHeader('objectValue'),
      renderRow: (objectValue: number) => formatPrice(objectValue),
    },
    [INQUIRY_TABLE_HEADERS.SUPPLIER]: {
      key: INQUIRY_TABLE_HEADERS.SUPPLIER,
      text: translateHeader('supplier'),
      renderRow: (supplier: string) => supplier,
    },
    [INQUIRY_TABLE_HEADERS.AGENT]: {
      key: INQUIRY_TABLE_HEADERS.AGENT,
      text: translateHeader('agent'),
      renderRow: (agent: string) => agent,
    },
    [INQUIRY_TABLE_HEADERS.ZIP_CODE_AREA]: {
      key: INQUIRY_TABLE_HEADERS.ZIP_CODE_AREA,
      text: translateHeader('zipCodeArea'),
      renderRow: (zipCodeArea: string) => zipCodeArea,
    },
  };
  const headersByType: Record<string, any> = {
    [InquiryType.leaseplan]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.ID]: {
        key: INQUIRY_TABLE_HEADERS.ID,
        text: translateHeader('onboardingId'),
        renderRow: (id: string) => idRowValue(id),
      },
    },
    [InquiryType.profiRLL]: {
      [INQUIRY_TABLE_HEADERS.ID]: {
        key: INQUIRY_TABLE_HEADERS.ID,
        text: translateHeader('id'),
        renderRow: (id: string) => idRowValue(id),
      },
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: {
        key: INQUIRY_TABLE_HEADERS.CREATED_AT,
        text: translateHeader('createdAt'),
        renderRow: (createdDate: Date) => createdAtRowValue(createdDate),
      },
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: {
        key: INQUIRY_TABLE_HEADERS.COMPANY_NAME,
        text: translateHeader('companySpecialized'),
        renderRow: (companyName: string) => valueOrNotAvailable(companyName),
      },
      [INQUIRY_TABLE_HEADERS.PURPOSE_KIND]: {
        key: INQUIRY_TABLE_HEADERS.PURPOSE_KIND,
        text: translateHeader('usage'),
        renderRow: (purpose: { firstName: string; lastName: string }) =>
          purpose ? translatePurpose(purpose) : notAvailable,
      },
      [INQUIRY_TABLE_HEADERS.FINANCING_ROLE]: {
        key: INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
        text: translateHeader('financingRole'),
        renderRow: (role: string) => (role ? translateRole(role) : notAvailable),
      },
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: {
        key: INQUIRY_TABLE_HEADERS.PROCESSOR,
        text: translateHeader('processor'),
        renderRow: (processor: { firstName: string; lastName: string }) =>
          processor ? printProcessorData(processor) : notAvailable,
      },
      [INQUIRY_TABLE_HEADERS.SUB_STATUS]: {
        key: INQUIRY_TABLE_HEADERS.SUB_STATUS,
        text: translateHeader('status'),
        renderRow: (status: INQUIRY_SUBSTATUSES) => translateStatus(status),
      },
    },
    [InquiryType.profiMittweida]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.FINANCING_ROLE]: {
        key: INQUIRY_TABLE_HEADERS.FINANCING_ROLE,
        text: translateHeader('financingRole'),
        renderRow: (role: string) => {
          return role ? translateMittweidaRole(role) : notAvailable;
        },
      },
    },
    [InquiryType.dvag]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.VB_NAME]: {
        key: INQUIRY_TABLE_HEADERS.VB_NAME,
        text: translateHeader('vbName'),
        renderRow: (vb: { firstName: string; lastName: string }) =>
          vb ? `${vb.firstName} ${vb.lastName}` : notAvailable,
      },
    },
    [InquiryType.mmv]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.ID]: {
        ...defaultHeaders[INQUIRY_TABLE_HEADERS.ID],
        text: translateHeader('process'),
      },
      [INQUIRY_TABLE_HEADERS.VPP_ID]: {
        key: INQUIRY_TABLE_HEADERS.VPP_ID,
        text: translateHeader('vppId'),
        renderRow: (vppId: string) => <b>{vppId}</b>,
      },
    },
    [InquiryType.dzb]: {
      [INQUIRY_TABLE_HEADERS.ID]: {
        key: INQUIRY_TABLE_HEADERS.ID,
        text: translateHeader('process'),
        renderRow: (id: string) => idRowValue(id),
      },
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: {
        key: INQUIRY_TABLE_HEADERS.CREATED_AT,
        text: translateHeader('createdAt'),
        renderRow: (createdDate: Date) => createdAtRowValue(createdDate),
      },
      [INQUIRY_TABLE_HEADERS.PARTNER_REFERENCE_ID]: {
        key: INQUIRY_TABLE_HEADERS.PARTNER_REFERENCE_ID,
        text: translateHeader('gpNumber'),
        renderRow: (partnerReferenceId: string) => valueOrNotAvailable(partnerReferenceId),
      },
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: {
        key: INQUIRY_TABLE_HEADERS.COMPANY_NAME,
        text: translateHeader('gpName'),
        renderRow: (companyName: string) => valueOrNotAvailable(companyName),
      },
      [INQUIRY_TABLE_HEADERS.ASSOCIATION_NAME]: {
        key: INQUIRY_TABLE_HEADERS.ASSOCIATION_NAME,
        text: translateHeader('association'),
        renderRow: (associationName: string) => valueOrNotAvailable(associationName),
      },
      [INQUIRY_TABLE_HEADERS.COMPANY_COUNTRY]: {
        key: INQUIRY_TABLE_HEADERS.COMPANY_COUNTRY,
        text: translateHeader('country'),
        renderRow: (country: string) => valueOrNotAvailable(translateCountry(country)),
      },
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: {
        key: INQUIRY_TABLE_HEADERS.PROCESSOR,
        text: translateHeader('processor'),
        renderRow: (processor: { firstName: string; lastName: string }) =>
          processor ? printProcessorData(processor) : notAvailable,
      },
      [INQUIRY_TABLE_HEADERS.STATUS]: {
        key: INQUIRY_TABLE_HEADERS.STATUS,
        text: translateHeader('status'),
        renderRow: (status: INQUIRY_STATUSES) => translateStatus(status),
      },
    },
    [InquiryType.hausbank]: {
      [INQUIRY_TABLE_HEADERS.ID]: {
        key: INQUIRY_TABLE_HEADERS.ID,
        text: translateHeader('id'),
        renderRow: (id: string) => idRowValue(id),
      },
      [INQUIRY_TABLE_HEADERS.CREATED_AT]: {
        key: INQUIRY_TABLE_HEADERS.CREATED_AT,
        text: translateHeader('createdAt'),
        renderRow: (createdDate: Date) => createdAtRowValue(createdDate),
      },
      [INQUIRY_TABLE_HEADERS.COMPANY_NAME]: {
        key: INQUIRY_TABLE_HEADERS.COMPANY_NAME,
        text: translateHeader('companySpecialized'),
        renderRow: (companyName: string) => valueOrNotAvailable(companyName),
      },
      [INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_NAME]: {
        key: INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_NAME,
        text: translateHeader('homeownerAssociationName'),
        renderRow: (homeownerAssociationName: string) =>
          valueOrNotAvailable(homeownerAssociationName),
      },
      [INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_ZIPCODE]: {
        key: INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_ZIPCODE,
        text: translateHeader('zipCode'),
        renderRow: (homeownerAssociationZipcode: string) =>
          valueOrNotAvailable(homeownerAssociationZipcode),
      },
      [INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_CITY]: {
        key: INQUIRY_TABLE_HEADERS.HOMEOWNER_ASSOCIATION_CITY,
        text: translateHeader('city'),
        renderRow: (homeownerAssociationCity: string) =>
          valueOrNotAvailable(homeownerAssociationCity),
      },
      [INQUIRY_TABLE_HEADERS.FINANCING_NEED]: {
        key: INQUIRY_TABLE_HEADERS.FINANCING_NEED,
        text: translateHeader('financingAmount'),
        renderRow: (financingAmount: number) =>
          financingAmount ? <strong>{formatPrice(financingAmount)}</strong> : notAvailable,
      },
      [INQUIRY_TABLE_HEADERS.PROCESSOR]: {
        key: INQUIRY_TABLE_HEADERS.PROCESSOR,
        text: translateHeader('processor'),
        renderRow: (processor: { firstName: string; lastName: string }) =>
          processor ? printProcessorData(processor) : notAvailable,
      },
      [INQUIRY_TABLE_HEADERS.STATUS]: {
        key: INQUIRY_TABLE_HEADERS.STATUS,
        text: translateHeader('status'),
        renderRow: (status: INQUIRY_STATUSES) => translateStatus(status),
      },
    },
    [InquiryType.bfsService]: {
      ...defaultHeaders,
      [INQUIRY_TABLE_HEADERS.FACTORING_LINE]: {
        key: INQUIRY_TABLE_HEADERS.FACTORING_LINE,
        text: translateHeader('factoringLine'),
        renderRow: (res: { line: string; factoringLine: string }) =>
          res.line === InquiryLane.contract
            ? formatPriceForFactoringLine(res.factoringLine, true) ?? notAvailable
            : res.factoringLine
            ? formatPriceForFactoringLine(res.factoringLine, true)
            : t('data.financingProduct.factoringLine.lead'),
      },
    },
    default: defaultHeaders,
  };

  return headersByType[selectedInquiryType] || headersByType.default;
};
