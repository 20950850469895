import * as React from 'react';

import { translations } from 'new/form/common/types';
import { Page } from 'new/form/Page';

import { CompanyDetailsSection } from './sections/companyDetails';
import { CompletingCompanyDetailsSection } from './sections/completingCompanyDetailsSection';

export const MittweidaCompanyStep = () => {
  return (
    <Page
      translationStringForTitle={
        translations.inquiryType.mittweida.pages.companyDetails.progressBarTitle
      }
    >
      <CompanyDetailsSection />
      <CompletingCompanyDetailsSection />
    </Page>
  );
};
