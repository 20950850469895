import React from 'react';

import {
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  USER_PROFILE_INDUSTRY,
  BFS_USER_PROFILE_INDUSTRY,
  PARTNER_SPECIFIC_IBAN,
  COMPANY_DETAILS_COMPANY_TAX_NUMBER,
  USER_PROFILE_COMPANY_NAME,
  USER_PROFILE_PHONE_NUMBER,
  USER_PROFILE_LEGAL_FORM,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import DvagCompanyDetailsSection from 'modules/InquiryDetails/DetailedInformation/CompanyDetailsSection/DvagCompanyDetailsSection';
import OnlineFactoringCompanyDetailsSection from 'modules/InquiryDetails/DetailedInformation/CompanyDetailsSection/OnlineFactoringCompanyDetailsSection';
import Section from 'modules/InquiryDetails/DetailedInformation/DetailedInformationSection';
import InformationRows from 'modules/InquiryDetails/DetailedInformation/InformationRows/InformationRows';
import useInquiryDetailsFields from 'modules/InquiryDetails/DetailedInformation/useInquiryDetailsFields';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { chooseConfigSpecificValue } from 'shared/chooseConfigSpecific';
import {
  chooseSelectedInquiryTypeSpecificComponent,
  useSelectedInquiryTypeSpecificValue,
} from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useTranslations } from 'utils/hooks/useTranslations';

const CompanyDetailsSection = () => {
  const t = useTranslations('pages.inquiryDetails.detailed');
  const excludedFieldsByConfig = chooseConfigSpecificValue({
    [CONFIG.IS_ADDITIONAL_ASSOCIATION_COMPANY_ENABLED]: [],
    default: [USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY],
  });

  const excludedFieldsByInquiryType = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: [
      USER_PROFILE_LEGAL_FORM,
      PARTNER_SPECIFIC_IBAN,
      USER_PROFILE_PHONE_NUMBER,
    ],
    [InquiryType.bfs]: [USER_PROFILE_INDUSTRY],
    default: [
      BFS_USER_PROFILE_INDUSTRY,
      COMPANY_DETAILS_COMPANY_TAX_NUMBER,
      USER_PROFILE_COMPANY_NAME,
    ],
  });

  const excludedFields = excludedFieldsByConfig.concat(excludedFieldsByInquiryType);

  const includedFieldsByInquiryType = useSelectedInquiryTypeSpecificValue({
    [InquiryType.bfsService]: [USER_PROFILE_COMPANY_NAME],
    default: [],
  });

  const fields = useInquiryDetailsFields({
    sectionId: INQUIRY_SECTIONS.COMPANY,
    excludedFields,
    includeFields: includedFieldsByInquiryType,
  });

  if (!fields.length) {
    return null;
  }

  return (
    <Section title={t('companyDetails')}>
      <InformationRows fields={fields} />
    </Section>
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagCompanyDetailsSection,
  [InquiryType.onlinefactoring]: OnlineFactoringCompanyDetailsSection,
  default: CompanyDetailsSection,
});
