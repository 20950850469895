import * as React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  useMultiStyleConfig,
} from '@chakra-ui/react';

import { HeadingComponent } from '../Heading';

type ScrollBehaviorType = 'inside' | 'outside';
type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

export type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  scrollBehavior?: ScrollBehaviorType;
  size?: SizeType;
  title?: string;
  testId?: string;
  footer?: React.ReactNode;
  children: React.ReactNode;
  closeOnOverlayClick?: boolean;
};

export const ModalComponent = ({
  isOpen,
  onClose,
  scrollBehavior = 'outside',
  size = 'xl',
  title,
  footer,
  children,
  testId,
  closeOnOverlayClick = true,
}: ModalProps) => {
  const styles = useMultiStyleConfig('Modal', {
    variant: size,
  });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      preserveScrollBarGap
      scrollBehavior={scrollBehavior}
      size={size}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay />

      <ModalContent sx={styles.content} data-testid={testId}>
        <ModalHeader>
          {title && (
            <HeadingComponent color="brand.default" variant="secondary">
              {title}
            </HeadingComponent>
          )}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>{children}</ModalBody>

        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
};
