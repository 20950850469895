import React from 'react';

import { bool, string } from 'prop-types';
import ReactDOM from 'react-dom';
import gtmParts from 'react-google-tag-manager';

import { CSP_NONCE } from 'utils/sessionStorage/keys';

// deep tracking with `useTracking({id: scriptId, update: pushUpdate})`
// const pushUpdate = (id, pathname) => {
//   if (window.gtag) {
//    window.gtag('config', id, { page_path: pathname });
//   }
// };

class GoogleTagManager extends React.Component {
  componentDidMount() {
    const { dataLayerName } = this.props;

    if (!window[dataLayerName]) {
      this.createGtmScriptTagInHead();
    }
  }

  createGtmScriptTagInHead = () => {
    const { scriptId } = this.props;
    const script = document.createElement('script');
    const gtmScriptNode = document.getElementById(scriptId);
    const scriptText = document.createTextNode(gtmScriptNode.textContent);

    script.appendChild(scriptText);
    const cspNonce = sessionStorage.getItem(CSP_NONCE);
    script.setAttribute('nonce', cspNonce);
    script.setAttribute('data-nonce', cspNonce);

    if (document.head.prepend) {
      document.head.prepend(script);
    } else {
      document.head.appendChild(script);
    }
    // release content of placeholder span using to generate script tag
    // if not released GoogleTagManager call warning to not nest script tag
    gtmScriptNode.textContent = '';
  };

  render() {
    const { dataLayerName, gtmId, previewVariables, scriptId } = this.props;
    const { body } = document;
    const gtm = gtmParts({
      id: gtmId,
      dataLayerName,
      previewVariables,
    });

    const child = (
      <>
        {gtm.noScriptAsReact()}
        <span id={scriptId}>{gtm.scriptAsReact()}</span>
      </>
    );
    return ReactDOM.createPortal(child, body);
  }
}

GoogleTagManager.propTypes = {
  gtmId: string.isRequired,
  scriptId: string,
  dataLayerName: string,
  previewVariables: bool,
};

GoogleTagManager.defaultProps = {
  scriptId: 'react-google-tag-manager-gtm',
  dataLayerName: 'dataLayer',
  previewVariables: false,
};

export default GoogleTagManager;
