import React from 'react';

import { Divider } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { Asset } from 'components/Asset';
import { PRODUCT_KIND } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFormValues } from 'store/hooks/useFormValues';
import { getMeta } from 'store/progress/selectors';
import { HeadingComponent } from 'theme/components/Heading';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { FinalPageAction } from './FinalPageAction';
import { conditionalStepList, iconMap, STEPS } from '../stepService';

const StaticActions = () => {
  const t = useTranslations('pages.finalPage.static.steps');
  const metaData = useSelector(getMeta);
  const product = useFormValues()[PRODUCT_KIND];

  const stepList = conditionalStepList(metaData?.fastlaneEligible, String(product));

  const steps = stepList.map((value: STEPS) => (
    <React.Fragment key={value}>
      <Divider opacity="1" mt={8} mb={8} borderColor="border.lightGrey" />
      <FinalPageAction asset={<Asset type={iconMap[value].type} value={iconMap[value].key} />}>
        <HeadingComponent as="h3" variant="secondary" mb={4}>
          {t(value + '.header')}
        </HeadingComponent>
        <TextComponent mb={6}>{t(value + '.description')}</TextComponent>
      </FinalPageAction>
    </React.Fragment>
  ));
  return <>{steps}</>;
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: StaticActions,
  default: () => null,
});
