import React from 'react';

import moment from 'moment';

import Condition from 'modules/ConditionalSubform/Condition';
import { requestDetailsConditions } from 'modules/Inquiry/Form/Conditions/dvag/requestDetails.conditions';
import {
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  MACHINE_INTERESTED_IN_INSURANCE,
  REQUEST_DETAILS,
  VEHICLE_INTERESTED_IN_INSURANCE,
  LOAN_TYPE,
  LOAN_TYPE__ANNUITY,
  LEASING_AMORTISATION_KIND,
  LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  LEASING_TAKEOVER,
  LEASING_TAKEOVER__UNDECIDED,
  FAVORED_DECISION_DATE,
} from 'modules/Inquiry/Form/formFields';
import BankSearch from 'modules/Inquiry/Form/Steps/RequestDetails/BankSearch/BankSearch';
import ProductKind from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKind/ProductKind';
import ProductKindDetailsSection from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/ProductKindDetailsSection';
import { useRequestDetailsSectionValidators } from 'modules/Inquiry/Form/Validations/default/useRequestDetailsSectionValidators';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import DisabledSections from 'pages/inquiryFlow/RequestDetails/DisabledSections/DisabledSections';
import Heading from 'pages/inquiryFlow/RequestDetails/Heading/Heading';
import SectionWrapper from 'pages/inquiryFlow/RequestDetails/SectionWrapper';
import { useSendLead } from 'pages/inquiryFlow/RequestDetails/useSendLead';

const initialFormValues = {
  [VEHICLE_INTERESTED_IN_INSURANCE]: false,
  [MACHINE_INTERESTED_IN_INSURANCE]: false,
  [LEASING_INTERESTED_IN_CREDIT_FINANCING]: false,
  [HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN]: false,
  [INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS]: false,
  [LOAN_TYPE]: LOAN_TYPE__ANNUITY,
  [LEASING_AMORTISATION_KIND]: LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  [LEASING_TAKEOVER]: LEASING_TAKEOVER__UNDECIDED,
  [FAVORED_DECISION_DATE]: moment().add(90, 'days').format('YYYY-MM-DD'),
};

const DvagRequestDetails = () => {
  useSendLead();

  const getSectionValidators = useRequestDetailsSectionValidators();

  const { isProductKindSelected, shouldShowCommonSections } = requestDetailsConditions;

  return (
    <InquiryFormNew
      initialValues={initialFormValues}
      headerComp={<Heading />}
      currentStepName={REQUEST_DETAILS}
      getSectionValidators={getSectionValidators}
    >
      {() => (
        <>
          <SectionWrapper>
            <ProductKind />
          </SectionWrapper>

          <Condition
            condition={isProductKindSelected}
            renderOnFail={() => <DisabledSections numOfSections={3} />}
          >
            <ProductKindDetailsSection />

            <Condition
              condition={shouldShowCommonSections}
              onFail={<DisabledSections numOfSections={1} offset={1} />}
            >
              <BankSearch sectionNumber={2} />
            </Condition>
          </Condition>
        </>
      )}
    </InquiryFormNew>
  );
};

export default DvagRequestDetails;
