import * as React from 'react';

import { CheckboxWithField } from 'components/Checkbox';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import paths from 'constants/paths';
import {
  COMPANY_DETAILS_CONSENT,
  COMPANY_DETAILS_DATA_SECURITY,
} from 'modules/Inquiry/Form/formFields';
import { translations } from 'new/form/common/types';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

const {
  title,
  fields: { companyDetailsConsent, companyDetailsDataSecurity },
} = translations.pages.companyDetails.sections.termsAndConditions.bigPicture;

interface BigPictureTermsAndConditionsProps {
  sectionNumber?: number;
}
export const BigPictureTermsAndConditions = ({
  sectionNumber,
}: BigPictureTermsAndConditionsProps) => {
  const { required, mustBeTrue } = useFieldValidators();
  const t = useTranslations();

  const formContent = (
    <>
      <FormRow>
        <CheckboxWithField
          name={COMPANY_DETAILS_CONSENT}
          validate={combineValidators(required, mustBeTrue)}
          text={
            <span>
              {t(companyDetailsConsent.label, {
                termsAndConditions: (
                  <a href={paths.termsAndConditions}>
                    {t(companyDetailsConsent.links.termsAndConditions)}
                  </a>
                ),
              })}
            </span>
          }
        />
      </FormRow>
      <FormRow>
        <CheckboxWithField
          name={COMPANY_DETAILS_DATA_SECURITY}
          validate={combineValidators(required, mustBeTrue)}
          text={
            <span>
              {t(companyDetailsDataSecurity.label, {
                dataSecurity: (
                  <a href={paths.privacyPolicy}>
                    {t(companyDetailsDataSecurity.links.dataSecurity)}
                  </a>
                ),
              })}
            </span>
          }
        />
      </FormRow>
    </>
  );

  if (sectionNumber) {
    return (
      <FormSection sectionNumber={sectionNumber} title={t(title)}>
        {formContent}
      </FormSection>
    );
  }

  return formContent;
};
