import { AnyObject, FormApi } from 'final-form';
import createDecorator from 'final-form-focus';

import { TOP_BAR_HEIGHT } from 'constants/globalConstants';
import {
  Focusable,
  FocusableInputWithDataName,
  FocusableWithDataName,
} from 'new/form/scrollToError';
import { scrollTo } from 'utils/scroll';

import { SectionValidator } from './types';

const SCROLL_OFFSET_FOR_LABEL = 80;

const getFieldNameForStandardAndArrayFields = ([fieldName, errorValue]: [
  string,
  Record<string, string>,
]) => {
  if (Array.isArray(errorValue)) {
    const nonNullErrors = errorValue.filter((err) => err !== null);
    if (nonNullErrors.length > 0) {
      const firstError = nonNullErrors[0];
      const keys = Object.keys(firstError);
      if (keys.length > 0) {
        return keys[0];
      }
    }
  }
  return fieldName;
};

const getNamesOfInvalidFields = (errors: AnyObject) =>
  Object.entries(errors)
    .filter(([key]) => errors[key])
    .map(getFieldNameForStandardAndArrayFields) || [];

const getFirstNotValidElement = (inputs: Focusable[], errors: AnyObject) => {
  const invalidFields = getNamesOfInvalidFields(errors);

  return inputs.find((input) => {
    let fieldName: string | undefined = input.name;

    // Check if fieldName can already be found. This should be true for all new field names
    if (fieldName) {
      return invalidFields.includes(fieldName);
    }

    // If the field is not found, try to extract the field name from the old field name schema
    fieldName = fieldName.split('.').pop();

    // Check if old schema field name is found
    if (fieldName) {
      return invalidFields.includes(fieldName);
    }

    // If old schema field name is not found, try to extract the field name from the data-name attribute and check again
    const dataName = (input as FocusableWithDataName)['data-name'];
    if (dataName) {
      return invalidFields.includes(dataName);
    }
    return false;
  }) as FocusableInputWithDataName;
};

export const scrollToFirstNotValidElement = (inputs: Focusable[], errors: object) => {
  const firstNotValidFieldElement = getFirstNotValidElement(inputs, errors as AnyObject);

  if (!firstNotValidFieldElement) {
    return undefined;
  }

  const inputOffsetTop = firstNotValidFieldElement.getBoundingClientRect().top;

  const scrollValue = window.scrollY + inputOffsetTop - TOP_BAR_HEIGHT - SCROLL_OFFSET_FOR_LABEL;

  // use smooth scroll instead of default one
  scrollTo(window, scrollValue);

  // There is small issue in chrome that makes preventScroll not work
  // workaround is to add it to event loop so this ugly workaround works :(
  // if you will find better solution, please do so
  setTimeout(() => firstNotValidFieldElement.focus({ preventScroll: true }), 0);

  // since create decorator doesn't allow me to prevent scroll on default focus behaviour
  // I do it on my own higher in this func
  return undefined;
};

const createFocusDecorator = () => createDecorator(undefined, scrollToFirstNotValidElement);

export const focusDecorator = createFocusDecorator();

export const areSectionsValid = (sectionValidators: SectionValidator[], form: FormApi) =>
  !sectionValidators.find((validator) => !validator(form));
