import React from 'react';

import { useFieldValue, useFormValues } from 'store/hooks/useFormValues';

interface ComponentFormValue {
  step: string;
  field: string;
  value: string | number | boolean;
  component: React.FC;
  valueFromCurrentStep?: boolean;
}

export const chooseFormValueSpecificComponent =
  (componentMap: ComponentFormValue[], defaultComponent: React.FC) =>
  (props: any): React.ReactNode => {
    let Component = defaultComponent;

    componentMap.forEach(({ step, field, value, component, valueFromCurrentStep }) => {
      if (valueFromCurrentStep) {
        if (value === useFieldValue<string>(field, '')) {
          Component = component;
        }
      }
      if (value === useFieldValue<string>(field, '')) {
        Component = component;
      }
    });

    return <Component {...props} />;
  };

export const useFormValueSpecificValue = (
  field: string,
  valueMap: { [key: string]: unknown },
  defaultValue: any = null,
) => {
  const fieldValue = useFormValues()[field] as string;

  return valueMap[fieldValue] || valueMap.default || defaultValue;
};
