import { AnyAction } from 'redux';

import { IBankOffer } from 'models/BankOffer.model';
import { ICompeonOffer } from 'models/CompeonOffer.model';
import {
  IFastlaneState,
  IInquiryDetails,
  IInquiryEditDetails,
  InquiryDetailsType,
} from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { CompeonStatus } from 'models/types/CompeonStatus.type';
import {
  OFFER_FACTORING_FEE,
  OFFER_FACTORING_LINE,
  OFFER_INSTALLMENT_RATE,
  OFFER_MONTHLY_INSTALLMENT,
  OFFER_PAYOUT_RATIO,
  USER_PROFILE_BALANCE_SHEET,
  USER_PROFILE_DETAILED_ANNUAL_TURNOVER,
  USER_PROFILE_EMPLOYEE_COUNT,
} from 'modules/Inquiry/Form/formFields';
import {
  ACTION,
  CompanySize,
} from 'modules/Inquiry/Form/Steps/FinancingNeed/PurposeDetails/CoronaDetailsSection/otherKfwProgram/useFetchKfwProgram';
import { SET_INQUIRY_FORWARDED_TO_INSURANCE_SUCCESS } from 'modules/InquiryDetails/DetailedInformation/ForwardInquiryToInsurance/forwardToInquiryInsurance.service';
import {
  mapCompeonFilesFromApi,
  mapCompeonStateFromApi,
} from 'modules/InquiryDetails/InquiryDetails.service';
import { INQUIRY_SECTIONS } from 'modules/InquiryDetails/InquirySections.model';
import { UPLOAD_FILES_SUCCESS } from 'modules/Offers/InquiryOffersSection/CompeonOffers/CompeonOfferAccept/service';
import { OFFER_STATUS } from 'modules/Offers/InquiryOffersSection/constants';
import { setStatusSingleOfferInList } from 'modules/Offers/InquiryOffersSection/service';
import { ADDITIONAL_FIELDS_SUCCESS } from 'pages/customerPortal/InquiryDetails/AdditionalFields/useAdditionalFieldsCall';
import { ACTIONS } from 'pages/inquiryFlow/SummaryPage/Fastlane/actions';
import { getUpdateState } from 'utils/storeHelpers';
import {
  formatAsPercentage,
  formatPriceForFactoringLine,
  replaceDotWithComma,
} from 'utils/valueFormats';

import {
  SET_INQUIRY_DETAILS,
  RESET_INQUIRY_DETAILS,
  SET_BANK_OFFERS,
  SET_COMPEON_OFFERS,
  ACCEPT_COMPEON_OFFER,
  ACCEPT_BANK_OFFER_SUCCESS,
  SET_INQUIRY_EDIT_DETAILS,
  CLEAR_INQUIRY_DETAILS,
  SHOW_INTEREST_IN_OFFER_SUCCESS,
  SET_ALLOW_DIGITAL_PROCESSING_SUCCESS,
  SET_LEGAL_REPRESENTATIVES_ACCEPTED_TRUE,
  BINDING_OFFER_SUCCESS,
  TRIGGER_CREFO_SUCCESS,
  TRIGGER_KYC_SUCCESS,
  OFFER_CALCULATION_SUCCESS,
  INCONSISTENCY_REPORT_SUCCESS,
  TRANSPARENCY_REGISTER_PICK_ORDER_SUCCESS,
  GET_COMPEON_INQUIRY_SUCCESS,
  INVITE_CUSTOMER_SUCCESS,
  MARKET_DATA_SENT,
  ASSIGNED_REAL_ESTATE_EXPERT,
  IS_QUICK_CHECK_STARTED,
  RESET_OFFER_CALCULATION,
  GET_COMPEON_INQUIRY_STATE_SUCCESS,
} from './actions';

export interface IInquiryDetailsState {
  details: Partial<InquiryDetailsType> | null;
  editDetails: IInquiryEditDetails | null;
  bankOffers: IBankOffer[];
  compeonOffers: ICompeonOffer[];
  kfwProgram: {
    companySize: CompanySize;
    companyAge: number;
    kfwProgram: string;
    isLoading: boolean;
  };
  fastLane: IFastlaneState;
  externalId?: string;
  compeonStatus: CompeonStatus;
}

const initialState: IInquiryDetailsState = {
  details: null,
  editDetails: null,
  bankOffers: [],
  compeonOffers: [],
  kfwProgram: {
    companySize: CompanySize.small,
    companyAge: 0,
    kfwProgram: '',
    isLoading: false,
  },
  fastLane: {
    areDocumentsMandatory: false,
    canDisplayFastlaneDocuments: false,
    fastlaneInquiryFileRequests: [],
    isFastlaneLoading: true,
    isQualifiedForFastlane: false,
    fastlaneErrors: {},
    errorCheckingFastlane: false,
  },
  externalId: '',
  compeonStatus: CompeonStatus.new,
};

export const inquiryDetailsReducer = (
  state: IInquiryDetailsState = initialState,
  action: AnyAction,
): IInquiryDetailsState => {
  const updateState = getUpdateState<IInquiryDetailsState>(state);

  switch (action.type) {
    case SET_INQUIRY_DETAILS:
      return updateState({
        details: {
          ...action.payload,
          dashboardActions: {
            ...action.payload.dashboardActions,
            indicativeConditionCompleted:
              action.payload.dashboardActions?.indicativeConditionCompleted,
            indicativeOffer: action.payload.dashboardActions?.indicativeOffer,
            kycTriggered: action.payload.dashboardActions?.kycTriggered,
          },
          compeonDocument: [],
        },
        externalId: action.payload.externalId,
        compeonStatus: action.payload.compeonStatus,
      });
    case RESET_INQUIRY_DETAILS:
      return updateState(initialState);
    case SET_BANK_OFFERS:
      return updateState({ bankOffers: action.payload });
    case SET_COMPEON_OFFERS:
      return updateState({
        compeonOffers: action.payload
          ? action.payload.map((offer: ICompeonOffer) => ({
              ...offer,
              filesRequiredByBank: offer.filesRequiredByBank.map((file) => ({
                ...file,
                uploaded: false,
              })),
            }))
          : [],
      });
    case ACCEPT_COMPEON_OFFER:
      return updateState({
        compeonOffers: setStatusSingleOfferInList(
          state.compeonOffers,
          action.payload,
          OFFER_STATUS.ACCEPTED,
        ) as ICompeonOffer[],
      });
    case ACCEPT_BANK_OFFER_SUCCESS:
      return updateState({
        bankOffers: setStatusSingleOfferInList(
          state.bankOffers,
          action.payload.data.data.id,
          OFFER_STATUS.ACCEPTED,
        ) as IBankOffer[],
      });
    case SHOW_INTEREST_IN_OFFER_SUCCESS:
      return updateState({
        compeonOffers: state.compeonOffers.map((offer) => ({
          ...offer,
          hasShownInterest:
            offer.id === action.payload.data.data.id ? true : offer.hasShownInterest,
        })),
      });
    case SET_INQUIRY_FORWARDED_TO_INSURANCE_SUCCESS:
      return updateState({
        details: { ...state.details, wasForwardedToInsurance: true },
      });
    case SET_INQUIRY_EDIT_DETAILS:
      return updateState({ editDetails: action.payload });
    case CLEAR_INQUIRY_DETAILS:
      return initialState;
    case ACTION.GET_KFW_PROGRAM:
      return updateState({
        kfwProgram: {
          ...state.kfwProgram,
          isLoading: true,
        },
      });
    case ACTION.GET_KFW_PROGRAM_SUCCESS:
      return updateState({
        kfwProgram: {
          companySize: action.payload.data.data.attributes.company_size,
          companyAge: action.payload.data.data.attributes.company_age,
          kfwProgram: action.payload.data.data.attributes.kfw_program,
          isLoading: false,
        },
      });
    case ACTIONS.SET_IS_QUALIFIED_FOR_FASTLANE:
      return updateState({
        fastLane: {
          ...state.fastLane,
          isQualifiedForFastlane: action.data,
        },
      });
    case ACTIONS.SET_FASTLANE_LOADING:
      return updateState({
        fastLane: {
          ...state.fastLane,
          isFastlaneLoading: action.data,
        },
      });
    case ACTIONS.SET_ARE_DOCUMENTS_MANDATORY:
      return updateState({
        fastLane: {
          ...state.fastLane,
          areDocumentsMandatory: action.data,
        },
      });
    case ACTIONS.SET_FASTLANE_INQUIRY_FILE_REQUESTS:
      return updateState({
        fastLane: {
          ...state.fastLane,
          fastlaneInquiryFileRequests: action.data,
          canDisplayFastlaneDocuments: Boolean(action.data.length),
        },
      });
    case ACTIONS.SET_FASTLANE_ERRORS:
      return updateState({
        fastLane: {
          ...state.fastLane,
          fastlaneErrors: action.data,
        },
      });
    case ACTIONS.SET_ERROR_CHECKING_FASTLANE:
      return updateState({
        fastLane: {
          ...state.fastLane,
          errorCheckingFastlane: true,
          isFastlaneLoading: false,
          isQualifiedForFastlane: false,
        },
      });
    case ACTIONS.RESET_STATE:
      return updateState({
        fastLane: {
          areDocumentsMandatory: false,
          canDisplayFastlaneDocuments: false,
          fastlaneInquiryFileRequests: [],
          isFastlaneLoading: true,
          isQualifiedForFastlane: false,
          fastlaneErrors: {},
          errorCheckingFastlane: false,
        },
      });
    case BINDING_OFFER_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            indicativeConditionCompleted: true,
            indicativeOffer: {
              [OFFER_FACTORING_FEE]: formatAsPercentage(
                action.payload.data.data.attributes.factoring_fee,
              ).toString(),
              [OFFER_FACTORING_LINE]: formatPriceForFactoringLine(
                action.payload.data.data.attributes.factoring_line,
                false,
              ),
              [OFFER_INSTALLMENT_RATE]: action.payload.data.data.attributes.instalment_rate,
              [OFFER_MONTHLY_INSTALLMENT]: action.payload.data.data.attributes.monthly_instalment,
              [OFFER_PAYOUT_RATIO]: action.payload.data.data.attributes.payout_ratio,
            },
          },
        },
      });

    case ADDITIONAL_FIELDS_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          [INQUIRY_SECTIONS.COMPANY]: {
            ...(state.details as IInquiryDetails)[INQUIRY_SECTIONS.COMPANY],
            [USER_PROFILE_EMPLOYEE_COUNT]: action.payload.data.data.attributes.count_of_employees,
            [USER_PROFILE_BALANCE_SHEET]:
              action.payload.data.data.attributes.detailed_balance_sheet,
            [USER_PROFILE_DETAILED_ANNUAL_TURNOVER]:
              action.payload.data.data.attributes.detailed_annual_turnover,
          },
        },
      });

    case UPLOAD_FILES_SUCCESS:
      return updateState({
        compeonOffers: state.compeonOffers.map((offer) => ({
          ...offer,
          filesRequiredByBank: offer.filesRequiredByBank.map((file) => ({
            ...file,
            uploaded:
              file.name === action.payload.data.data.attributes['virtual-path']
                ? true
                : file.uploaded,
          })),
        })),
      });

    case SET_ALLOW_DIGITAL_PROCESSING_SUCCESS:
      return updateState({
        details: { ...state.details, allowDigitalProcessing: true },
      });

    case SET_LEGAL_REPRESENTATIVES_ACCEPTED_TRUE:
      return updateState({
        details: { ...state.details, legalRepresentativesAccepted: true },
      });

    case TRIGGER_CREFO_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            crefoTriggered: true,
          },
        },
      });

    case TRIGGER_KYC_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            kycTriggered: true,
          },
        },
      });
    case INVITE_CUSTOMER_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            customerInvitationSend: true,
          },
        },
      });
    case TRANSPARENCY_REGISTER_PICK_ORDER_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            transparencyRegister: {
              ...state.details?.dashboardActions?.transparencyRegister,
              selectedOrderId: action.payload.data.data.attributes.selected_order_id,
            },
          },
        },
      });

    case INCONSISTENCY_REPORT_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            inconsistencyReportUrl: action.payload.data.links.inconsistency_report.href,
          },
        },
      });

    case OFFER_CALCULATION_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            indicativeOfferCalculations: {
              factoringFee: !action.payload.data.factoring_fee
                ? 'N/A'
                : `${replaceDotWithComma(
                    formatAsPercentage(action.payload.data.factoring_fee).toString(),
                  )}%`,
              factoringLine: !action.payload.data.factoring_line
                ? 'N/A'
                : formatPriceForFactoringLine(action.payload.data.factoring_line, true),
              payoutRate: !action.payload.data.payout_rate
                ? 'N/A'
                : `${action.payload.data.payout_rate}%`,
            },
          },
        },
      });

    case RESET_OFFER_CALCULATION:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            indicativeOfferCalculations: undefined,
          },
        },
      });

    case MARKET_DATA_SENT:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            marketDataSent: true,
          },
        },
      });

    case ASSIGNED_REAL_ESTATE_EXPERT:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            assignedRealEstateExpert: true,
          },
        },
      });

    case IS_QUICK_CHECK_STARTED:
      return updateState({
        details: {
          ...state.details,
          dashboardActions: {
            ...state.details?.dashboardActions,
            isQuickCheckStarted: true,
          },
        },
      });

    case GET_COMPEON_INQUIRY_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          compeonDocument: mapCompeonFilesFromApi(action.payload.data),
        },
      });

    case GET_COMPEON_INQUIRY_STATE_SUCCESS:
      return updateState({
        details: {
          ...state.details,
          compeonState: mapCompeonStateFromApi(action.payload.data),
        },
      });

    default:
      return state;
  }
};
