import React from 'react';

import { Field, useForm } from 'react-final-form';

import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import {
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  BFS_SERVICE__PURPOSE_KIND,
  PRE_FINANCING_PERIOD,
  PURPOSE_KIND,
  PURPOSE_KIND_BFS_SERVICE,
} from 'modules/Inquiry/Form/formFields';
import { useInquirySelectOptions } from 'modules/Inquiry/inquiryFieldsTranslations/useInquirySelectValues';
import { fieldBusinessConditions } from 'schema/inquirySchema.conditions';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';

import { bfsServiceKindOptions } from './bfsServicePurposeKindOptions';

interface PurposeKindDropDownPropType {
  name: string;
}

const PurposeKindDropDown: React.FC<PurposeKindDropDownPropType> = ({ name }) => {
  const t = useTranslations();
  const purposeKindOptions = useInquirySelectOptions(BFS_SERVICE__PURPOSE_KIND);
  const form = useForm();
  const { required } = useFieldValidators();

  const onChange = (fieldValue: string) => {
    const selectedKind = bfsServiceKindOptions.find(
      (option) => option.value === fieldValue,
    )?.purposeKind;
    form.change(PURPOSE_KIND, selectedKind);
    form.change(BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING, null);
    form.change(BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON, null);
    const formValues = form.getState().values;
    if (fieldBusinessConditions.isBFSFactomatAndPatientTransport({ formValues })) {
      form.change(PRE_FINANCING_PERIOD, '60');
    } else {
      form.change(PRE_FINANCING_PERIOD, null);
    }
  };

  return (
    <FormRow>
      {/* We have to add the PURPOSE_KIND field to the form so that we can change its value */}
      <Field name={PURPOSE_KIND} render={() => null} />
      <SelectWithField
        caption={t('pages.bfsService.sections.industry.title')}
        name={PURPOSE_KIND_BFS_SERVICE}
        placeholder={t('placeholders.pleaseChoose')}
        options={purposeKindOptions}
        tooltip={t('pages.bfsService.sections.industry.tooltip')}
        data-testid={'select-field' + PURPOSE_KIND_BFS_SERVICE}
        validate={required}
        onChange={onChange}
        preventEnterInInput
      />
    </FormRow>
  );
};

export default PurposeKindDropDown;
