import { ReactNode } from 'react';

import { useSelector } from 'react-redux';

import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import { UserRole } from 'constants/user/userRoles';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { CompanyRoutes } from 'pages/operationPortal/InquiryDetails/CompanyRoutes';
import { DashboardRouter } from 'pages/operationPortal/InquiryDetails/Dashboard';
import DetailedInformationPage from 'pages/operationPortal/InquiryDetails/DetailedInformation';
import OperationDocumentExchange from 'pages/operationPortal/InquiryDetails/DocumentExchange/OperationDocumentExchange';
import { getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';

import { PlanningEvaluationRoutes } from './PlanningEvaluationRoutes';

export enum OperationRouteName {
  Dashboard = 'dashboard',
  DocExchange = 'documents',
  Company = 'company',
  DetailedInformation = 'details',
  PlanningEvaluation = 'real-estate',
}

export interface OperationsRouteDeclaration {
  name: OperationRouteName; // $name-link will be used as data-testid for testing
  path: string;
  component: ReactNode;
  title: string; // it is used for navigation link
  permission?: UserRole[];
}

// FIXME: pass companyId from Navigation component and turn companisDetailsRoutes into function which returns routes with correct companyId in path
const opRoutes: OperationsRouteDeclaration[] = [
  {
    name: OperationRouteName.Dashboard,
    path: paths.operation.inquiryDetails.dashboard,
    // FIXME this is incorrectly typed
    // @ts-ignore
    component: DashboardRouter,
    title: 'pages.inquiryDetails.navigation.dashboard',
  },
  {
    name: OperationRouteName.DocExchange,
    path: paths.operation.inquiryDetails.documentExchange,
    // FIXME this is incorrectly typed
    // @ts-ignore
    component: OperationDocumentExchange,
    title: 'pages.inquiryDetails.navigation.documentExchange',
  },
  {
    name: OperationRouteName.Company,
    path: paths.operation.inquiryDetails.companyDetails.root(),
    // FIXME this is incorrectly typed
    // @ts-ignore
    component: CompanyRoutes,
    title: 'pages.inquiryDetails.navigation.companyDetails',
    permission: PERMISSIONS.COMPANY_DETAILS.DISPLAY as UserRole[],
  },
  {
    name: OperationRouteName.PlanningEvaluation,
    path: paths.operation.inquiryDetails.planningEvaluation.root(),
    // FIXME this is incorrectly typed
    // @ts-ignore
    component: PlanningEvaluationRoutes,
    title: 'pages.inquiryDetails.navigation.planningEvaluation',
  },
  {
    name: OperationRouteName.DetailedInformation,
    path: paths.operation.inquiryDetails.details,
    // FIXME this is incorrectly typed
    // @ts-ignore
    component: DetailedInformationPage,
    title: 'pages.inquiryDetails.navigation.details',
  },
];

const defaultRoutes = [
  OperationRouteName.Dashboard,
  OperationRouteName.DetailedInformation,
  OperationRouteName.DocExchange,
];
// Order is relevant for navigation
const inquiryTypeRoutes: { [type in InquiryType]: OperationRouteName[] } = {
  [InquiryType.bigPicture]: [
    OperationRouteName.Dashboard,
    OperationRouteName.DetailedInformation,
    OperationRouteName.DocExchange,
    OperationRouteName.Company,
  ],
  [InquiryType.onlinefactoring]: [
    OperationRouteName.Dashboard,
    OperationRouteName.DetailedInformation,
    OperationRouteName.DocExchange,
    OperationRouteName.Company,
  ],
  [InquiryType.default]: defaultRoutes,
  [InquiryType.profiRLL]: [...defaultRoutes, OperationRouteName.PlanningEvaluation],
  [InquiryType.dzb]: [...defaultRoutes, OperationRouteName.Company],
  [InquiryType.profiMittweida]: [...defaultRoutes, OperationRouteName.PlanningEvaluation],
  [InquiryType.leaseplan]: [OperationRouteName.Dashboard, OperationRouteName.DetailedInformation],
  [InquiryType.bfs]: [
    OperationRouteName.Dashboard,
    OperationRouteName.DocExchange,
    OperationRouteName.DetailedInformation,
    OperationRouteName.Company,
  ],
  [InquiryType.dvag]: defaultRoutes,
  [InquiryType.bfsService]: defaultRoutes,
  [InquiryType.mmv]: defaultRoutes,
  [InquiryType.hausbank]: [
    OperationRouteName.Dashboard,
    OperationRouteName.DetailedInformation,
    OperationRouteName.DocExchange,
    OperationRouteName.Company,
  ],
};

const getRouteDeclaration = (
  routeName: OperationRouteName,
): OperationsRouteDeclaration | undefined => opRoutes.find((route) => route.name === routeName);

export const useOperationDetailsRoutes = (): OperationsRouteDeclaration[] => {
  const inquiryType = useSelector(getInquiryFormTypeSelector) as InquiryType;
  const routes: OperationRouteName[] = inquiryTypeRoutes[inquiryType] ?? inquiryTypeRoutes.default;
  return routes.map(getRouteDeclaration).filter(Boolean) as OperationsRouteDeclaration[];
};
