import {
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_LAST_NAME,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_CONSENT,
  USER_PROFILE_PHONE_NUMBER,
  USER_PROFILE_PASSWORD,
  STRUCTURE_NUMBER,
  ADP_NUMBER,
  USER_PROFILE_BIRTHDATE,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_GENDER,
  CONTACT_PERSON_FIRST_NAME,
  CONTACT_PERSON_LAST_NAME,
  CONTACT_PERSON_PHONE,
  CONTACT_PERSON_SECURITY_CONSENT,
} from 'modules/Inquiry/Form/formFields';
import { conditionalObject } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { InquiryFormMapper } from 'modules/Inquiry/mapInquiryToApi/InquiryFormMapper.model';
import { isLoggedInUser } from 'utils/user/conditionals';

import { InquiryProfiFormMapper } from '../InquiryProfiFormMapper.model';

const userData = (allFields: any) => {
  return {
    salutation: allFields[COMPANY_DETAILS_GENDER],
    'first-name': allFields[COMPANY_DETAILS_FIRST_NAME],
    'last-name': allFields[COMPANY_DETAILS_LAST_NAME],
    email: allFields[COMPANY_DETAILS_EMAIL],
    'terms-and-conditions-accepted': allFields[COMPANY_DETAILS_CONSENT],
    'phone-number': allFields[USER_PROFILE_PHONE_NUMBER],
    password: allFields[USER_PROFILE_PASSWORD],
    'birth-date': allFields[USER_PROFILE_BIRTHDATE],
    [STRUCTURE_NUMBER]: allFields[STRUCTURE_NUMBER],
    [ADP_NUMBER]: allFields[ADP_NUMBER],
  };
};

export const mapBFSServiceUserDetailsToApi = (allFields: any, isLoggedIn: boolean) => {
  if (isLoggedIn) {
    // user data is taken from token
    return {};
  }

  return {
    'user-attributes': userData(allFields),
  };
};

export const mapUserDetailsToApi: InquiryFormMapper = (allFields) => {
  const isLoggedIn = isLoggedInUser();

  if (isLoggedIn) {
    // user data is taken from token
    return {};
  }

  return {
    'user-attributes': userData(allFields),
  };
};

export const mapBigPictureUserToApi = (
  allFields: any,
  isLoggedIn: boolean,
  isBankAdvisor = false,
) => {
  if (isLoggedIn) {
    return {
      ...conditionalObject(!isBankAdvisor, {
        'user-attributes': {
          'birth-date': allFields[USER_PROFILE_BIRTHDATE],
        },
      }),
      ...conditionalObject(isBankAdvisor, {
        'invited-customer-attributes': {
          email: allFields[COMPANY_DETAILS_EMAIL],
          salutation: allFields[COMPANY_DETAILS_GENDER],
          'first-name': allFields[COMPANY_DETAILS_FIRST_NAME],
          'last-name': allFields[COMPANY_DETAILS_LAST_NAME],
          'phone-number': allFields[USER_PROFILE_PHONE_NUMBER],
        },
      }),
    };
  }

  return {
    'user-attributes': userData(allFields),
  };
};

export const mapBFSUserToApi = (allFields: any, isCustomer: boolean = false) => {
  const isLoggedIn = isLoggedInUser();
  if (isLoggedIn && isCustomer) {
    return {};
  } else {
    return {
      'user-attributes': {
        email: allFields[CONTACT_PERSON_EMAIL],
        salutation: allFields[CONTACT_PERSON_GENDER],
        'first-name': allFields[CONTACT_PERSON_FIRST_NAME],
        'last-name': allFields[CONTACT_PERSON_LAST_NAME],
        'terms-and-conditions-accepted': allFields[CONTACT_PERSON_SECURITY_CONSENT],
        'phone-number': allFields[CONTACT_PERSON_PHONE],
        'security-consent': allFields[CONTACT_PERSON_SECURITY_CONSENT],
      },
    };
  }
};

export const mapProfiUserDetailsToApi: InquiryProfiFormMapper = (
  allFields,
  isCustomer = false,
  isEdit = false,
) => {
  const isLoggedIn = isLoggedInUser();

  if ((isLoggedIn && isCustomer) || isEdit) {
    // user data is taken from token
    return {};
  }

  return {
    'user-attributes': userData(allFields),
  };
};
