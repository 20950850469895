import React from 'react';

import { ModalBody, useDisclosure } from '@chakra-ui/react';
import _range from 'lodash/range';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import archiveInquiryAction from 'api/CompeonReverseApi/operation/archiveInquiry/actions';
import FormRow from 'components/FormRow';
import { SelectWithField } from 'components/Selects/Select';
import { Option } from 'components/Selects/Select/Select';
import { FeToBeMapping } from 'modules/InquiryDetails/InquiryDetails.service';
import { usePortalConfig } from 'new/portals/state/portalConfigContext/hooks';
import { useToasts } from 'shared/hooks/useToasts';
import { mapInquiryDetailsApiResponseAction } from 'store/inquiryDetails/actions';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

const CancelInquiryInCompeon = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const { success } = useToasts();
  const t = useTranslations('pages.inquiryDetails.detailed.cancelInquiry');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isPending, makeCall } = useDispatchApiCall();
  const dispatch = useDispatch();
  const { portalConfig } = usePortalConfig();

  const options: Option[] = _range(1, 13).map((i: number) => ({
    label: t('modal.reasons.reason' + i),
    value: t('modal.reasons.reason' + i + 'compeon'),
  }));

  async function archiveInquiry(formValues: any) {
    if (inquiryId) {
      const response = await makeCall(archiveInquiryAction(inquiryId, formValues.reason));

      if (!response.error) {
        success({ description: t('modal.success') });
        dispatch(
          mapInquiryDetailsApiResponseAction(
            response.payload.data,
            portalConfig?.feToBeMappingKeys as FeToBeMapping,
          ),
        );
        onClose();
      }
    }

    //TODO: What to do if there is no inquiryId
  }

  return (
    <>
      <ButtonComponent onClick={onOpen} variant="primary" ml="4">
        {t('caption')}
      </ButtonComponent>

      <ModalComponent
        testId="transparency-company-selection-modal"
        isOpen={isOpen}
        onClose={onClose}
        title={t('modal.header')}
        footer={
          <>
            <ButtonComponent
              type="submit"
              form="form-transparency-register-select-company"
              isLoading={isPending}
            >
              {t('modal.action')}
            </ButtonComponent>
          </>
        }
      >
        <ModalBody>
          <Form
            onSubmit={archiveInquiry}
            render={({ handleSubmit }) => (
              <form id="form-transparency-register-select-company" onSubmit={handleSubmit}>
                <FormRow>{t('modal.subheader')}</FormRow>
                <FormRow>
                  <SelectWithField
                    name={'reason'}
                    options={options}
                    caption={t('modal.caption')}
                    data-testid={'select-field-reason'}
                  />
                </FormRow>
              </form>
            )}
          />
        </ModalBody>
      </ModalComponent>
    </>
  );
};

export default CancelInquiryInCompeon;
