import React from 'react';

import { Switch } from 'react-router-dom';

import ProgressBar from 'components/ProgressBar/ProgressBar';
import Spinner from 'components/Spinner';
import paths from 'constants/paths';
import { MittweidaEditCustomerPortalForm } from 'mittweida/inquiry';
import { MmvEditCustomerPortalForm } from 'mmv/inquiry';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import InquirySettingsProvider from 'modules/Inquiry/InquirySettingsProvider';
import useInquiryEditFormPopulator from 'modules/InquiryEdit/useInquiryEditInitializerHook';
import { useSetProgressForEdit } from 'modules/InquiryEdit/useSetProgressBarForEdit';
import { withCanEditInquiryGuard } from 'modules/InquiryEdit/WithCanEditInquiryGuard';
import { InquiryProcessConfigProvider } from 'new/form/state/inquiryProcessConfig';
import RootEditRedirect from 'pages/customerPortal/InquiryEdit/RootEditRedirect';
import CompanyDetails from 'pages/inquiryFlow/CompanyDetails';
import { ContractDetailsStep } from 'pages/inquiryFlow/ContractDetails/ContractDetailsStep';
import CustomerData from 'pages/inquiryFlow/CustomerData';
import { FinancingNeedStep } from 'pages/inquiryFlow/FinancingNeed/FinancingNeedStep';
import PeopleDetailsUnwrapped from 'pages/inquiryFlow/PeopleDetails';
import RequestDetails from 'pages/inquiryFlow/RequestDetails/RequestDetailsStep';
import SummaryPage from 'pages/inquiryFlow/SummaryPage/SummaryPageStep';
import UserProfile from 'pages/inquiryFlow/UserProfile';
import CustomerPrivateRoute from 'routes/CustomerPrivateRoute';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import InquiryCancelEdit from './Cancel';
import { DzbEditCustomerPortalForm } from '../../../dzb/inquiry';

const InquiryRoutes = () => {
  return (
    <Switch>
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.financingNeed}
        render={() => <FinancingNeedStep />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.companyDetails}
        render={() => <CompanyDetails />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.requestDetails}
        render={() => <RequestDetails />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.userProfile}
        render={() => <UserProfile />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.peopleDetails}
        render={() => <PeopleDetailsUnwrapped />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.summaryPage}
        render={() => <SummaryPage />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.cancel}
        render={() => <InquiryCancelEdit />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.root}
        render={() => <RootEditRedirect />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.contractDetails}
        render={() => <ContractDetailsStep />}
      />
      <CustomerPrivateRoute
        exact
        path={paths.customer.inquiryDetails.edit.customerData}
        render={() => <CustomerData />}
      />
    </Switch>
  );
};

const EditCustomerInquiryRoutes = withCanEditInquiryGuard(InquiryRoutes);

const DefaultInquiryEdit = () => {
  const isInitialized = useInquiryEditFormPopulator();
  useSetProgressForEdit();

  if (!isInitialized) {
    return <Spinner />;
  }

  return (
    <main>
      <ProgressBar />
      <InquirySettingsProvider>
        <EditCustomerInquiryRoutes />
      </InquirySettingsProvider>
    </main>
  );
};

const DdzbInquiryEdit = () => (
  <InquiryProcessConfigProvider>
    <DzbEditCustomerPortalForm />
  </InquiryProcessConfigProvider>
);

const MittweidaInquiryEdit = () => (
  <InquiryProcessConfigProvider>
    <MittweidaEditCustomerPortalForm />
  </InquiryProcessConfigProvider>
);

const MmvInquiryEdit = () => (
  <InquiryProcessConfigProvider>
    <MmvEditCustomerPortalForm />
  </InquiryProcessConfigProvider>
);

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dzb]: DdzbInquiryEdit,
  [InquiryType.default]: DefaultInquiryEdit,
  [InquiryType.profiMittweida]: MittweidaInquiryEdit,
  [InquiryType.mmv]: MmvInquiryEdit,
});
