import { RLLProfitability } from 'models/types/Profitability.type';

import * as fields from '../Inquiry/Form/formFields';

const mapRealEstateExpertFromAPI = (data: RLLProfitability['realEstateExpert']) => ({
  [fields.CURRENT_USAGE_REE_VALUE_PER_SQM_BUILDING]: data.current.building.marketValuePerSqm,
  [fields.CURRENT_USAGE_REE_PARKING_LOT_VALUE]: data.current.unit.marketValueParkingLot,
  [fields.CURRENT_USAGE_REE_COMMERCIAL_RENT_PER_SQM]: data.current.unit.commercialRentPerSqm,
  [fields.CURRENT_USAGE_REE_PARKING_LOT_RENT]: data.current.unit.rentParkingLot,
  [fields.CURRENT_USAGE_REE_GARAGE_VALUE]: data.current.unit.marketValueGarage,
  [fields.CURRENT_USAGE_REE_VALUE_PER_SQM_UNIT]: data.current.unit.marketValuePerSqm,
  [fields.CURRENT_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]: data.current.unit.residentialRentPerSqm,
  [fields.CURRENT_USAGE_REE_GARAGE_RENT]: data.current.unit.rentGarage,
  [fields.FUTURE_USAGE_REE_VALUE_PER_SQM_BUILDING]: data.future.building.marketValuePerSqm,
  [fields.FUTURE_USAGE_REE_PARKING_LOT_VALUE]: data.future.unit.marketValueParkingLot,
  [fields.FUTURE_USAGE_REE_COMMERCIAL_RENT_PER_SQM]: data.future.unit.commercialRentPerSqm,
  [fields.FUTURE_USAGE_REE_PARKING_LOT_RENT]: data.future.unit.rentParkingLot,
  [fields.FUTURE_USAGE_REE_GARAGE_VALUE]: data.future.unit.marketValueGarage,
  [fields.FUTURE_USAGE_REE_VALUE_PER_SQM_UNIT]: data.future.unit.marketValuePerSqm,
  [fields.FUTURE_USAGE_REE_RESIDENTIAL_RENT_PER_SQM]: data.future.unit.residentialRentPerSqm,
  [fields.FUTURE_USAGE_REE_GARAGE_RENT]: data.future.unit.rentGarage,
});

const mapFinancingDetailsFromAPI = (data: RLLProfitability['financingDetails']) => ({
  [fields.PROFITABILITY_FINANCING_DURATION_RENT]: data.rent.duration,
  [fields.PROFITABILITY_INTEREST_RATE_RENT]: data.rent.interestRate,
  [fields.PROFITABILITY_INTEREST_RATE_RENT_SHORT]: data.rent.interestRateShortTerm,
  [fields.PROFITABILITY_REPAYMENT_RATE_RENT]: data.rent.repaymentRate,
  [fields.PROFITABILITY_FINANCING_FEE_IN_PERCENT_RENT]: data.rent.financingFee,
  [fields.PROFITABILITY_FINANCING_FEE_UNIT]: data.unit.financingFee,
  [fields.PROFITABILITY_INTEREST_RATE_UNIT]: data.unit.interestRate,
  [fields.PROFITABILITY_FINANCING_DURATION_UNIT]: data.unit.duration,
  [fields.PROFITABILITY_INTEREST_RATE_BUILDING]: data.building.interestRate,
  [fields.PROFITABILITY_FINANCING_FEE_BUILDING]: data.building.financingFee,
  [fields.PROFITABILITY_FINANCING_DURATION_BUILDING]: data.building.duration,
});

const mapLendingValueFromAPI = (data: RLLProfitability['lendingValue']) => ({
  [fields.PROFITABILITY_EXTRA_MARKDOWN]: data.extraMarkdown,
  [fields.PROFITABILITY_SAFETY_MARKDOWN]: data.safetyMarkdown,
});

const mapPresalesFromAPI = (data: RLLProfitability['presales']) => ({
  [fields.PROFITABILITY_PRESALES_AREA_COMMERCIAL]: data.baseValues.areaCommercialPercent,
  [fields.PROFITABILITY_PRESALES_AREA_RESIDENTIAL]: data.baseValues.areaResidentialPercent,
  [fields.PROFITABILITY_NUMBER_OF_GARAGES]: data.baseValues.garage,
  [fields.PROFITABILITY_NUMBER_OF_PARKING_LOTS]: data.baseValues.parking,
  [fields.PROFITABILITY_PRESALES_PRICE_GARAGE_PER_PIECE]: data.pricePerShare.garage,
  [fields.PROFITABILITY_PRESALES_PRICE_PARKING_LOT_PER_PIECE]: data.pricePerShare.parking,
  [fields.PROFITABILITY_PRESALES_PRICE_COMMERCIAL_PER_SQM]: data.pricePerShare.commercial,
  [fields.PROFITABILITY_PRESALES_PRICE_RESIDENTIAL_PER_SQM]: data.pricePerShare.residential,
});

const mapInvestmentCalculationFromAPI = (data: RLLProfitability['investment']) => ({
  [fields.PROFITABILITY_AGENT_FEES]: data.agentFee,
  [fields.PROFITABILITY_LEGAL_FEES]: data.legalFee,
  [fields.PROFITABILITY_OTHER_FEES]: data.otherFees,
  [fields.PROFITABILITY_OTHER_COSTS]: data.otherCosts,
  [fields.PROFITABILITY_COMPENSATIONS]: data.compensations,
  [fields.PROFITABILITY_PURCHASE_PRICE]: data.purchasePrice,
  [fields.PROFITABILITY_PUBLIC_DISPOSAL]: data.publicDisposal,
  [fields.PROFITABILITY_MEASUREMENT_COSTS]: data.measurementCosts,
  [fields.PROFITABILITY_NON_PUBLIC_DISPOSAL]: data.nonPublicDisposal,
  [fields.PROFITABILITY_OTHER_PURCHASE_COSTS]: data.otherPurchaseCosts,
  [fields.PROFITABILITY_LAND_DEVELOPMENT_COSTS]: data.landDevelopmentCosts,
  [fields.PROFITABILITY_OTHER_DEVELOPMENT_COSTS]: data.otherDevelopmentCosts,
  [fields.PROFITABILITY_REAL_ESTATE_TRANSFER_TAX]: data.realEstateTransferTax,
  [fields.PROFITABILITY_BUFFER]: data.buffer,
  [fields.PROFITABILITY_MARKETING_SALES_COSTS]: data.marketingSalesCosts,
  [fields.PROFITABILITY_CONSTRUCTION_COSTS]: data.constructionCosts,
  [fields.PROFITABILITY_OTHER_CONSTRUCTION_COSTS]: data.otherConstructionCosts,
  [fields.PROFITABILITY_OUTDOOR_FACILITIES_COSTS]: data.outdoorFacilitiesCosts,
  [fields.PROFITABILITY_FINANCING_COSTS_FEES_MANUAL]: data.financingCostsFeesManual,
  [fields.PROFITABILITY_THIRD_PARTY_FINANCING_COSTS]: data.thirdPartyFinancingCosts,
  [fields.PROFITABILITY_FINANCING_COSTS_INTERESTS_MANUAL]: data.financingCostsInterestsManual,
  [fields.PROFITABILITY_ARCHITECT_COSTS]: data.architectCosts,
  [fields.PROFITABILITY_ADDITIONAL_COSTS]: data.additionalCosts,
});

const mapEvaluationFromAPI = (data: RLLProfitability['evaluation']) => ({
  [fields.PROFITABILITY_USUAL_SALES_COST_RATE]: data.usualSalesCostsRate,
});

const mapEvaluationRentFromAPI = (data: RLLProfitability['evaluationRent']) => ({
  [fields.PROFITABILITY_OPERATING_COSTS_RESIDENTIAL]: data.residentialOperatingCostsPercent,
  [fields.PROFITABILITY_OPERATING_COSTS_COMMERCIAL]: data.commercialOperatingCostsPercent,
  [fields.PROFITABILITY_DEPRECIATIONS_PER_YEAR_IN_EURO]: data.depreciation,
  [fields.PROFITABILITY_TAX_RATE_IN_PERCENT]: data.taxRate,
});

const mapQuickCheckFromAPI = (data: RLLProfitability['quickCheck']) => ({
  [fields.QUICK_CHECK_PREVIOUS_BUSINESS_RELATIONSHIPS]:
    data.customerInformation.previousBusinessRelationshipInfo,
  [fields.QUICK_CHECK_INITIATOR]: data.customerInformation.initiatorInfo,
  [fields.QUICK_CHECK_STATUS_OF_CURRENT_PROJECT_FINANCING_IN_HOUSE]:
    data.customerInformation.projectFinancingStatusInhouse,
  [fields.QUICK_CHECK_FINANCIAL_CURCUMSTANCES_OF_CUSTOMER]:
    data.customerInformation.economicCircumstances,
  [fields.QUICK_CHECK_PROJECT_DESCRIPTION]: data.projectInformation.projectDescription,
  [fields.QUICK_CHECK_INFO_ON_COLLATERAL]: data.projectInformation.collateralInfo,
  [fields.QUICK_CHECK_WITHDRAWAL_REQUIREMENTS]: data.projectInformation.withdrawalRequirements,
  [fields.QUICK_CHECK_OTHER_NOTICES_AND_INFORMATION]: data.otherInformation.otherNotesInfo,
  [fields.QUICK_CHECK_JUSTIFICATION_TEMPLATE]: data.otherInformation.justificationTemplate,
  [fields.QUICK_CHECK_TYPE_AND_GUARANTOR]: data.loanValue.securityAndWarrantor,
  [fields.QUICK_CHECK_AMOUNT_IN_EURO]: data.loanValue.amountInEuros,
  [fields.QUICK_CHECK_CURRENT_TOTAL_COMMITMENT]: data.unsecuredPortion.currentTotalCommitmentInEuro,
  [fields.QUICK_CHECK_NEW_COMMITMENT]: data.unsecuredPortion.newCommitmentInEuro,
  [fields.QUICK_CHECK_CURRENT_COLLATERAL_VALUE]: data.unsecuredPortion.currentTotalCollateralInEuro,
  [fields.QUICK_CHECK_NEW_COLLATERAL]: data.unsecuredPortion.newCollateralInEuro,
});

export const mapRLLProfitabilityFromApi = (profitability: RLLProfitability) => ({
  ...mapRealEstateExpertFromAPI(profitability.realEstateExpert),
  ...mapFinancingDetailsFromAPI(profitability.financingDetails),
  ...mapLendingValueFromAPI(profitability.lendingValue),
  ...mapPresalesFromAPI(profitability.presales),
  ...mapInvestmentCalculationFromAPI(profitability.investment),
  ...mapEvaluationFromAPI(profitability.evaluation),
  ...mapEvaluationRentFromAPI(profitability.evaluationRent),
  ...mapQuickCheckFromAPI(profitability.quickCheck),
});
