import { ConfigController } from 'config/ConfigController';
import { PARTNERS } from 'constants/partner';
import { USER_ROLE_BANK_ADVISOR } from 'constants/user/userRoles';
import { BuildingProject } from 'models/BuildingProject.type';
import { BuildingType } from 'models/BuildingType.model';
import { ProductKindType } from 'models/ProductKind.model';
import {
  BUILDING_PROJECT,
  BUILDING_PROJECT__PURCHASE,
  BUILDING_PROJECT__RECONSTRUCTION,
  BUILDING_TYPE,
  BUILDING_TYPE__APARTMENT,
  BUILDING_TYPE__COMMERCIAL,
  BUILDING_TYPE__CONDOMINIUM,
  BUILDING_TYPE__DETACHED,
  BUILDING_TYPE__DEVELOPER_MEASURE,
  BUILDING_TYPE__DUPLEX,
  BUILDING_TYPE__HOTEL,
  BUILDING_TYPE__OFFICE,
  BUILDING_TYPE__OTHER,
  BUILDING_TYPE__PRODUCTION_HALL,
  BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL,
  BUILDING_TYPE__RETAIL_PARK,
  BUILDING_TYPE__STORAGE_HALL,
  BUILDING_TYPE_OF_USE,
  BUILDING_TYPE_OF_USE__OWN_USE_LETTING_THIRD_PARTY,
  BUILDING_TYPE_OF_USE__THIRD_PARTY_RENTAL,
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY,
  COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING,
  COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__KFW_PROGRAM,
  HIRE_PURCHASE_AMORTISATION_KIND,
  HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  INVESTMENT_LOAN_AMORTISATION_KIND,
  INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  LEASING_AMORTISATION_KIND,
  LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_TYPE,
  LOAN_TYPE__AMORTISABLE,
  LOAN_TYPE__ANNUITY,
  MACHINE_IS_ORDERED,
  MACHINE_NEW,
  OVERDRAFT_TERMINABILITY,
  PRE_TAX_DEDUCTION,
  PROJECT_FINANCING_KNOWS_ANNUAL_RENT,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_ROLE,
  PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE,
  PURPOSE_KIND,
  PURPOSE_KIND__CORONA,
  PURPOSE_KIND__MACHINE,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  PURPOSE_KIND__PROJECT_FINANCING,
  PURPOSE_KIND__VEHICLE,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT,
  REAL_ESTATE_KIND,
  REAL_ESTATE_KIND__LAND_ONLY,
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT,
  REAL_ESTATE_PROJECT__FOLLOW_UP,
  REAL_ESTATE_PROJECT__PURCHASE,
  REAL_ESTATE_PROJECT__RECONSTRUCTION,
  REAL_ESTATE_USAGE_KIND,
  REAL_ESTATE_USAGE_KIND__BOTH,
  REAL_ESTATE_USAGE_KIND__THIRD_PARTY,
  ROLES__FILE_HALTER,
  ROLES__PARTITION,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_LEGAL_FORM__EINZELUNTERNEHMER,
  USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
  USER_PROFILE_LEGAL_FORM__OTHER,
  VEHICLE_KIND,
  VEHICLE_KIND__OTHER,
  VEHICLE_NEW,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  PRODUCT_KIND,
  PRODUCT_KIND__LOAN,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  PURPOSE_KIND__KFINANZ,
  CLIENT_ROLE__CONTRACTOR,
  CLIENT_ROLE,
  NUMBER_OF_TECHNOLOGIES,
  NUMBER_OF_TECHNOLOGIES__ONE,
  USER_PROFILE_COMPANY_RATING_KIND,
  USER_PROFILE_COMPANY_RATING_KIND__INTERNAL,
  ROLES__PROPERTY_BUYER,
  EXISTING_USER_COMPANY_ALREADY_CUSTOMER,
  PURPOSE_KIND__GOODS,
  BFS_SERVICE__USE_FACTORING_AS_FINANCING,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  PURPOSE_KIND__FACTOMAT,
  BFS_SERVICE__FACTORING_OFFER,
  BFS_SERVICE__FACTORING_OFFER_OTHER,
  PURPOSE_KIND_BFS_SERVICE,
  BFS_SERVICE__PURPOSE_KIND_OTHER,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_OTHER,
  BFS_SERVICE__PURPOSE_KIND_PATIENT_TRANSPORT,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { fieldParsers } from 'modules/Inquiry/mapInquiryToApi/fieldParsers';
import { shouldShowFoundingMonth } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { schemaHelpers } from 'schema/inquirySchema.helpers';
import { BusinessCondition, FormSections } from 'schema/inquirySchema.models';

const { toBoolean, toInteger } = fieldParsers;

const hasHeritableBuildingRights: BusinessCondition = ({ formValues }) =>
  !!toBoolean(formValues[REAL_ESTATE_HERITABLE_BUILDING_RIGHT]);
const isRentable: BusinessCondition = ({ formValues }) =>
  [REAL_ESTATE_USAGE_KIND__THIRD_PARTY, REAL_ESTATE_USAGE_KIND__BOTH].includes(
    formValues[REAL_ESTATE_USAGE_KIND],
  );
const hasVehiclePurpose: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__VEHICLE;
const hasMachinePurpose: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__MACHINE;
const hasOfficeEquipmentPurpose: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__OFFICE_EQUIPMENT;
const hasKfinanzPurpose: BusinessCondition = ({ formValues }) =>
  formValues && formValues[PURPOSE_KIND] === PURPOSE_KIND__KFINANZ;
const isNotAnnuityLoan: BusinessCondition = ({ formValues }) =>
  formValues[LOAN_TYPE] !== LOAN_TYPE__ANNUITY;

const hasFixedLoanInterestRate: BusinessCondition = ({ formValues }) =>
  !!toBoolean(formValues[LOAN_FIXED_INTEREST_RATE]);
const shouldInvolveLoanInterestPeriod: BusinessCondition = ({ formValues }) =>
  isNotAnnuityLoan({ formValues }) ? hasFixedLoanInterestRate({ formValues }) : true;

const vehicleIsNotNew: BusinessCondition = ({ formValues }) =>
  toBoolean(formValues[VEHICLE_NEW]) === false;
const hasSelectedOtherVehicle: BusinessCondition = ({ formValues }) =>
  formValues[VEHICLE_KIND] === VEHICLE_KIND__OTHER;
const machineIsNotNew: BusinessCondition = ({ formValues }) =>
  toBoolean(formValues[MACHINE_NEW]) === false;
const shouldConsiderSubsidies: BusinessCondition = ({ formValues }) =>
  !!toBoolean(formValues[LOAN_CONSIDER_SUBSIDIES]) &&
  formValues[LOAN_TYPE] === LOAN_TYPE__AMORTISABLE;
const isPartitionOrFileHalter: BusinessCondition = ({ formValues }) =>
  [ROLES__PARTITION, ROLES__FILE_HALTER].includes(formValues[PROJECT_FINANCING_ROLE]);
const hasListOfRenters: BusinessCondition = ({ formValues }) =>
  !!toBoolean(formValues[PROJECT_FINANCING_LIST_OF_RENTERS]);
const isPlanningRenovation: BusinessCondition = ({ formValues }) =>
  !!toBoolean(formValues[PROJECT_FINANCING_RENOVATION_PLANNED]);
const hasSelectedAlreadyAssignedCompany: BusinessCondition = ({ formValues }) =>
  formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY] &&
  formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY] !==
    COMPANY_DETAILS_EXISTING_USER_COMPANY__NEW_COMPANY;
const isPassYearsThreshold: BusinessCondition = ({ formValues }) =>
  shouldShowFoundingMonth(toInteger(formValues[USER_PROFILE_FOUNDING_YEAR]));

const hasSelectedAlreadyExistingCompany: BusinessCondition = ({ formValues }) =>
  !!formValues[COMPANY_DETAILS_COMPANY] && !!formValues[COMPANY_DETAILS_COMPANY].name;

const knowsAnnualRent: BusinessCondition = ({ formValues }) =>
  !!toBoolean(formValues[PROJECT_FINANCING_KNOWS_ANNUAL_RENT]);

const hasSelectedOtherFinancingOption: BusinessCondition = ({ formValues }) =>
  formValues[CORONA_FINANCING_OPTION] === CORONA_FINANCING_OPTION__KFW_PROGRAM;

const isBigPictureForm: BusinessCondition = ({ formType }) => formType === InquiryType.bigPicture;

const isBFSForm: BusinessCondition = ({ formType }) => formType === InquiryType.bfs;

const isNotBFSForm: BusinessCondition = ({ formType }) => formType !== InquiryType.bfs;

const isProFiForm: BusinessCondition = ({ formType }) => formType === InquiryType.profiRLL;

const isPartnerDVAG: BusinessCondition = ({ partnerId }) => partnerId === PARTNERS.DVAG;

const notRealEstateDevProject: BusinessCondition = ({ formValues }) =>
  formValues[REAL_ESTATE_PROJECT] !== REAL_ESTATE_PROJECT__DEVELOPMENT_PROJECT;

const isRealEstateBuilding: BusinessCondition = ({ formValues }) => {
  const allowedProjects = [
    REAL_ESTATE_PROJECT__PURCHASE,
    REAL_ESTATE_PROJECT__FOLLOW_UP,
    REAL_ESTATE_PROJECT__RECONSTRUCTION,
  ];
  return (
    allowedProjects.includes(formValues[REAL_ESTATE_PROJECT]) &&
    formValues[REAL_ESTATE_KIND] !== REAL_ESTATE_KIND__LAND_ONLY
  );
};

const machineIsOrdered: BusinessCondition = ({ formValues }) =>
  !!toBoolean(formValues[MACHINE_IS_ORDERED]);
const isNotPurposeWithoutAmountSection: BusinessCondition = ({ formValues }) =>
  ![PURPOSE_KIND__CORONA, PURPOSE_KIND__PROJECT_FINANCING].includes(formValues[PURPOSE_KIND]);
const overdraftHasTerminability: BusinessCondition = ({ formValues }) =>
  !!toBoolean(formValues[OVERDRAFT_TERMINABILITY]);
const leasingHasPartialAmortisation: BusinessCondition = ({ formValues }) =>
  formValues[LEASING_AMORTISATION_KIND] === LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION;
const investmentLoanHasPartialAmortisation: BusinessCondition = ({ formValues }) =>
  formValues[INVESTMENT_LOAN_AMORTISATION_KIND] ===
  INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION;
const hirePurchaseHasPartialAmortisation: BusinessCondition = ({ formValues }) =>
  formValues[HIRE_PURCHASE_AMORTISATION_KIND] ===
  HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION;
const hasSelectedEnergyEfficiencyPurposeKind: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__ENERGY_EFFICIENCY;

const hasSelectedMachinePurposeKind: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__MACHINE;

const hasSelectedOfficeEquipmentPurposeKind: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__OFFICE_EQUIPMENT;

const hasSelectedVehiclePurposeKind: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__VEHICLE;

const canShowIntrestedInInsurance: BusinessCondition = (form) =>
  hasSelectedEnergyEfficiencyPurposeKind(form) ||
  hasSelectedMachinePurposeKind(form) ||
  hasSelectedOfficeEquipmentPurposeKind(form);

const hasSelectedBuildingType =
  (buildingType: BuildingType): BusinessCondition =>
  ({ formValues }) =>
    formValues[BUILDING_TYPE] === buildingType;

const isBuildingWithThirdParty: BusinessCondition = ({ formValues }) =>
  [
    BUILDING_TYPE_OF_USE__THIRD_PARTY_RENTAL,
    BUILDING_TYPE_OF_USE__OWN_USE_LETTING_THIRD_PARTY,
  ].includes(formValues[BUILDING_TYPE_OF_USE]);

const isBuildingCommercialRentableType: BusinessCondition = ({ formValues }) =>
  [
    BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL,
    BUILDING_TYPE__OFFICE,
    BUILDING_TYPE__PRODUCTION_HALL,
    BUILDING_TYPE__STORAGE_HALL,
    BUILDING_TYPE__DEVELOPER_MEASURE,
    BUILDING_TYPE__COMMERCIAL,
    BUILDING_TYPE__RETAIL_PARK,
    BUILDING_TYPE__HOTEL,
    BUILDING_TYPE__OTHER,
  ].includes(formValues[BUILDING_TYPE]);

const isBuildingLivingRentableType: BusinessCondition = ({ formValues }) =>
  [
    BUILDING_TYPE__DETACHED,
    BUILDING_TYPE__DUPLEX,
    BUILDING_TYPE__CONDOMINIUM,
    BUILDING_TYPE__APARTMENT,
    BUILDING_TYPE__RESIDENTIAL_AND_COMMERCIAL,
  ].includes(formValues[BUILDING_TYPE]);

const buildingReconstructionOrPurchase: BusinessCondition = ({ formValues }) =>
  [BUILDING_PROJECT__RECONSTRUCTION, BUILDING_PROJECT__PURCHASE].includes(
    formValues[BUILDING_PROJECT],
  );

const hasSelectedBuildingProject =
  (project: BuildingProject[]): BusinessCondition =>
  ({ formValues }) =>
    project.includes(formValues[BUILDING_PROJECT]);

const companyReceivedGeneralSubsidies: BusinessCondition = ({ formValues }) =>
  toBoolean(formValues[COMPANY_DETAILS_RECEIVED_SUBSIDIES_GENERAL]) === true;
const companyReceivedAgriculturalSubsidies: BusinessCondition = ({ formValues }) =>
  toBoolean(formValues[COMPANY_DETAILS_RECEIVED_SUBSIDIES_AGRICULTURAL]) === true;
const companyReceivedFishingSubsidies: BusinessCondition = ({ formValues }) =>
  toBoolean(formValues[COMPANY_DETAILS_RECEIVED_SUBSIDIES_FISHING]) === true;
const companyReceivedDawiSubsidies: BusinessCondition = ({ formValues }) =>
  toBoolean(formValues[COMPANY_DETAILS_RECEIVED_SUBSIDIES_DAWI]) === true;

const isPreTax: BusinessCondition = ({ formValues }) =>
  toBoolean(formValues[PRE_TAX_DEDUCTION]) === true;

const isAlreadyCompanyCustomer: BusinessCondition = ({ formValues }) => {
  if (toBoolean(formValues[USER_PROFILE_COMPANY_ALREADY_CUSTOMER])) {
    return !!toBoolean(formValues[USER_PROFILE_COMPANY_ALREADY_CUSTOMER]);
  } else {
    if (formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY])
      return !!toBoolean(
        formValues[COMPANY_DETAILS_EXISTING_USER_COMPANY][EXISTING_USER_COMPANY_ALREADY_CUSTOMER],
      );
  }
  return false;
};

const canShowAuthorizedRepresentative: BusinessCondition = ({ formValues }) =>
  ![
    USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
    USER_PROFILE_LEGAL_FORM__EINZELUNTERNEHMER,
  ].includes(formValues[USER_PROFILE_LEGAL_FORM]);

const hasSelectedOtherLegalForm: BusinessCondition = ({ formValues }) => {
  return formValues[USER_PROFILE_LEGAL_FORM] === USER_PROFILE_LEGAL_FORM__OTHER;
};

const hasSelectedQuickCredit: BusinessCondition = ({ formValues }) =>
  formValues[CORONA_FINANCING_OPTION] === CORONA_FINANCING_OPTION__QUICK_CREDIT;

const hasSelectedProduct =
  (productKind: ProductKindType): BusinessCondition =>
  ({ formValues }) =>
    formValues[PRODUCT_KIND] === productKind;

const productSelectedAndValidSection = (
  product: ProductKindType,
  section: FormSections,
): BusinessCondition =>
  schemaHelpers.combineConditions(
    hasSelectedProduct(product),
    schemaHelpers.sectionsAreValid([section]),
  );

const shouldShowSubsidies: BusinessCondition = ({ formValues }) =>
  formValues[PRODUCT_KIND] === PRODUCT_KIND__LOAN &&
  formValues[LOAN_TYPE] === LOAN_TYPE__AMORTISABLE &&
  formValues[PURPOSE_KIND] !== PURPOSE_KIND__CORONA;

const isContractor: BusinessCondition = ({ formValues }) =>
  formValues[CLIENT_ROLE] === CLIENT_ROLE__CONTRACTOR;

const isTechnologyOne: BusinessCondition = ({ formValues }) =>
  formValues[NUMBER_OF_TECHNOLOGIES] === NUMBER_OF_TECHNOLOGIES__ONE;

const isInternalCompanyRating: BusinessCondition = ({ formValues }) =>
  formValues[USER_PROFILE_COMPANY_RATING_KIND] === USER_PROFILE_COMPANY_RATING_KIND__INTERNAL;

const hasCalculatedAmountField: BusinessCondition = ({ formValues }) =>
  [PURPOSE_KIND__MACHINE, PURPOSE_KIND__GOODS, PURPOSE_KIND__VEHICLE].includes(
    formValues[PURPOSE_KIND],
  );

const userLoggedIn: BusinessCondition = ({ formValues }) => !!formValues.isLoggedIn;

const isBankAdvisor: BusinessCondition = ({ userRoles }) =>
  !!userRoles?.includes(USER_ROLE_BANK_ADVISOR);

const canShowExcludedBanks: BusinessCondition = () => {
  return ConfigController.featureFlags.getFlagByName('isCompeonForwardingEnabled');
};

const canShowUserProfileAdditionalAssociationCompany: BusinessCondition = () => {
  return ConfigController.featureFlags.getFlagByName('isAdditionalAssociationCompanyEnabled');
};

const canShowStructureNumber: BusinessCondition = () => {
  return ConfigController.featureFlags.getFlagByName('isSalesPersonsEnabled');
};

const canShowADPNumber: BusinessCondition = () => {
  return ConfigController.featureFlags.getFlagByName('isSalesPersonsEnabled');
};

const isNotLandBuyerInProFiForm: BusinessCondition = ({ formValues, formType }) =>
  formValues[PROJECT_FINANCING_ROLE] &&
  formValues[PROJECT_FINANCING_ROLE] !== ROLES__PROPERTY_BUYER &&
  formType === InquiryType.profiRLL;

const isDeviatingFromFutureUsage: BusinessCondition = ({ formValues, formType }) =>
  formValues[PROJECT_FINANCING_ROLE] &&
  formValues[PROJECT_FINANCING_ROLE] !== ROLES__PROPERTY_BUYER &&
  toBoolean(formValues[PROJECT_FINANCING_IS_DEVIATING_FROM_FUTURE_USAGE]) &&
  formType === InquiryType.profiRLL;

const isFactoringFormFinance: BusinessCondition = ({ formValues }) =>
  toBoolean(formValues[BFS_SERVICE__USE_FACTORING_AS_FINANCING]) === true;

const isBFSOnlineFactoring: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING;

const isBFSFactomat: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__FACTOMAT;

const isBFSFactomatAndNotPatientTransport: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__FACTOMAT &&
  formValues[PURPOSE_KIND_BFS_SERVICE] !== BFS_SERVICE__PURPOSE_KIND_PATIENT_TRANSPORT;

const isBFSFactomatAndPatientTransport: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND] === PURPOSE_KIND__FACTOMAT &&
  formValues[PURPOSE_KIND_BFS_SERVICE] === BFS_SERVICE__PURPOSE_KIND_PATIENT_TRANSPORT;

const isBFSFactoringOffer: BusinessCondition = ({ formValues }) =>
  formValues[BFS_SERVICE__FACTORING_OFFER] === BFS_SERVICE__FACTORING_OFFER_OTHER;

const isBFSIndustryOther: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND_BFS_SERVICE] === BFS_SERVICE__PURPOSE_KIND_OTHER;

const isNotBFSIndustryOther: BusinessCondition = ({ formValues }) =>
  formValues[PURPOSE_KIND_BFS_SERVICE] !== BFS_SERVICE__PURPOSE_KIND_OTHER;

const isBFSIndustrySoftwareOther: BusinessCondition = ({ formValues }) =>
  formValues[BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING] ===
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING_OTHER;

const isNotBFSFactoringOfferOrOther: BusinessCondition = ({ formValues, formType }) =>
  formType !== InquiryType.bfsService ||
  formValues[PURPOSE_KIND_BFS_SERVICE] === BFS_SERVICE__PURPOSE_KIND_OTHER;

const isNotProFiForm: BusinessCondition = ({ formType }) => formType !== InquiryType.profiRLL;

// Conditions whether to include or not include fields in the api request
// This should be refactor to be reused across application to apply business logic once
// Basically this is a list of all business conditions in the form
export const fieldBusinessConditions = {
  canShowExcludedBanks,
  canShowUserProfileAdditionalAssociationCompany,
  canShowStructureNumber,
  canShowADPNumber,
  isInternalCompanyRating,
  hasCalculatedAmountField,
  isTechnologyOne,
  isContractor,
  shouldShowSubsidies,
  productSelectedAndValidSection,
  hasSelectedProduct,
  hasSelectedQuickCredit,
  hasSelectedBuildingProject,
  buildingReconstructionOrPurchase,
  isBuildingLivingRentableType,
  isBuildingCommercialRentableType,
  isBuildingWithThirdParty,
  hasSelectedBuildingType,
  canShowIntrestedInInsurance,
  hasSelectedEnergyEfficiencyPurposeKind,
  hasSelectedMachinePurposeKind,
  hasSelectedVehiclePurposeKind,
  isPartitionOrFileHalter,
  hasListOfRenters,
  isPlanningRenovation,
  hasSelectedAlreadyAssignedCompany,
  hasHeritableBuildingRights,
  isRentable,
  hasVehiclePurpose,
  hasMachinePurpose,
  hasOfficeEquipmentPurpose,
  hasKfinanzPurpose,
  isNotAnnuityLoan,
  hasFixedLoanInterestRate,
  shouldInvolveLoanInterestPeriod,
  vehicleIsNotNew,
  hasSelectedOtherVehicle,
  machineIsNotNew,
  shouldConsiderSubsidies,
  hasSelectedAlreadyExistingCompany,
  knowsAnnualRent,
  hasSelectedOtherFinancingOption,
  notRealEstateDevProject,
  isRealEstateBuilding,
  isNotPurposeWithoutAmountSection,
  overdraftHasTerminability,
  leasingHasPartialAmortisation,
  investmentLoanHasPartialAmortisation,
  hirePurchaseHasPartialAmortisation,
  machineIsOrdered,
  isPassYearsThreshold,
  isPreTax,
  companyReceivedGeneralSubsidies,
  companyReceivedAgriculturalSubsidies,
  companyReceivedFishingSubsidies,
  companyReceivedDawiSubsidies,
  canShowAuthorizedRepresentative,
  hasSelectedOtherLegalForm,
  isBigPictureForm,
  isBFSForm,
  isNotBFSForm,
  isProFiForm,
  isPartnerDVAG,
  userLoggedIn,
  isAlreadyCompanyCustomer,
  isBankAdvisor,
  isNotLandBuyerInProFiForm,
  isDeviatingFromFutureUsage,
  isFactoringFormFinance,
  isBFSOnlineFactoring,
  isBFSFactomat,
  isNotProFiForm,
  isBFSFactoringOffer,
  isBFSIndustryOther,
  isNotBFSIndustryOther,
  isBFSIndustrySoftwareOther,
  isNotBFSFactoringOfferOrOther,
  isBFSFactomatAndNotPatientTransport,
  isBFSFactomatAndPatientTransport,
  canShowPreFinancingPeriod: isBFSFactomatAndNotPatientTransport,
};
