import React from 'react';

import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';

import Condition from 'modules/ConditionalSubform/Condition';
import { requestDetailsConditions } from 'modules/Inquiry/Form/Conditions/default/requestDetails.conditions';
import {
  REQUEST_DETAILS,
  LOAN_TYPE,
  LOAN_FIXED_INTEREST_RATE__FIXED,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_TYPE__ANNUITY,
  PRODUCT_KIND,
  PRODUCT_KIND__LOAN,
  BANK_DETAILS_IBAN,
  PARTNER_SPECIFIC_IBAN,
  END_USER_CONDITION_INTEREST_RATE,
  END_USER_CONDITION_AMOUNT,
} from 'modules/Inquiry/Form/formFields';
import {
  DecisionDateSection,
  validateDecisionDateSection,
} from 'modules/Inquiry/Form/Steps/RequestDetails/DecisionDate/DecisionDateSection';
import FixedAnnuityLoan, {
  fixedAnnuityLoanSectionValidationCondition,
  fixedAnnuityLoanSectionValidation,
} from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/FixedAmortisableLoan';
import Loan from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Loan/LoanSection';
import { useRequestDetailsSectionValidators } from 'modules/Inquiry/Form/Validations/default/useRequestDetailsSectionValidators';
import InquiryFormNew from 'modules/InquiryFormNew/InquiryFormNew';
import Heading from 'pages/inquiryFlow/RequestDetails/Heading/Heading';
import { isLeadProcessLane } from 'store/inquiryProcess/selectors';
import { getStoredValueSelector } from 'store/progress/selectors';
import { COMPANY_DETAILS } from 'utils/sessionStorage/keys';

import DisabledSections from './DisabledSections/DisabledSections';

export const useGetRequestDetailsBigPictureValidations = () => () =>
  [fixedAnnuityLoanSectionValidation, validateDecisionDateSection];

export const RequestDetailsBigPicture = () => {
  const loanSectionValidator = useRequestDetailsSectionValidators();
  const { shouldShowCommonSections } = requestDetailsConditions;
  const partnerSpecificIban = useSelector(
    getStoredValueSelector([COMPANY_DETAILS, PARTNER_SPECIFIC_IBAN]),
  );
  const isLead = useSelector(isLeadProcessLane);
  const validations = useGetRequestDetailsBigPictureValidations();
  const leadValidation = isLead ? loanSectionValidator : validations;

  const initialValues = {
    [PRODUCT_KIND]: PRODUCT_KIND__LOAN,
    [LOAN_TYPE]: LOAN_TYPE__ANNUITY,
    [LOAN_FIXED_INTEREST_RATE]: LOAN_FIXED_INTEREST_RATE__FIXED,
    [BANK_DETAILS_IBAN]: !isLead ? partnerSpecificIban : null,
  };

  return (
    <InquiryFormNew
      initialValues={initialValues}
      headerComp={<Heading />}
      currentStepName={REQUEST_DETAILS}
      getSectionValidators={leadValidation}
    >
      {() => (
        <>
          {!isLead && (
            <>
              <Field name={END_USER_CONDITION_INTEREST_RATE} type="hidden" component={'input'} />
              <Field name={END_USER_CONDITION_AMOUNT} type="hidden" component={'input'} />
              <FixedAnnuityLoan />
            </>
          )}
          {isLead && <Loan />}
          <Condition
            condition={
              isLead ? shouldShowCommonSections : fixedAnnuityLoanSectionValidationCondition
            }
            onFail={<DisabledSections numOfSections={1} offset={1} />}
          >
            <DecisionDateSection sectionNumber={2} />
          </Condition>
        </>
      )}
    </InquiryFormNew>
  );
};

export default RequestDetailsBigPicture;
