import { useMutation } from '@tanstack/react-query';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import endpoints from 'api/CompeonReverseApi/endpoints';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { store } from 'store';
import { setProcessLane } from 'store/inquiryProcess/actions';

import { useBigPictureSpecializedProcess } from '../../api/CompeonReverseApi/customer/queryHooks/inquiries';

const delay = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

async function fetchWithRetry(id: string, retries = 5, delayMs = 1000): Promise<InquiryLane> {
  for (let i = 0; i < retries; i++) {
    try {
      const response = await EcoBankingAxiosClientAuthedInstance.get(
        endpoints.INQUIRIES.BIG_PICTURE_CHECK.compose({ params: { id } }),
      );
      if (response.data.data.attributes.lane_assignment_status === 'completed') {
        return response.data.data.attributes.lane;
      }
      await delay(delayMs);
    } catch (error) {
      if (i === retries - 1) {
        return InquiryLane.lead;
      }
      await delay(delayMs);
    }
  }
  return InquiryLane.lead;
}

export const useInquiryProcessLaneCheck = () => {
  const mutation = useMutation(({ id }: { id: string }) => fetchWithRetry(id), {
    onSuccess: (data) => {
      setProcessLane(data)(store.dispatch);
    },
    onError: () => {
      setProcessLane(InquiryLane.lead)(store.dispatch);
    },
  });

  return { mutation };
};

export const useProcessLaneCheck = () => {
  return useBigPictureSpecializedProcess({
    retry: 10,
    onSuccess: (data) => {
      setProcessLane(data.data.data.attributes.lane ?? InquiryLane.lead)(store.dispatch);
    },
  });
};
