import React from 'react';

import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import LanguageProvider from 'components/App/LanguageProvider';
import { PartnerProvider } from 'components/App/PartnerProvider/PartnerProvider';
import TokenExpirationWrapper from 'components/App/TokenExpirationWrapper';
import FixedSidebar from 'components/FixedSidebar/FixedSidebar';
import Footer from 'modules/Footer';
import { Head } from 'modules/Head/Head';
import { Header } from 'modules/Header';
import Routes from 'routes';
import { isUnderOperationPortalPath } from 'shared/hooks/useIsUnderOperationPortalPath';
import { persistor, store } from 'store';
import { GlobalStyle } from 'styles/global';

import AppWrapper from './AppWrapper';
import CspMeta from './CspMeta';
import { InquiryValuesProvider } from './InquiryValuesProvider';
import MainContent from './MainContent';
import paths from '../../constants/paths';
import { PortalConfigProvider } from '../../new/portals/state/portalConfigContext';

export const history = createBrowserHistory();
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (error.response?.status === 401) {
        history.replace(
          isUnderOperationPortalPath(history.location.pathname)
            ? paths.operation.login
            : paths.customer.login,
          { redirectTo: history.location.pathname },
        );
      }
    },
  }),
});

const persister = createSyncStoragePersister({
  storage: window.sessionStorage,
});

const App = () => {
  return (
    <>
      <CspMeta />
      <Provider store={store}>
        <Router history={history}>
          <PartnerProvider>
            <InquiryValuesProvider>
              <PortalConfigProvider>
                <LanguageProvider>
                  <TokenExpirationWrapper>
                    <AppWrapper persistor={persistor}>
                      <PersistQueryClientProvider
                        client={queryClient}
                        persistOptions={{ persister }}
                      >
                        <GlobalStyle />
                        <Head />
                        <Header />
                        <MainContent>
                          <Routes />
                          <FixedSidebar />
                          <Footer />
                        </MainContent>
                        <ReactQueryDevtools />
                      </PersistQueryClientProvider>
                    </AppWrapper>
                  </TokenExpirationWrapper>
                </LanguageProvider>
              </PortalConfigProvider>
            </InquiryValuesProvider>
          </PartnerProvider>
        </Router>
      </Provider>
    </>
  );
};

export default App;
