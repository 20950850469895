import * as React from 'react';
import { useEffect } from 'react';

import { Center, Flex, Grid, GridItem, useDisclosure } from '@chakra-ui/react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Asset } from 'components/Asset';
import { CheckboxWithField } from 'components/Checkbox';
import MarkdownWrapper from 'components/MarkdownWrapper/MarkdownWrapper';
import paths from 'constants/paths';
import { ReportType } from 'models/InquiryDetails/DefaultInquiryDetails.model';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import { ProgressSectionType } from 'pages/customerPortal/InquiryDetails/Dashboard/types';
import { useGetRiskAnalysis } from 'pages/operationPortal/CompaniesDetails/helpers/useGetRiskAnalysis';
import { BlackWhite } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/BlackWhite/BlackWhite';
import { Rating } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/Rating';
import { RiskClass } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/RiskClass';
import { Score } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/Score';
import { TrafficLights } from 'pages/operationPortal/CompaniesDetails/RiskAnalysis/TrafficLight/TrafficLights';
import {
  getBlackAndWhiteReport,
  getCompactReport,
  getRatingReport,
  getShortReport,
  getTrafficLightReport,
} from 'pages/operationPortal/CompaniesDetails/store/selectors';
import { chooseLaneSpecificComponent } from 'shared/chooseLaneSpecificComponent';
import { useToasts } from 'shared/hooks/useToasts';
import { triggerCrefoAction } from 'store/inquiryDetails/actions';
import {
  getCompanyId,
  isCrefoTriggered,
  manualCrefoReportFound,
} from 'store/inquiryDetails/selectors';
import { ButtonComponent } from 'theme/components/Button';
import { HeadingComponent } from 'theme/components/Heading';
import ArrowRightIcon from 'theme/components/Icon/ArrowRightIcon';
import { ModalComponent } from 'theme/components/Modal';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { useTranslations } from 'utils/hooks/useTranslations';

import { useCreditReformProducts } from './useCreditReformProducts';

type CrefoQuery = {
  isFirst: boolean;
  isLast: boolean;
  isCompleted: boolean;
  completedValue: number;
  progressIncrement: number;
  progressTotal: number;
  id?: string;
};

type Values = Record<ReportType, boolean>;

const selectionRules: Partial<Record<ReportType, ReportType[]>> = {
  [ReportType.SHORT]: [ReportType.TRAFFIC_LIGHT],
  [ReportType.COMPACT]: [ReportType.TRAFFIC_LIGHT, ReportType.SHORT],
};

/**
 * Processes the selected options from a form with checkboxes.
 * Applies specific rules based on the selectionRules options,
 * where selecting these options also implicitly selects additional options.
 *
 * @param {ReportType[]} selections - The options selected by the user.
 * @return {ReportType[]} An array of options to send to the API, with additional options added according to the rules.
 */
function processSelections(selections: ReportType[]): ReportType[] {
  let toSend: ReportType[] = [...selections];

  selections.forEach((option) => {
    const additionalOptions = selectionRules[option] || [];
    additionalOptions.forEach((additionalOption) => {
      if (!toSend.includes(additionalOption)) {
        toSend.push(additionalOption);
      }
    });
  });

  return toSend;
}

const CrefoQueryStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: CrefoQuery) => {
  const t = useTranslations('pages.inquiryDetails.dashboard.actions.crefoQuery');
  const ratingT = useTranslations('pages.companiesDetails.riskAnalysis');

  const { id: inquiryId } = useParams<{ id: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { success } = useToasts();
  const { isLoading, getRiskAnalysis } = useGetRiskAnalysis();
  const companyId = useSelector(getCompanyId);
  const trafficLightReport = useSelector(getTrafficLightReport);
  const shortReport = useSelector(getShortReport);
  const compactReport = useSelector(getCompactReport);
  const ratingReport = useSelector(getRatingReport);
  const blackWhiteReport = useSelector(getBlackAndWhiteReport);
  const hasCrefoTriggered = useSelector(isCrefoTriggered);
  const reportFound = useSelector(manualCrefoReportFound);
  const { reformProductsMap, enabledReports } = useCreditReformProducts();
  const { makeCall } = useDispatchApiCall({
    errorMessage: t('failure'),
    isPendingInitially: false,
    showErrorNotification: true,
  });
  const initialValues = {
    [ReportType.TRAFFIC_LIGHT]: !!trafficLightReport,
    [ReportType.SHORT]: !!shortReport,
    [ReportType.COMPACT]: !!compactReport,
    [ReportType.RATING]: !!ratingReport,
    [ReportType.BLACK_WHITE]: !!blackWhiteReport,
  };

  const reportsRequestOpen = !(
    !!trafficLightReport &&
    !!compactReport &&
    !!shortReport &&
    !!ratingReport &&
    !!blackWhiteReport
  );

  const disabledReportOptions: Record<ReportType, boolean> = {
    [ReportType.TRAFFIC_LIGHT]: !!trafficLightReport,
    [ReportType.SHORT]: !!shortReport,
    [ReportType.COMPACT]: !!compactReport,
    [ReportType.RATING]: !!ratingReport,
    [ReportType.BLACK_WHITE]: !!blackWhiteReport,
  };

  const reportOptionTranslations = {
    [ReportType.TRAFFIC_LIGHT]: t('trafficReport'),
    [ReportType.SHORT]: t('shortReport'),
    [ReportType.COMPACT]: t('compactReport'),
    [ReportType.RATING]: t('ratingReport'),
    [ReportType.BLACK_WHITE]: t('blackWhiteReport'),
  };

  useEffect(() => {
    getRiskAnalysis(companyId);
  }, [companyId, getRiskAnalysis]);

  const submitRequest = async (values: Values) => {
    const reportTypes = Object.keys(values).filter(
      (key) => values[key as ReportType],
    ) as ReportType[];

    const payload = processSelections(reportTypes);

    const { error } = await makeCall(triggerCrefoAction(inquiryId!!, payload));

    if (!error) {
      success({ description: t('successful') });
      getRiskAnalysis(companyId);
    }

    onClose();
  };

  const shouldShowWaitingText = hasCrefoTriggered && reportsRequestOpen;

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <Grid templateColumns={['repeat(1, 1fr)', null, '2fr 1fr']} gap={12} mb={12}>
        <GridItem>
          <HeadingComponent
            as="h4"
            color="brand.default"
            mb={2}
            variant="secondary"
            data-testid={'CD-Risk-Analysis-Heading'}
          >
            {t('title')}
          </HeadingComponent>

          <TextComponent mb={4}>
            <MarkdownWrapper text={t('description')} />
          </TextComponent>

          {reportFound === false ? (
            <TextComponent mb={6} color={'text.tertiary'}>
              {t('noResults')}
            </TextComponent>
          ) : null}

          {!isCompleted && (
            <>
              <ButtonComponent
                isLoading={isLoading}
                leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
                onClick={onOpen}
                variant="primary"
              >
                {t('action')}
              </ButtonComponent>

              <Form
                onSubmit={submitRequest}
                initialValues={initialValues}
                render={({ handleSubmit }) => (
                  <ModalComponent
                    isOpen={isOpen}
                    onClose={onClose}
                    title={t('title')}
                    footer={
                      <>
                        <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
                          {t('buttonCancel')}
                        </ButtonComponent>
                        <ButtonComponent onClick={handleSubmit}>
                          {t('buttonConfirm')}
                        </ButtonComponent>
                      </>
                    }
                  >
                    <form onSubmit={handleSubmit}>
                      <TextComponent mb="6">{t('text')}</TextComponent>
                      <Flex flexWrap="wrap" rowGap="20px">
                        {(enabledReports as ReportType[])?.map((item: ReportType) => (
                          <Flex key={item} flex="50%">
                            <CheckboxWithField
                              name={item}
                              text={reportOptionTranslations[item]}
                              smallCheckbox
                              disabled={!!disabledReportOptions[item]}
                            />
                          </Flex>
                        ))}
                      </Flex>
                    </form>
                  </ModalComponent>
                )}
              />
            </>
          )}
        </GridItem>

        <GridItem>
          <Center height="100%">
            <Asset type="dashboard" value={ProgressSectionType.CREFO_QUERY} htmlHeight="200" />
          </Center>
        </GridItem>
      </Grid>
      {shouldShowWaitingText && <TextComponent mb="6">{t('waiting')}</TextComponent>}
      {reportsRequestOpen && (
        <>
          <Grid
            templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
            gap={6}
            mb={9}
          >
            {reformProductsMap[ReportType.TRAFFIC_LIGHT] &&
              !!disabledReportOptions[ReportType.TRAFFIC_LIGHT] && <TrafficLights />}
            {reformProductsMap[ReportType.SHORT] && !!disabledReportOptions[ReportType.SHORT] && (
              <RiskClass heading={ratingT('riskClass')} report={shortReport} />
            )}
            {reformProductsMap[ReportType.COMPACT] &&
              !!disabledReportOptions[ReportType.COMPACT] && <Score />}
            {reformProductsMap[ReportType.RATING] && !!disabledReportOptions[ReportType.RATING] && (
              <Rating report={ratingReport} heading={ratingT('ratingClass')} />
            )}
            {reformProductsMap[ReportType.BLACK_WHITE] &&
              !!disabledReportOptions[ReportType.BLACK_WHITE] && (
                <BlackWhite report={blackWhiteReport} heading={ratingT('blackWhite')} />
              )}
          </Grid>

          <Flex justifyContent="flex-end">
            <ButtonComponent
              as="a"
              href={paths.operation.inquiryDetails.companyDetails.riskAnalysis(
                inquiryId,
                companyId,
              )}
              leftIcon={<ArrowRightIcon boxSize={6} display="block" />}
              variant="tertiary"
            >
              {t('showKeyFigures')}
            </ButtonComponent>
          </Flex>
        </>
      )}
    </ProgressSection>
  );
};

export default chooseLaneSpecificComponent({
  [InquiryLane.lead]: CrefoQueryStep,
  [InquiryLane.offer]: CrefoQueryStep,
  default: null,
});
