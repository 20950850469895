import React, { Suspense } from 'react';

import { node } from 'prop-types';

import { PARTNERS } from 'constants/partner';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { LOADING_INDICATOR } from 'modules/InquiryDetails/FetchInquiryDetails';
import { choosePartnerSpecificComponent } from 'shared/choosePartnerSpecificComponent';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useSsoLinkHandler } from 'shared/hooks/useSsoLinkHandler';

import { MainContainer } from './styles';

const MainContent = ({ children }) => (
  <MainContainer>
    <Suspense fallback={LOADING_INDICATOR}>{children}</Suspense>
  </MainContainer>
);

MainContent.propTypes = {
  children: node,
};

MainContent.defaultProps = {
  children: null,
};

const DvagMainContent = ({ children }) => {
  useSsoLinkHandler();
  return <MainContainer>{children}</MainContainer>;
};

DvagMainContent.propTypes = {
  children: node,
};

DvagMainContent.defaultProps = {
  children: null,
};

const DisplayCondition = choosePartnerSpecificComponent({
  default: MainContent,
  [PARTNERS.DVAG_SSO]: DvagMainContent,
});

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagMainContent,
  default: DisplayCondition,
});
