import React from 'react';

import { InputWithField } from 'components/Input';
import { LEASING_TERM_IN_MONTHS } from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import { purposeKindSpecificValidationMap } from '../../HirePurchase/fields/HirePurchaseTermInMonths';
import DurationInMonthYearDropDown from '../../shared/DurationInMonthYearDropDown/DurationInMonthYearDropDown';

const LeasingTermInMonths = () => {
  return <InputWithField name={LEASING_TERM_IN_MONTHS} type="number" />;
};

const DvagLeasingTermInMonths = () => {
  return (
    <DurationInMonthYearDropDown
      SpecificValidationMap={purposeKindSpecificValidationMap}
      fieldName={LEASING_TERM_IN_MONTHS}
    />
  );
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: DvagLeasingTermInMonths,
  default: LeasingTermInMonths,
});
