// API names
export enum InquiryType {
  default = 'default',
  bigPicture = 'big_picture',
  onlinefactoring = 'onlinefactoring',
  leaseplan = 'leaseplan',
  bfs = 'bfs',
  profiRLL = 'profi',
  profiMittweida = 'profi_mittweida',
  dvag = 'dvag',
  bfsService = 'bfs_service',
  mmv = 'mmv',
  dzb = 'dzb',
  hausbank = 'hausbank',
}

// FIXME: extending because something has a dependency on InquiryType which
// crashes the page when adding a new inquiry type.Would need more investigation to add an extra inquiry type
export enum ExtendedInquiryType {
  default = 'default',
  bigPicture = 'big_picture',
  onlinefactoring = 'onlinefactoring',
  leaseplan = 'leaseplan',
  bfs = 'bfs',
  profiRLL = 'profi',
  profiMittweida = 'profi_mittweida',
  dvag = 'dvag',
  bfsService = 'bfs_service',
  mmv = 'mmv',
  dzb = 'dzb',
}
