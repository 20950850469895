import React from 'react';

import { useDisclosure } from '@chakra-ui/react';

import { Sidebar, IconWrapper, IconButton, ButtonText } from 'components/Sidebar/styles';
import CallbackForm from 'modules/ContactSidebar/CallbackRequestModal/CallbackForm';
import { Container } from 'modules/ContactSidebar/CallbackSidebar/styles';
import { ButtonComponent } from 'theme/components/Button';
import MailIcon from 'theme/components/Icon/MailIcon';
import { ModalComponent } from 'theme/components/Modal';
import { useTranslations } from 'utils/hooks/useTranslations';
import { isLoggedInUser, userIsAgent } from 'utils/user/conditionals';

const CallbackSidebar: React.FC = () => {
  const t = useTranslations('components.contactSidebar');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLoggedIn = isLoggedInUser();
  const isOperation = userIsAgent();

  const shouldHideContact = !isLoggedIn || isOperation;
  if (shouldHideContact) {
    return null;
  }

  const handleSubmittedForm = () => {
    onClose();
  };

  return (
    <Container>
      <Sidebar>
        <IconWrapper>
          <IconButton onClick={onOpen}>
            <MailIcon boxSize={6} mt={1} mb={1} />
            <ButtonText>{t('contact')}</ButtonText>
          </IconButton>
        </IconWrapper>

        <ModalComponent
          isOpen={isOpen}
          onClose={onClose}
          title={t('callbackRequest.headline')}
          footer={
            <>
              <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
                {t('callbackRequest.buttonCancel')}
              </ButtonComponent>
              <ButtonComponent form="form-callback" type="submit">
                {t('callbackRequest.buttonConfirm')}
              </ButtonComponent>
            </>
          }
        >
          <CallbackForm onSubmitted={handleSubmittedForm} />
        </ModalComponent>
      </Sidebar>
    </Container>
  );
};

export default CallbackSidebar;
