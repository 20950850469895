import { useCallback } from 'react';

import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';

import { useDownloadPdf } from 'api/CompeonReverseApi/operation/queryHooks';
import { useToasts } from 'shared/hooks/useToasts';
import { getInquiryIdSelector } from 'store/inquiryDetails/selectors';

type UsageType = 'current' | 'future';
type FinancingRole = 'developer' | 'partition' | 'stockholder';

const parseFilenameFromContentDisposition = (contentDisposition: string | undefined) => {
  const filename = contentDisposition?.trim() ? decodeURIComponent(contentDisposition).trim() : '';
  return filename.split("''")[1] || null;
};

export const useDownloadPdfFile = () => {
  const { error } = useToasts();
  const downloadPdfMutation = useDownloadPdf({
    onError: () => {
      error({});
    },
  });

  const inquiryId = useSelector(getInquiryIdSelector) ?? '';

  const downloadPdf = useCallback(
    async (financingRole: FinancingRole, usageType: UsageType) => {
      const res = await downloadPdfMutation.mutateAsync({
        financingRole,
        usageType,
        inquiryId,
      });

      const filenameForDownload =
        parseFilenameFromContentDisposition(res.headers['content-disposition']) || 'file_download';

      const blob = new Blob([res.data], {
        type: res.headers['content-type'],
      });
      saveAs(blob, filenameForDownload);
    },
    [inquiryId, downloadPdfMutation],
  );
  return { downloadPdf };
};
