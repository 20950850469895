import * as React from 'react';

import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/react';

import { ButtonComponent } from 'theme/components/Button';
import { ModalComponent } from 'theme/components/Modal';
import { ProgressSection } from 'theme/components/ProgressSection';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

import { TriggerProfitabilitySectionCard } from './TriggerProfitabilitySectionCard';

interface Props {
  isFirst?: boolean;
  isLast?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  completedValue?: number;
  progressIncrement?: number;
  progressTotal?: number;
  id?: string;
}

export const TriggerProfitabilityCalculationStep = ({
  isFirst,
  isLast,
  isCompleted,
  completedValue,
  progressIncrement,
  progressTotal,
  id,
}: Props) => {
  const t = useTranslations(
    'pages.inquiryDetails.dashboard.actions.triggerProfitabilityCalculation',
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <ProgressSection
      isFirst={isFirst}
      isLast={isLast}
      isCompleted={isCompleted}
      completedValue={completedValue}
      progressIncrement={progressIncrement}
      progressTotal={progressTotal}
      id={id}
    >
      <TriggerProfitabilitySectionCard onClick={onOpen} />
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        title={t('modal.title')}
        footer={
          <Flex justifyContent={'flex-end'}>
            <ButtonComponent variant="tertiary" mr={4} onClick={onClose}>
              {t('modal.cancelText')}
            </ButtonComponent>
            <ButtonComponent isLoading={false} onClick={onClose}>
              {t('modal.confirmText')}
            </ButtonComponent>
          </Flex>
        }
        size="md"
      >
        {t('modal.subDescription')
          .split('\n')
          .map((text: string) => (
            <TextComponent mb={4} key={text}>
              {text}
            </TextComponent>
          ))}
      </ModalComponent>
    </ProgressSection>
  );
};
