import { MAX_PHONE_NUMBER_LENGTH, MIN_PHONE_NUMBER_LENGTH } from 'constants/globalConstants';
import { MAX_AMOUNT_VALUE } from 'modules/Inquiry/inquiry.constants';
import { useTranslations } from 'utils/hooks/useTranslations';
import {
  buildValidator,
  equalTo,
  exactLength,
  hasMobilePhonePrefix,
  hasPhonePrefix,
  isEmail,
  isFutureDate,
  isGermanIban,
  isIban,
  isInteger,
  isNumber,
  isPercentage,
  isPhoneNumber,
  isPhoneNumberReal,
  isPositive,
  isPostcode,
  isSameOrAfter,
  isSameOrBefore,
  isValidPassword,
  lowerBound,
  matchesSpecificBankCodes,
  maxLength,
  maxYear,
  minLength,
  minYear,
  mustBeTrue,
  notNegative,
  required,
  selectAtLeastOne,
  under4Months,
  upperBound,
  upperBoundFrom,
} from 'utils/validators';
import { formatPrice } from 'utils/valueFormats';

export const useFieldValidators = () => {
  const t = useTranslations('errors');

  return {
    optional: () => false,
    required: required(t('required')),
    maxLength: (num) => maxLength(num, t('maxLengthTextCharacters', { number: num })),
    minLength: (num) => minLength(num, t('minLengthDigits', { number: num })),
    exactLength: (num) => exactLength(num, t('exactLengthDigits', { number: num })),
    minPhoneLength: minLength(
      MIN_PHONE_NUMBER_LENGTH,
      t('minPhoneNumberLength', { number: MIN_PHONE_NUMBER_LENGTH }),
    ),
    maxPhoneLength: maxLength(
      MAX_PHONE_NUMBER_LENGTH,
      t('maxPhoneNumberLength', { number: MAX_PHONE_NUMBER_LENGTH }),
    ),
    maxMonths: (maxMonths) => upperBound(maxMonths, t('maxMonths', { months: maxMonths })),
    minMonths: (minMonths) => lowerBound(minMonths, t('minMonths', { months: minMonths })),
    isPostalCode: isPostcode(t('zipCode')),
    maxPrice: (price) => upperBound(price, t('maxPrice', { amount: formatPrice(price) })),
    minPrice: (price) => lowerBound(price, t('minPrice', { amount: formatPrice(price) })),
    upperBoundFrom: (fieldName) => upperBoundFrom(fieldName, t('upperBoundFrom')),
    isIban: isIban(t('iban')),
    isGermanIban: isGermanIban(t('notGermanIban')),
    matchesSpecificBankCodes: (bankCodes, partnerName) =>
      matchesSpecificBankCodes(bankCodes, t('doesNotMatchSpecificBankCode', { partnerName })),
    isEmail: isEmail(t('isEmail')),
    isPhoneNumber: isPhoneNumber(t('hasPhoneFormat')),
    hasPhonePrefix: hasPhonePrefix(t('isPhoneNumber')),
    isPhoneNumberReal: isPhoneNumberReal(t('isPhoneNumberReal')),
    hasMobilePhonePrefix: hasMobilePhonePrefix(t('isPhoneNumber')),
    isInteger: isInteger(t('isInteger')),
    isNumber: isNumber(t('isNumber')),
    isPositive: isPositive(t('isPositive')),
    isPercentage: isPercentage(t('percentageMaxLength')),
    noFutureYears: upperBound(new Date().getUTCFullYear(), t('notBiggerThenCurrentYear')),
    minYear: (min) => minYear(min, t('minYear')),
    maxYear: (max) => maxYear(max, t('maxYear')),
    customValidation: buildValidator,
    under4Months: (data) => under4Months(data, t('under4Months')),
    selectAtLeastOne: (validator) => selectAtLeastOne(validator, t('selectOneField')),
    isSameOrBefore: (date) => isSameOrBefore(date, t('dateIsSameOrBefore')),
    isSameOrAfter: (date) => isSameOrAfter(date, t('dateIsSameOrAfter')),
    isValidPassword: isValidPassword(t('isValidPassword')),
    equalTo: (fieldName) => equalTo(fieldName, t('equalsTo')),
    maxAmountPrice: upperBound(
      MAX_AMOUNT_VALUE,
      t('maxPrice', { amount: formatPrice(MAX_AMOUNT_VALUE) }),
    ),
    minValue: (min) => lowerBound(min, t('lowerBound', { amount: min, number: min })),
    maxValue: (max) => upperBound(max, t('upperBound', { number: max })),
    isFutureDate: isFutureDate(t('isFutureDate')),
    notNegative: notNegative(t('isPositive')),
    mustBeTrue: mustBeTrue(t('mustBeTrue')),
    lessThanPurchasePrice: (price, field) =>
      upperBound(price, t('lessThanPurchasePrice', { field })),
    requiredCountry: required(t('requiredCountry')),
  };
};
