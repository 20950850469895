import {
  hausbankFields,
  hausbankFieldsWithoutParents,
  hausbankFieldsWithSectionString,
  HausbankFieldTypes,
} from 'hausbank/inquiry/fields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  customValidations,
  fieldValidators,
} from 'modules/Inquiry/inquiryFieldValidation/customValidations';
import { validateArrayFields, ValidateSectionFunction } from 'new/form/useValidateFields';

export const validateLegalRepresentativesSection: ValidateSectionFunction<InquiryType.hausbank> = (
  form,
  values,
  store,
  conditions,
) => {
  const {
    salutation,
    firstName,
    lastName,
    birthDate,
    email,
    phoneNumber,
    soleSignatureAuthorized,
  } =
    hausbankFieldsWithoutParents.personalDataPage.legalRepresentativesSection.legalRepresentatives;

  const isSoleAuthorizationValid = isValidLegalRepresentatives(values);

  if (isSoleAuthorizationValid) {
    return validateArrayFields({
      arrayValues: values.personalDataPage?.legalRepresentativesSection?.legalRepresentatives || [],
      fieldsWithoutParent: [
        salutation,
        firstName,
        lastName,
        birthDate,
        email,
        phoneNumber,
        soleSignatureAuthorized,
      ],
      parentName:
        hausbankFieldsWithSectionString.personalDataPage.legalRepresentativesSection
          .legalRepresentatives.sectionString,
      form,
      values,
      conditions,
    });
  }

  return false;
};

const { salutation, firstName, lastName, birthDate, email, phoneNumber, soleSignatureAuthorized } =
  hausbankFields.personalDataPage.legalRepresentativesSection.legalRepresentatives;

export const legalRepresentativesSectionValidationMap = {
  [salutation]: fieldValidators.string().required(),
  [firstName]: fieldValidators.string().required(),
  [lastName]: fieldValidators.string().required(),
  [birthDate]: fieldValidators.date().required().max(Date.now()),
  [email]: fieldValidators.string().required().custom(customValidations.isEmail),
  [phoneNumber]: fieldValidators.phoneNumber().required().isPhoneNumberReal(),
  [soleSignatureAuthorized]: fieldValidators.boolean().required(),
};

export const isValidLegalRepresentatives = (values: HausbankFieldTypes): boolean => {
  const legalRepresentatives =
    values.personalDataPage?.legalRepresentativesSection?.legalRepresentatives;

  // No legal representatives, assume it's valid (or handle as you see fit)
  if (!legalRepresentatives || legalRepresentatives.length === 0) {
    return false;
  }

  // Only proceed if there is exactly one legal representative
  if (legalRepresentatives.length === 1) {
    const [legalRepresentative] = legalRepresentatives; // Destructuring to get the first element

    if (legalRepresentative) {
      const isSoleSignatureAuthorized = Boolean(
        JSON.parse((legalRepresentative.soleSignatureAuthorized as unknown as string) ?? 'false'),
      );

      return isSoleSignatureAuthorized;
    }

    return false;
  }

  return true;
};
