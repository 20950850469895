import React from 'react';

import EmptyField from 'components/EmptyField';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import Condition from 'modules/ConditionalSubform/Condition';
import { fieldHasTruthyValue, fieldHasValue } from 'modules/Inquiry/Form/formConditions';
import {
  LEASING_AMORTISATION_KIND,
  LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  LEASING_INSTRESTED_IN_INSURANCE_HELPER,
} from 'modules/Inquiry/Form/formFields';
import LeasingDescription from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Leasing/fields/LeasingDescription';
import LeasingResidualValue from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Leasing/fields/LeasingResidualValue';
import LeasingTakeover from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Leasing/fields/LeasingTakeover';
import LeasingInterestedInCreditFinancing from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/Leasing/LeasingInterestedInCreditFinancing';
import InterestedInInsurance from 'modules/Inquiry/Form/Steps/RequestDetails/ProductKindDetails/shared/InterestedInInsurance';
import { FormSections } from 'schema/inquirySchema.models';
import { useTranslations } from 'utils/hooks/useTranslations';

import LeasingAmortisationKind from './fields/LeasingAmortisationKind';
import LeasingSummary from './LeasingSummary';
import AdvancedDeposit from '../shared/AdvancedDeposit';

export const leasingResidualValueCondition = fieldHasValue(
  LEASING_AMORTISATION_KIND,
  LEASING_AMORTISATION_KIND__PARTIAL_AMORTISATION,
);

const overlappingFieldsCondition = fieldHasTruthyValue(LEASING_AMORTISATION_KIND);

const Leasing = () => {
  const t = useTranslations('pages.requestDetails.sections.leasing');
  return (
    <FormSection name={FormSections.leasing} sectionNumber={1} title={t('title')}>
      <AdvancedDeposit />
      <LeasingSummary />

      <FormRow>
        <LeasingAmortisationKind />
        <LeasingResidualValue />
      </FormRow>

      <Condition
        condition={overlappingFieldsCondition}
        onFail={<EmptyField name={LEASING_AMORTISATION_KIND} />}
      >
        <FormRow separator>
          <LeasingTakeover />
        </FormRow>
        <FormRow>
          <LeasingDescription />
        </FormRow>
        <FormRow>
          <LeasingInterestedInCreditFinancing />
        </FormRow>
        <EmptyField name={LEASING_INSTRESTED_IN_INSURANCE_HELPER} />
      </Condition>

      <InterestedInInsurance />
    </FormSection>
  );
};

export default Leasing;
