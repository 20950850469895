import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import DisabledFormSection from 'components/DisabledFormSection';
import FormSection from 'components/FormSection';
import { hausbankFieldsWithSectionString, HausbankSections } from 'hausbank/inquiry/fields';
import { translations } from 'new/form/common/types';
import { Condition } from 'new/form/Condition';
import { LegalRepresentativeCards } from 'new/legalRepresentatives/LegalRepresentativeCards';
import { HausbankLegalRepresentativeFields } from 'pages/operationPortal/CompaniesDetails/AssiciatedPerson/components/legalRepresentatives/hausbank/HausbankLegalRepresentativeFields';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { TextComponent } from 'theme/components/Text';
import { useTranslations } from 'utils/hooks/useTranslations';

export const HausbankLegalRepresentativesSection = () => {
  const t = useTranslations();
  const { mutators } = useForm();
  const {
    title,
    subheadings: { additionalInformation },
  } = translations.inquiryType.hausbank.pages.personalData.sections.legalRepresentatives;

  const arrayFieldName =
    hausbankFieldsWithSectionString.personalDataPage.legalRepresentativesSection
      .legalRepresentatives.sectionString;

  return (
    <Condition<HausbankSections>
      validation={'passwordSection'}
      fallback={
        <DisabledFormSection
          sectionNumber={3}
          placeholder={t(translations.inquiryType.hausbank.sectionFallback)}
          title={t(title)}
        />
      }
    >
      <FormSection title={t(title)} sectionNumber={3}>
        <TextComponent color={'text.tertiary'} mb={12}>
          {t(additionalInformation)}
        </TextComponent>
        <VStack gap={8}>
          <FieldArray name={arrayFieldName}>
            {({ fields }) => {
              return (
                <LegalRepresentativeCards
                  fields={fields}
                  FieldComponent={HausbankLegalRepresentativeFields}
                  arrayFieldName={arrayFieldName}
                />
              );
            }}
          </FieldArray>

          <ButtonComponent
            leftIcon={<AddIcon boxSize={6} display={'block'} />}
            onClick={() => mutators.push(arrayFieldName)}
            variant={'secondary'}
            alignSelf={'flex-end'}
          >
            {t(
              translations.inquiryType.hausbank.pages.personalData.sections.legalRepresentatives
                .fields.add,
            )}
          </ButtonComponent>
        </VStack>
      </FormSection>
    </Condition>
  );
};
