import React from 'react';

import { VStack } from '@chakra-ui/react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import Condition from 'components/Condition';
import FormRow from 'components/FormRow';
import FormSection from 'components/FormSection';
import { YesNoRadioGroupWithField } from 'components/YesNoRadioGroup';
import { dzbFields, dzbFieldsWithSectionString, DzbFieldTypes } from 'dzb/inquiry/fields';
import { BeneficialOwnersCards } from 'dzb/inquiry/steps/personalData/sections/beneficialOwnersSection/components/BeneficialOwnersCards';
import { translations } from 'new/form/common/types';
import ButtonComponent from 'theme/components/Button';
import AddIcon from 'theme/components/Icon/AddIcon';
import { useTranslations } from 'utils/hooks/useTranslations';

const { allInformationAvailable } = dzbFields.personalDataPage.beneficialOwnersSection;
const arrayFieldName =
  dzbFieldsWithSectionString.personalDataPage.beneficialOwnersSection.beneficialOwners
    .sectionString;

export const BeneficialOwnersSection = () => {
  const { values } = useFormState<DzbFieldTypes>();
  const t = useTranslations();
  const { mutators } = useForm();
  const {
    title,
    fields: {
      allInformationAvailable: { tooltip },
    },
  } = translations.inquiryType.dzb.pages.personalData.sections.beneficialOwners;

  const isAddButtonVisible =
    (values.personalDataPage.beneficialOwnersSection.allInformationAvailable as unknown) === 'true';
  return (
    <FormSection title={t(title)} sectionNumber={3}>
      <FormRow>
        <YesNoRadioGroupWithField name={allInformationAvailable} tooltip={t(tooltip)} />
      </FormRow>
      <VStack gap={8}>
        <FieldArray name={arrayFieldName}>
          {({ fields }) => {
            return <BeneficialOwnersCards fields={fields} />;
          }}
        </FieldArray>

        <Condition condition={isAddButtonVisible}>
          <ButtonComponent
            leftIcon={<AddIcon boxSize={6} display={'block'} />}
            onClick={() => mutators.push(arrayFieldName)}
            variant={'secondary'}
            alignSelf={'flex-end'}
          >
            {t(
              translations.inquiryType.dzb.pages.personalData.sections.beneficialOwners.fields.add,
            )}
          </ButtonComponent>
        </Condition>
      </VStack>
    </FormSection>
  );
};
