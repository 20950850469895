import React from 'react';

import env from '@beam-australia/react-env';
import { Helmet } from 'react-helmet';

import { usePartnerConfig } from 'config/partner/hooks';
import {
  FreshChat,
  FreshWidget,
} from 'modules/ContactSidebar/ContactFooter/ContactDetails/FreshWorks';
import { EnvironmentVariable } from 'utils/environment';
import ETracker from 'utils/tracker/Etracker';
import GoogleTagManager from 'utils/tracker/GoogleTagManager';
import { TrackingTool } from 'utils/tracker/TrackingTool';
import WebtrekkTracker from 'utils/tracker/webtrekk';

import defaultFavicon from './favicon.png';

export const Head = () => {
  const {
    meta: {
      gtmId,
      widgetId,
      chatToken,
      pageTitle,
      faviconUrl,
      webtrekkDomain,
      webtrekkId,
      etrackerId,
      trackingTool,
    },
  } = usePartnerConfig();

  const icon = faviconUrl
    ? `${env(EnvironmentVariable.LOGO_STORAGE_URL)}${faviconUrl}`
    : defaultFavicon;

  return (
    <>
      {gtmId && <GoogleTagManager gtmId={gtmId} />}
      {webtrekkId && <WebtrekkTracker webtrekkId={webtrekkId} webtrekkDomain={webtrekkDomain} />}
      {widgetId && <FreshWidget widgetId={widgetId} />}
      {etrackerId && <ETracker etrekkId={etrackerId} />}
      {chatToken && <FreshChat chatToken={chatToken} />}
      {trackingTool ? <TrackingTool config={trackingTool} /> : null}
      <Helmet>
        {pageTitle && <title>{pageTitle}</title>}
        <link rel="shortcut icon" type="image/png" href={icon} />
      </Helmet>
    </>
  );
};
