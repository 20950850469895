import React from 'react';

import { Redirect, useLocation } from 'react-router-dom';

import ProgressBar from 'components/ProgressBar/ProgressBar';
import { useFormConfig } from 'config/formConfig/hooks';
import paths from 'constants/paths';
import PERMISSIONS from 'constants/user/permissions';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import InquirySettingsProvider from 'modules/Inquiry/InquirySettingsProvider';
import InquirySpecificTypeForm from 'pages/inquiryFlow/InquirySpecificTypeForm';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { hasPermission as hasPermissionCreator } from 'utils/user/conditionals';

import CreateInquiryNotAllowed from './CreateInquiryNotAllowed';

const useNewInquiryGate = () => {
  /* ATTENTION: Inquiry types that should be blocked from accessing /anfragen/neue have to
  have a root path set for new inquiries in src/store/config/reducer.js => startUrlForInquiryType */
  const { pathname } = useLocation();
  const { selectedInquiryType } = useFormConfig();
  const hasPermission = hasPermissionCreator(selectedInquiryType || InquiryType.default);

  const canCreateInquiry =
    hasPermission(PERMISSIONS.INQUIRY.CREATE) || pathname === paths.finalPage;

  const InquiryListRedirect = <Redirect to={paths.customer.inquiryList} />;
  const SubstituteComponent = useSelectedInquiryTypeSpecificValue({
    [InquiryType.dzb]: InquiryListRedirect,
    [InquiryType.mmv]: InquiryListRedirect,
    [InquiryType.leaseplan]: InquiryListRedirect,
    [InquiryType.default]: <CreateInquiryNotAllowed />,
  });

  return { canCreateInquiry, SubstituteComponent };
};

const InquiryCreate = () => {
  const { canCreateInquiry, SubstituteComponent } = useNewInquiryGate();

  if (!canCreateInquiry) {
    return SubstituteComponent;
  }

  return (
    <main>
      <InquirySettingsProvider>
        <ProgressBar />
        <InquirySpecificTypeForm />
      </InquirySettingsProvider>
    </main>
  );
};

export default InquiryCreate;
