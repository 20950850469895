import React from 'react';

import _compact from 'lodash/compact';
import { Field, useForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { SelectWithField } from 'components/Selects/Select';
import { PURPOSE_KIND__REAL_ESTATE, PURPOSE_KIND } from 'modules/Inquiry/Form/formFields';
import { useFormValueSpecificValue } from 'shared/chooseFormValueSpecific';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useTranslations } from 'utils/hooks/useTranslations';
import { combineValidators } from 'utils/validators';

import {
  getDvagRealEstateLoanDurationSelectValues,
  getLoanDurationSelectValues,
} from '../../Loan/LoanDuration/service';
import { PurposeKindSpecificValidationMap } from '../../shared/types';

const purposeKindSpecificOptions: {
  [key: string]: Function;
} = {
  [PURPOSE_KIND__REAL_ESTATE]: getDvagRealEstateLoanDurationSelectValues,
  default: getLoanDurationSelectValues,
};

interface IdropDownFields {
  value: string;
  label: string;
}
interface IdropDownValidator {
  max: string;
  min: string;
}
interface Props {
  SpecificValidationMap: PurposeKindSpecificValidationMap;
  fieldName: string;
  saveValueInFieldName?: string;
}
const DurationInMonthYearDropDown = ({
  SpecificValidationMap,
  fieldName,
  saveValueInFieldName,
}: Props) => {
  const t = useTranslations();
  const { required, minMonths, maxMonths } = useFieldValidators();
  const { change } = useForm();

  let options: Array<IdropDownFields> = useFormValueSpecificValue(
    PURPOSE_KIND,
    purposeKindSpecificOptions,
  )(t, 120);
  const purposeKindSpecificValidation: IdropDownValidator = useFormValueSpecificValue(
    PURPOSE_KIND,
    SpecificValidationMap,
    {},
  );
  const validators = _compact([
    required,
    purposeKindSpecificValidation.min ? minMonths(purposeKindSpecificValidation.min) : null,
    purposeKindSpecificValidation.max ? maxMonths(purposeKindSpecificValidation.max) : null,
  ]);
  if (options && Object.keys(purposeKindSpecificValidation).length > 0)
    options = options.filter(
      (opt: IdropDownFields) =>
        opt.value >= purposeKindSpecificValidation.min &&
        opt.value <= purposeKindSpecificValidation.max,
    );

  return (
    <>
      <SelectWithField
        name={fieldName}
        validate={combineValidators(...validators)}
        options={options}
        data-testid={'select-field-' + fieldName}
      />
      {saveValueInFieldName && saveValueInFieldName !== fieldName ? (
        <>
          <Field name={saveValueInFieldName} render={() => null} />
          <OnChange name={fieldName}>
            {(value: string) => {
              change(saveValueInFieldName, value);
            }}
          </OnChange>
        </>
      ) : null}
    </>
  );
};

export default DurationInMonthYearDropDown;
