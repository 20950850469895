import React from 'react';

import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import PortalPage from 'components/PortalPage';
import paths from 'constants/paths';
import ErrorBoundary from 'modules/ErrorBoundary';
import DataProvider from 'modules/InquiryDetails/FetchInquiryDetails';
import CustomerAdditionalFields from 'pages/customerPortal/InquiryDetails/AdditionalFields';
import CustomerDashboard from 'pages/customerPortal/InquiryDetails/Dashboard';
import DetailedInformation from 'pages/customerPortal/InquiryDetails/DetailedInformation';
import CustomerDocumentExchange from 'pages/customerPortal/InquiryDetails/DocumentExchange/CustomerDocumentExchange';
import NavigationBar from 'pages/customerPortal/InquiryDetails/NavigationBar';
import CustomerPrivateRoute from 'routes/CustomerPrivateRoute';

import CustomerInquiryEditGate from '../InquiryEdit/CustomerInquiryEditGate';

const BigPictureInquiryDetails = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <ErrorBoundary>
      <DataProvider inquiryId={id}>
        <Switch>
          <CustomerPrivateRoute
            path={paths.customer.inquiryDetails.edit.root}
            component={CustomerInquiryEditGate}
          />
          <CustomerPrivateRoute
            path="*"
            render={() => (
              <PortalPage heading={<NavigationBar id={id} />}>
                <Switch>
                  <CustomerPrivateRoute
                    path={paths.customer.inquiryDetails.dashboard}
                    component={CustomerDashboard}
                  />
                  <CustomerPrivateRoute
                    path={paths.customer.inquiryDetails.additionalFields}
                    component={CustomerAdditionalFields}
                  />
                  <CustomerPrivateRoute
                    path={paths.customer.inquiryDetails.documentExchange}
                    component={CustomerDocumentExchange}
                  />
                  <CustomerPrivateRoute
                    path={paths.customer.inquiryDetails.details}
                    component={DetailedInformation}
                  />
                  <Route
                    path="*"
                    render={() => (
                      <Redirect to={paths.customer.inquiryDetails.dashboard.replace(':id', id)} />
                    )}
                  />
                </Switch>
              </PortalPage>
            )}
          />
        </Switch>
      </DataProvider>
    </ErrorBoundary>
  );
};

export default BigPictureInquiryDetails;
