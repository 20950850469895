import { PhoneNumberUtil } from 'google-libphonenumber';

export const phoneNumberUtils = PhoneNumberUtil.getInstance();
export const isPhoneNumberValid = (phoneNumber: string = '') => {
  try {
    const parsedPhoneNumber = phoneNumberUtils.parse(phoneNumber);
    return phoneNumberUtils.isValidNumber(parsedPhoneNumber);
  } catch {
    return false;
  }
};
