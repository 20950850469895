import { LegalRepresentative } from 'hausbank/inquiry/fields';
import { formatDateDays } from 'utils/date';

export function mapHausbankLegalRepresentatives(legalRepresentatives: LegalRepresentative[]) {
  return legalRepresentatives.map((legalRep) => ({
    salutation: legalRep.salutation,
    first_name: legalRep.firstName,
    last_name: legalRep.lastName,
    email: legalRep.email,
    phone_number: legalRep.phoneNumber,
    birth_date: formatDateDays(legalRep.birthDate),
    sole_signature_authorized: legalRep.soleSignatureAuthorized,
  }));
}
