import React from 'react';

import { Box, Spinner } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import endpoints from 'api/CompeonReverseApi/endpoints';
import { useLoadMarketDataRee, MAX_RETRIES } from 'api/CompeonReverseApi/operation/queryHooks';
import { StyledSpinnerWrapper } from 'components/Spinner/styles';
import UploadAnyFile from 'components/UploadBlock/NewFileRequestBlock/UploadAnyFile/UploadAnyFile';
import UploadedFileBlock from 'components/UploadBlock/NewFileRequestBlock/UploadedFileBlock/UploadedFileBlock';
import paths from 'constants/paths';
import { USER_SCOPE } from 'constants/userScopes';
import type { IFile } from 'models/File.model';
import type { ApiFile } from 'models/FileApi.model';
import { EditStepWrapper } from 'modules/SummaryTable/EditStepButton/styles';
import { mapApiFile } from 'shared/documentExchange/documentExchange.service';
import { useFetchInquiryFiles } from 'shared/documentExchange/useFetchInquiryFiles';
import { useToasts } from 'shared/hooks/useToasts';
import { addFileAction } from 'store/documentExchange/documentExchange.actions';
import { getAdditionalFilesSentByRealEstateExpert } from 'store/documentExchange/documentExchange.selectors';
import { getCreatedInquiryId } from 'store/progress/selectors';
import ButtonComponent from 'theme/components/Button';
import EditIcon from 'theme/components/Icon/EditIcon';
import { formatDateWithTime } from 'utils/date';
import { useTranslations } from 'utils/hooks/useTranslations';
import { deepCamelcaseKeys } from 'utils/object';
import { userIsRealEstateExpert } from 'utils/user/conditionals';

import AppraiserTable from './AppraiserTable';
import AppraiserTopBoxes from './AppraiserTopBoxes';
import { AccordionComponent } from '../../../../theme/components/Accordion';
import mapRealEstateValuesToEdit from '../EditMarketValuesReeProfitability/mapRealEstateValuesToEdit';
import { PROFI_RECORD_TYPE } from '../SharedComponent/constants';
import { getMarketDataFilter } from '../SharedComponent/filterManagementReducer';
import ProjectCostingFilters from '../SharedComponent/ProjectCostingFilters';
import ProjectCostingHeader from '../SharedComponent/ProjectCostingHeader';
import useEditFormConfig from '../useEditFormConfig';

export const Appraiser = () => {
  const { error } = useToasts();
  const inquiryId = useSelector(getCreatedInquiryId) || '';
  const { data, isLoading } = useLoadMarketDataRee({
    variables: { inquiryId },
    select: (response) => ({
      profitability: deepCamelcaseKeys(response.data.attributes.details),
      computedProfitability: deepCamelcaseKeys(response.data.attributes.computed_details),
    }),
    retry: (failureCount, responseError) => {
      if (responseError && failureCount === MAX_RETRIES) {
        error({});
        return false;
      }
      return true;
    },
  });
  const { editMarketValueDetailsRee } = paths.operation.inquiryDetails.planningEvaluation;
  const { isLoading: isFilesPending } = useFetchInquiryFiles({
    uploadedBy: USER_SCOPE.OPERATION_PORTAL,
  });
  // FIXME
  // @ts-ignore
  const files: IFile[] = useSelector(getAdditionalFilesSentByRealEstateExpert);
  const t = useTranslations('pages.planningEvaluation.appraiser');
  const tMarketData = useTranslations('pages.planningEvaluation.marketComparison');
  const tBase = useTranslations();

  const { saleType, usageType } = useSelector(getMarketDataFilter);

  const isRealEstateExpertEnabled = userIsRealEstateExpert();

  const uploadUrl = endpoints.INQUIRIES.FILES.LIST.compose({ params: { id: String(inquiryId) } });

  const dispatch = useDispatch();
  const addFileToList = (file: ApiFile) => dispatch(addFileAction(mapApiFile(file)));

  const { onEdit } = useEditFormConfig({
    pathToEdit: editMarketValueDetailsRee(inquiryId),
    recordType: PROFI_RECORD_TYPE.REE,
    editFormStructure: mapRealEstateValuesToEdit(),
  });

  if (isFilesPending || isLoading) {
    return (
      <StyledSpinnerWrapper>
        <Spinner />
      </StyledSpinnerWrapper>
    );
  }

  return (
    <>
      <ProjectCostingHeader
        heading={t('pageTitle')}
        subheading={t('subheading')}
        subheadingDate={
          data?.profitability.realEstateExpertUpdatedAt
            ? formatDateWithTime(data.profitability.realEstateExpertUpdatedAt)
            : '-'
        }
      />

      <ProjectCostingFilters />
      {data ? (
        <AppraiserTopBoxes
          topBoxes={data.computedProfitability.realEstateExpertTopBoxes[usageType][saleType]}
        />
      ) : null}

      <AccordionComponent
        testId="object-evaluation-accordion"
        headingLevel="h3"
        mb={8}
        mt={8}
        title={tMarketData('crousalHeader')}
      >
        {data ? (
          <AppraiserTable appraiser={data.profitability.realEstateExpert[usageType][saleType]} />
        ) : null}

        <EditStepWrapper>
          <ButtonComponent
            testId="edit-object-evaluation-button"
            leftIcon={<EditIcon boxSize={6} display="block" />}
            onClick={onEdit}
            variant="tertiary"
          >
            {tBase('buttons.edit')}
          </ButtonComponent>
        </EditStepWrapper>
      </AccordionComponent>

      <Box boxShadow="xl" p="0">
        {files.length === 0 ? (
          <>
            {isRealEstateExpertEnabled && (
              <UploadAnyFile onUpload={addFileToList} url={uploadUrl} isReePage={true} />
            )}
          </>
        ) : (
          <UploadedFileBlock
            file={files[0]}
            canRemove
            isReeEnabled={isRealEstateExpertEnabled}
            isReePage={true}
          />
        )}
      </Box>
    </>
  );
};
