import React, { useEffect } from 'react';

import { useForm } from 'react-final-form';

import { RadioGroupWithField } from 'components/RadioGroup';
import StaticField from 'components/StaticField';
import {
  INCOME_SURPLUS,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_LEGAL_FORM__AKTIENGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__ANSTALT_DES_OFFENTLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__EINGETRAGENER_KAUFMANN,
  USER_PROFILE_LEGAL_FORM__EINGETRAGENER_VEREIN,
  USER_PROFILE_LEGAL_FORM__EINGETRAGENE_GENOSSENSCHAFT,
  USER_PROFILE_LEGAL_FORM__EINZELUNTERNEHMER,
  USER_PROFILE_LEGAL_FORM__FREIBERUFLICH,
  USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE,
  USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE_AKTIENGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_BURGERLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_STIFTUNG_DES_PRIVATEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__GEWERBLICH,
  USER_PROFILE_LEGAL_FORM__GMBH,
  USER_PROFILE_LEGAL_FORM__GMBH_IN_RUNDUNG,
  USER_PROFILE_LEGAL_FORM__HAFTUNGSBESCHRANKTE,
  USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT_AUF_AKTIEN,
  USER_PROFILE_LEGAL_FORM__KORPERSCHAFT_DES_OFFENTLICHEN_RECHTS,
  USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON,
  USER_PROFILE_LEGAL_FORM__OFFENE_HANDELSGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__OTHER,
  USER_PROFILE_LEGAL_FORM__PARTNERGESELLSCHAFT,
  USER_PROFILE_LEGAL_FORM__UNTERNEHMERGESELLSCHAFT_HAFTUNGSBESCHRANKTE,
} from 'modules/Inquiry/Form/formFields';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { useFieldCaption } from 'modules/Inquiry/useFieldCaption';
import { COMPANY_FIELDS } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/service';
import { useGetSelectedCompanyParam } from 'pages/inquiryFlow/UserProfile/sections/CompletingCompanyDetails/useGetSelectedCompany';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { useFormValues } from 'store/hooks/useFormValues';
import { useTranslations } from 'utils/hooks/useTranslations';

const BilanzField: React.FC = () => {
  const t = useTranslations(
    'pages.userProfile.sections.completingCompanyDetails.fields.financialStatementType',
  );
  const { change } = useForm();

  useEffect(() => {
    change(INCOME_SURPLUS, false);
  }, [change]);

  return <StaticField caption={t('caption')} text={t('balance')} />;
};

const EurOrBilanzField: React.FC = () => {
  const label = useFieldCaption(INCOME_SURPLUS);
  const t = useTranslations();
  const tOptions = useTranslations(
    'pages.userProfile.sections.completingCompanyDetails.fields.incomeSurplus.options',
  );
  const { required } = useFieldValidators();

  const incomeSurplus = useGetSelectedCompanyParam(COMPANY_FIELDS.incomeSurplus);

  if (incomeSurplus) {
    return <StaticField caption={label} text={incomeSurplus ? t('other.yes') : t('other.no')} />;
  }

  return (
    <RadioGroupWithField
      name={INCOME_SURPLUS}
      // FIXME this is incorrectly typed
      // @ts-ignore
      validate={required}
      options={[
        {
          caption: tOptions('euro'),
          value: 'true',
        },
        {
          caption: tOptions('balance'),
          value: 'false',
        },
      ]}
    />
  );
};

const legalFormSpecificMap: { [key: string]: React.FC } = {
  [USER_PROFILE_LEGAL_FORM__GEWERBLICH]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__GMBH]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__AKTIENGESELLSCHAFT]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__EINGETRAGENE_GENOSSENSCHAFT]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__UNTERNEHMERGESELLSCHAFT_HAFTUNGSBESCHRANKTE]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT_AUF_AKTIEN]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE_AKTIENGESELLSCHAFT]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__GMBH_IN_RUNDUNG]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__HAFTUNGSBESCHRANKTE]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__KOMMANDITGESELLSCHAFT]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__OFFENE_HANDELSGESELLSCHAFT]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_BURGERLICHEN_RECHTS]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__PARTNERGESELLSCHAFT]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__EINGETRAGENER_VEREIN]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__GESELLSCHAFT_STIFTUNG_DES_PRIVATEN_RECHTS]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__KORPERSCHAFT_DES_OFFENTLICHEN_RECHTS]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__ANSTALT_DES_OFFENTLICHEN_RECHTS]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__FREIBERUFLICH]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__EINGETRAGENER_KAUFMANN]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__NATURLICHE_PERSON]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__EINZELUNTERNEHMER]: EurOrBilanzField,
  [USER_PROFILE_LEGAL_FORM__GEMEINNUTZIGE]: BilanzField,
  [USER_PROFILE_LEGAL_FORM__OTHER]: EurOrBilanzField,
};
const IncomeSurplus: React.FC = () => {
  const formValues = useFormValues();

  const Component =
    legalFormSpecificMap[formValues[USER_PROFILE_LEGAL_FORM] as string] || EurOrBilanzField;
  return <Component />;
};

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.dvag]: IncomeSurplus,
  default: () => null,
});
