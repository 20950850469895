import PropTypes from 'prop-types';

import { OfferStatusType } from './types/OfferStatus.type';
import { OFFER_STATUSES } from '../modules/Offers/InquiryOffersSection/constants';

export type CompeonOfferProductType =
  | 'loan'
  | 'leasing'
  | 'hirePurchase'
  | 'other'
  | 'factoring'
  | 'investmentLoan'
  | 'mezzanine'
  | 'projectFinancing'
  | 'overdraft';

export interface ICompeonOfferProperty {
  name: string;
  label?: string;
  value: string | number;
}

export interface IBankFile {
  id: string;
  name: string;
  uploaded?: boolean;
}

export interface ICompeonOffer {
  type: 'CompeonOffer';
  id: string;
  status: OfferStatusType;
  bankName: string;
  productName: CompeonOfferProductType;
  fileId?: string;
  questionnaireIds?: string[];
  properties?: ICompeonOfferProperty[];
  requiredFiles: string[];
  filesRequiredByBank: IBankFile[];
  fromPremiumPartner: boolean;
  fromSpecialPartner: boolean;
  hasShownInterest?: boolean;
  state: string;
  createdAt: string;
}

export const CompeonOfferShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  status: PropTypes.oneOf(OFFER_STATUSES).isRequired,
  bankName: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  ).isRequired,
  fileId: PropTypes.string,
  questionnaireIds: PropTypes.arrayOf(PropTypes.string),
  requiredFiles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  filesRequiredByBank: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fromPremiumPartner: PropTypes.bool,
  fromSpecialPartner: PropTypes.bool,
  hasShownInterest: PropTypes.bool,
});
