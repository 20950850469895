import { AxiosResponse } from 'axios/index';
import { createMutation, createQuery } from 'react-query-kit';

import { EcoBankingAxiosClientAuthedInstance } from 'api';
import queryKeys from 'constants/queryKeys';
import { deepCamelcaseKeys } from 'utils/object';

import endpoints from '../../endpoints';

export enum DashboardActionsType {
  FILE_UPLOAD_DASHBOARD_ACTION = 'file_upload_dashboard_action',
  IDENTIFY_AND_SIGN_CONTRACT_DASHBOARD_ACTION = 'identify_and_sign_contract_dashboard_action',
}

interface InquiryQueryVariables {
  id: string;
}

export interface DashboardActionsDetails {
  id: string;
  type: string;
  attributes: {
    expirationInDays: number;
    firstName: string;
    lastName: string;
    status: string;
    url: string;
  };
}

export interface InquiryDashboardAction {
  type: DashboardActionsType;
  id: string;
  attributes: {
    state: string;
    stepsCompleted: number;
    stepsTotal: number;
    details: DashboardActionsDetails | null;
  };
}

interface InquiryDashboardActionsResponce {
  dashboardActions: InquiryDashboardAction[];
}

export const useGetDashboardActions = createQuery<
  InquiryDashboardActionsResponce,
  InquiryQueryVariables
>({
  primaryKey: queryKeys.inquiries.dashboardActions,
  queryFn: ({ queryKey: [_, variables] }) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.DASHBOARD_ACTIONS.compose({
        params: { id: variables.id },
      }),
    ).then((response) => ({
      dashboardActions: deepCamelcaseKeys(response.data.data),
    })),
});

interface UseBigPictureSpecializedProcessQueryVariables {
  id: string;
}
export const useBigPictureSpecializedProcess = createMutation<
  AxiosResponse,
  UseBigPictureSpecializedProcessQueryVariables
>({
  mutationKey: ['specialized_process'],
  mutationFn: (variables) =>
    EcoBankingAxiosClientAuthedInstance.get(
      endpoints.INQUIRIES.BIG_PICTURE_CHECK.compose({ params: { id: variables.id } }),
    ),
});
