import { useSelector } from 'react-redux';

import CONFIG from 'shared/featureFlagConfig/configFromAdmin';
import { useConfig } from 'shared/featureFlagConfig/useConfig';
import useInquiryPermissions from 'shared/hooks/useInquiryPermissionsHook';
import { getInquiryDetailsSelector } from 'store/inquiryDetails/selectors';
import { userIsBankAdvisor } from 'utils/user/conditionals';

interface IsForwardableAttributes {
  canForwardToCompeon: boolean;
  hasBankAdvisorRole: boolean;
  isBankAdvisorEnabled: boolean;
  isCompeonForwardingEnabled: boolean;
}

export const isForwardable = ({
  canForwardToCompeon,
  hasBankAdvisorRole,
  isBankAdvisorEnabled,
  isCompeonForwardingEnabled,
}: IsForwardableAttributes) => {
  const isForwardableToCompeon = isCompeonForwardingEnabled && canForwardToCompeon;
  const isForwardableForUser = !hasBankAdvisorRole || isBankAdvisorEnabled;

  return {
    isForwardable: isForwardableToCompeon || isForwardableForUser,
    isForwardableToCompeon,
    isForwardableForUser,
  };
};

export const useInquiryForwarding = () => {
  const hasBankAdvisorRole = userIsBankAdvisor();
  const inquiryDetails = useSelector(getInquiryDetailsSelector);
  const isCompeonForwardingEnabled = useConfig(CONFIG.IS_COMPEON_FORWARDING_ENABLED);
  const isBankAdvisorEnabled = useConfig(CONFIG.IS_BANK_ADVISOR_ENABLED);
  const { canForwardToCompeon } = useInquiryPermissions();
  const hasBeenForwardedToCompeon = inquiryDetails && inquiryDetails.wasForwardToCompeon;

  return {
    hasBeenForwardedToCompeon,
    ...isForwardable({
      canForwardToCompeon,
      hasBankAdvisorRole,
      isBankAdvisorEnabled,
      isCompeonForwardingEnabled,
    }),
  };
};
