import React from 'react';

import InquiryTypeCondition from 'components/InquiryTypeCondition';
import { DzbEditCustomerPortalForm } from 'dzb/inquiry';
import { HausbankCreateForm } from 'hausbank/inquiry';
import { MittweidaCreateForm } from 'mittweida/inquiry';
import { MmvEditCustomerPortalForm } from 'mmv/inquiry';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { InquiryProcessConfigProvider } from 'new/form/state/inquiryProcessConfig';
import { OnlinefactoringForm } from 'onlinefactoring/OnlinefactoringForm';

import { BfsForm } from './BfsForm';
import { BfsServiceForm } from './BfsServiceForm';
import { BigPictureForm } from './BigPictureForm';
import { DefaultForm } from './DefaultForm';
import { DvagForm } from './DvagForm';
import { LeasePlanForm } from './LeasePlan';
import { ProfiForm } from './ProfiForm';

const FormRouter = () => {
  return (
    <InquiryTypeCondition
      cases={{
        big_picture: <BigPictureForm />,
        onlinefactoring: <OnlinefactoringForm />,
        default: <DefaultForm />,
        leaseplan: <LeasePlanForm />,
        bfs: <BfsForm />,
        profi: <ProfiForm />,
        [InquiryType.dzb]: (
          <InquiryProcessConfigProvider>
            <DzbEditCustomerPortalForm />
          </InquiryProcessConfigProvider>
        ),
        [InquiryType.profiMittweida]: (
          <InquiryProcessConfigProvider>
            <MittweidaCreateForm />
          </InquiryProcessConfigProvider>
        ),
        [InquiryType.mmv]: (
          <InquiryProcessConfigProvider>
            <MmvEditCustomerPortalForm />
          </InquiryProcessConfigProvider>
        ),
        [InquiryType.dvag]: <DvagForm />,
        [InquiryType.bfsService]: <BfsServiceForm />,
        [InquiryType.hausbank]: (
          <InquiryProcessConfigProvider>
            <HausbankCreateForm />
          </InquiryProcessConfigProvider>
        ),
      }}
    />
  );
};

export default FormRouter;
