import { useCallback, useEffect, useState } from 'react';

import _get from 'lodash/get';
import intersection from 'lodash/intersection';
import { useSelector } from 'react-redux';

import { API_METHODS, callReverseApi } from 'api/apiService';
import endpoints from 'api/CompeonReverseApi/endpoints';
import {
  END_USER_EMAIL,
  END_USER_FIRST_NAME,
  END_USER_GENDER,
  END_USER_LAST_NAME,
  END_USER_PHONE_NUMBER,
} from 'modules/Inquiry/Form/formFields';
import { getCompany } from 'store/companies/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { getParsedItemFromSessionStorage } from 'utils/sessionStorage/helpers';
import { USER_PROFILE } from 'utils/sessionStorage/keys';

const getCompanyLastEndCustomer = (companyId) =>
  callReverseApi({
    url: endpoints.COMPANIES.LAST_CUSTOMER.compose({ params: { id: companyId } }),
    method: API_METHODS.GET,
  });

// eslint-disable-next-line camelcase
const mapCompanyLastEndCustomer = ({ email, salutation, first_name, last_name, phone_number }) => ({
  [END_USER_FIRST_NAME]: first_name,
  [END_USER_LAST_NAME]: last_name,
  [END_USER_EMAIL]: email,
  [END_USER_PHONE_NUMBER]: phone_number,
  [END_USER_GENDER]: salutation,
});

const areEndCustomerDataAlreadyFilled = () => {
  const userProfile = getParsedItemFromSessionStorage(USER_PROFILE) || {};

  return Boolean(
    intersection(Object.keys(userProfile), [
      END_USER_FIRST_NAME,
      END_USER_LAST_NAME,
      END_USER_EMAIL,
      END_USER_GENDER,
      END_USER_PHONE_NUMBER,
    ]).length,
  );
};

export const usePrefillCompanyLastEndCustomer = () => {
  const [isPending, setIsPending] = useState(true);
  const { makeCall } = useDispatchApiCall();
  const { id: companyId } = useSelector(getCompany) || {};
  const [companyLastEndCustomer, setCompanyLastEndCustomer] = useState({});

  const initLastCompanyEndUser = useCallback(async () => {
    if (companyId && !areEndCustomerDataAlreadyFilled()) {
      const { payload, error } = await makeCall(getCompanyLastEndCustomer(companyId));
      const attributes = _get(payload, 'data.data.attributes');
      if (!error && attributes) {
        setCompanyLastEndCustomer(mapCompanyLastEndCustomer(attributes));
      }
    }
    setIsPending(false);
  }, [makeCall, companyId, setIsPending]);

  useEffect(() => {
    initLastCompanyEndUser();
  }, [initLastCompanyEndUser]);

  return { isPending, companyLastEndCustomer };
};
