import { useTranslations } from 'utils/hooks/useTranslations';
import { formatDecimal } from 'utils/valueFormats';

import { EvaluationRentData } from './types';

export const getHeaders = (t: ReturnType<typeof useTranslations>) => {
  const translate = (key: string) =>
    t(`pages.planningEvaluation.profitabilityCalculation.sections.rent.tableHeaders.${key}`);

  return [
    {
      key: 'label',
      text: translate('type'),
    },
    {
      key: 'customerNow',
      text: translate('customerNow'),
    },
    {
      key: 'customerPlan',
      text: translate('customerPlan'),
    },
    {
      key: 'marketData',
      text: translate('marketData'),
    },
    {
      key: 'realEstateExpert',
      text: translate('realEstateExpert'),
    },
  ];
};

export const getData = (
  t: ReturnType<typeof useTranslations>,
  { baseValues, customerNow, customerPlan, marketData, realEstateExpert }: EvaluationRentData,
) => {
  const translate = (key: string) =>
    t(`pages.planningEvaluation.profitabilityCalculation.sections.rent.tableRows.${key}`);
  const defaultCurrency = t('other.defaultCurrency');

  return [
    {
      label: translate('residentialRentSqm'),
      customerNow: formatDecimal(customerNow.residentialRentSqm, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.residentialRentSqm, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.residentialRentSqm, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.residentialRentSqm, {
        unit: defaultCurrency,
      }),
    },
    {
      label: translate('commercialRentSqm'),
      customerNow: formatDecimal(customerNow.commercialRentSqm, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.commercialRentSqm, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.commercialRentSqm, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.commercialRentSqm, {
        unit: defaultCurrency,
      }),
    },
    {
      label: translate('parkingRentUnit'),
      customerNow: formatDecimal(customerNow.parkingRentUnit, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.parkingRentUnit, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.parkingRentUnit, { unit: defaultCurrency }),
    },
    {
      label: translate('garageRentUnit'),
      customerNow: formatDecimal(customerNow.garageRentUnit, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.garageRentUnit, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.garageRentUnit, { unit: defaultCurrency }),
    },
    {
      label: translate('residentialRent'),
      customerNow: formatDecimal(customerNow.residentialRent, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.residentialRent, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.residentialRent, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.residentialRent, { unit: defaultCurrency }),
    },
    {
      label: translate('commercialRent'),
      customerNow: formatDecimal(customerNow.commercialRent, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.commercialRent, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.commercialRent, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.commercialRent, { unit: defaultCurrency }),
    },
    {
      label: translate('parkingRent'),
      customerNow: formatDecimal(customerNow.parkingRent, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.parkingRent, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.parkingRent, { unit: defaultCurrency }),
    },
    {
      label: translate('garageRent'),
      customerNow: formatDecimal(customerNow.garageRent, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.garageRent, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.garageRent, { unit: defaultCurrency }),
    },
    {
      label: translate('totalRent'),
      customerNow: formatDecimal(customerNow.totalRent, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.totalRent, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.totalRent, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.totalRent, { unit: defaultCurrency }),
      rowHighlight: true,
    },
    {
      label: translate('operatingCostsResidential'),
      customerNow: formatDecimal(customerNow.operatingCostsResidential, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.operatingCostsResidential, {
        unit: defaultCurrency,
      }),
      marketData: formatDecimal(marketData.operatingCostsResidential, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.operatingCostsResidential, {
        unit: defaultCurrency,
      }),
    },
    {
      label: translate('operatingCostsCommercial'),
      customerNow: formatDecimal(customerNow.operatingCostsCommercial, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.operatingCostsCommercial, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.operatingCostsCommercial, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.operatingCostsCommercial, {
        unit: defaultCurrency,
      }),
    },
    {
      label: translate('noi'),
      customerNow: formatDecimal(customerNow.noi, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.noi, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.noi, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.noi, { unit: defaultCurrency }),
      rowHighlight: true,
    },
    {
      label: translate('interestPayment'),
      customerNow: formatDecimal(customerNow.interestPayment, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.interestPayment, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.interestPayment, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.interestPayment, { unit: defaultCurrency }),
    },
    {
      label: translate('depreciation'),
      customerNow: formatDecimal(baseValues.depreciation, { unit: defaultCurrency }),
      customerPlan: formatDecimal(baseValues.depreciation, { unit: defaultCurrency }),
      marketData: formatDecimal(baseValues.depreciation, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(baseValues.depreciation, { unit: defaultCurrency }),
    },
    {
      label: translate('outcomeBeforeTax'),
      customerNow: formatDecimal(customerNow.outcomeBeforeTax, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.outcomeBeforeTax, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.outcomeBeforeTax, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.outcomeBeforeTax, { unit: defaultCurrency }),
      rowHighlight: true,
    },
    {
      label: translate('taxPayment'),
      customerNow: formatDecimal(customerNow.taxPayment, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.taxPayment, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.taxPayment, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.taxPayment, { unit: defaultCurrency }),
    },
    {
      label: translate('outcomeAfterTax'),
      customerNow: formatDecimal(customerNow.outcomeAfterTax, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.outcomeAfterTax, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.outcomeAfterTax, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.outcomeAfterTax, { unit: defaultCurrency }),
      rowHighlight: true,
    },
    {
      label: translate('ecfAfterTax'),
      customerNow: formatDecimal(customerNow.ecfAfterTax, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.ecfAfterTax, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.ecfAfterTax, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.ecfAfterTax, { unit: defaultCurrency }),
      rowHighlight: true,
    },
    {
      label: translate('interestPayment'),
      customerNow: formatDecimal(customerNow.interestPayment, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.interestPayment, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.interestPayment, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.interestPayment, { unit: defaultCurrency }),
    },
    {
      label: translate('repayment'),
      customerNow: formatDecimal(customerNow.repayment, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.repayment, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.repayment, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.repayment, { unit: defaultCurrency }),
    },
    {
      label: translate('surplus'),
      customerNow: formatDecimal(customerNow.surplus, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.surplus, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.surplus, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.surplus, { unit: defaultCurrency }),
    },
    {
      label: translate('dscr'),
      customerNow: formatDecimal(customerNow.dscr),
      customerPlan: formatDecimal(customerPlan.dscr),
      marketData: formatDecimal(marketData.dscr),
      realEstateExpert: formatDecimal(realEstateExpert.dscr),
    },
    {
      label: translate('financingFactor'),
      customerNow: formatDecimal(customerNow.financingFactor),
      customerPlan: formatDecimal(customerPlan.financingFactor),
      marketData: formatDecimal(marketData.financingFactor),
      realEstateExpert: formatDecimal(realEstateExpert.financingFactor),
    },
    {
      label: translate('annuity'),
      customerNow: formatDecimal(customerNow.annuity, { unit: defaultCurrency }),
      customerPlan: formatDecimal(customerPlan.annuity, { unit: defaultCurrency }),
      marketData: formatDecimal(marketData.annuity, { unit: defaultCurrency }),
      realEstateExpert: formatDecimal(realEstateExpert.annuity, { unit: defaultCurrency }),
    },
  ];
};
