import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { chooseSelectedInquiryTypeSpecificComponent } from 'shared/chooseSelectedInquiryTypeSpecificComponent';

import DvagExistingUserCompanySelect from './DvagExistingUserCompanySelect';
import ExistingUserCompanySelect from './ExistingUserCompanySelect';
import { MittweidaExistingUserCompanySelect } from '../../../../../../mittweida/inquiry/steps/company/sections/companyDetails/components/companySuggestion/MittweidaExistingUserCompanySelect';

export default chooseSelectedInquiryTypeSpecificComponent({
  [InquiryType.profiMittweida]: MittweidaExistingUserCompanySelect,
  [InquiryType.dvag]: DvagExistingUserCompanySelect,
  [InquiryType.hausbank]: MittweidaExistingUserCompanySelect,
  default: ExistingUserCompanySelect,
});
