import React from 'react';

import { SelectWithField } from 'components/Selects/Select';
import { useRecalculateProgressBarOnMount } from 'modules/FormPage';
import {
  COMPANY_DETAILS_COMPANY,
  COMPANY_DETAILS_COMPANY__NOT_FOUND,
  END_USER_FIRST_NAME,
} from 'modules/Inquiry/Form/formFields';
import { useFieldValidators } from 'shared/hooks/useFieldValidators';
import { endUserFromSsoPersist } from 'shared/hooks/useSsoLinkHandler';
import { useTranslations } from 'utils/hooks/useTranslations';

import { SuggestionOption } from '../../CompanySuggestion/useFetchCompanySuggestions';

export const SelectPossibleCompanies = ({
  companies,
  onChangeClick,
}: {
  companies: SuggestionOption[];
  onChangeClick: Function;
}) => {
  useRecalculateProgressBarOnMount();

  const t = useTranslations('pages.companyDetails.sections.company.fields');
  const { required } = useFieldValidators();
  const uniqueIds: string[] = [];

  // Mapping for unique key and filtering to remove duplicates
  const companyOptions =
    !!companies &&
    companies
      .map((company) => ({
        label: company.label,
        value: company.value,
        key: company.value['crefo-id'],
        isItemBold: true,
      }))
      .filter((element) => {
        const isDuplicate = uniqueIds.includes(element.value['crefo-id']);
        if (!isDuplicate) {
          uniqueIds.push(element.value['crefo-id']);
          return true;
        }
        return false;
      });

  const additionalOption = {
    label: t('companyDetailsExistingUserCompany.additionalOption.labelOnSelected'),
    value: COMPANY_DETAILS_COMPANY__NOT_FOUND,
    key: `id-${Math.random().toString(36).substring(2, 9)}`,
    greyBackground: true,
    isItemBold: true,
  };

  const ssoData = endUserFromSsoPersist.sessionStorage;
  const userFirstName = ssoData && ssoData[END_USER_FIRST_NAME];

  return (
    <SelectWithField
      name={COMPANY_DETAILS_COMPANY}
      caption={t('companyDetailsCompany.caption')}
      validate={required}
      options={[...companyOptions, additionalOption]}
      onChangeInputValue={(value: string) => {
        onChangeClick(value);
      }}
      initial={userFirstName}
      data-testid={'select-field-' + COMPANY_DETAILS_COMPANY}
    />
  );
};
