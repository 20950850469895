import { ProductKindType } from 'models/ProductKind.model';
import { PurposeKind } from 'models/PurposeKind.model';
import {
  PURPOSE_KIND,
  FINANCING_NEED,
  PURPOSE_KIND__OTHER,
  FINANCING_AMOUNT,
  OTHER_PURPOSE_DESCRIPTION,
  PURPOSE_KIND__OFFICE_EQUIPMENT,
  OFFICE_EQUIPMENT_PRICE,
  OFFICE_EQUIPMENT_DESCRIPTION,
  PURPOSE_KIND__MACHINE,
  MACHINE_CATEGORY,
  MACHINE_PRICE,
  MACHINE_PERIOD_OF_USE,
  MACHINE_KIND,
  LEASING_ADVANCE_PAYMENT,
  LEASING_TERM_IN_MONTHS,
  LEASING_AMORTISATION_KIND,
  LEASING_RESIDUAL_VALUE,
  LEASING_TAKEOVER,
  LEASING_DESCRIPTION,
  LEASING_INTERESTED_IN_CREDIT_FINANCING,
  LEASING_INSTRESTED_IN_INSURANCE_HELPER,
  LEASING_VEHICLE_INTERESTED_IN_INSURANCE,
  LEASING_MACHINE_INTERESTED_IN_INSURANCE,
  MACHINE_MANUFACTURER,
  MACHINE_NEW,
  MACHINE_AGE,
  MACHINE_ORIGINAL_PRICE,
  HIRE_PURCHASE_ADVANCE_PAYMENT,
  HIRE_PURCHASE_TERM_IN_MONTHS,
  HIRE_PURCHASE_AMORTISATION_KIND,
  HIRE_PURCHASE_AMORTISATION_KIND__FULL_AMORTISATION,
  HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  HIRE_PURCHASE_RESIDUAL_VALUE,
  HIRE_PURCHASE_DESCRIPTION,
  HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
  INVESTMENT_LOAN_ADVANCE_PAYMENT,
  INVESTMENT_LOAN_TERM_IN_MONTHS,
  INVESTMENT_LOAN_AMORTISATION_KIND,
  INVESTMENT_LOAN_AMORTISATION_KIND__FULL_AMORTISATION,
  INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION,
  INVESTMENT_LOAN_RESIDUAL_VALUE,
  INVESTMENT_LOAN_DESCRIPTION,
  INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
  MACHINE_IS_ORDERED,
  LOAN_TYPE,
  FACTORING_TURNOVER_CLASS,
  FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
  FACTORING_FOREIGN_SHARE,
  FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
  FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
  FACTORING_COMMERCIAL_CREDIT_INSURANCE,
  FACTORING_ACTIVE_CUSTOMERS,
  FACTORING_BILLS_RANGE,
  FACTORING_ALREADY_IN_USE,
  FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY,
  FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON,
  FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS,
  PROPERTY_LOAN_KIND,
  PROPERTY_LOAN_TERM_IN_MONTHS,
  PROPERTY_LOAN_DESCRIPTION,
  LOAN_TERM,
  LOAN_FIXED_INTEREST_RATE,
  LOAN_INTEREST_PERIOD_IN_MONTHS,
  LOAN_CONSIDER_SUBSIDIES,
  LOAN_DESCRIPTION,
  MACHINE_DELIVERY_DATE,
  PURPOSE_KIND__REAL_ESTATE,
  REAL_ESTATE_PROJECT,
  REAL_ESTATE_KIND,
  REAL_ESTATE_SCHEDULED_DATE,
  REAL_ESTATE_LAND_SIZE,
  REAL_ESTATE_CONSTRUCTION_DATE,
  REAL_ESTATE_BUILDING_SIZE,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT,
  REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
  REAL_ESTATE_RENTED_OUT_AREA,
  REAL_ESTATE_COLD_RENT,
  REAL_ESTATE_ZIP_CODE,
  REAL_ESTATE_USAGE_KIND,
  REAL_ESTATE_TAXATION,
  REAL_ESTATE_PURCHASE_PRICE,
  REAL_ESTATE_FOLLOW_UP_AMOUNT,
  REAL_ESTATE_RECONSTRUCTION_COST,
  REAL_ESTATE_PROJECT_DESCRIPTION,
  REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
  REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
  PURPOSE_KIND__GOODS,
  GOODS__PURCHASE_PRICE,
  GOODS__DESCRIPTION,
  GOODS__SUPPLIER,
  GOODS__ESTIMATED_DELIVERY_DATE,
  PURPOSE_KIND__BUILDING,
  BUILDING_PROJECT,
  BUILDING_TYPE,
  BUILDING_TYPE_DESCRIPTION,
  BUILDING_COOWNERSHIP,
  BUILDING_CONDOMINIUM_NUM,
  BUILDING_NUMBER_OF_RESIDENTIAL_UNITS,
  BUILDING_TOTAL_USABLE_SPACE,
  BUILDING_LIVING_SPACE,
  BUILDING_COMMERCIAL_SPACE,
  BUILDING_SHARE_OF_COMMERCIAL_SPACE,
  BUILDING_TYPE_OF_USE,
  BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
  BUILDING_RENTED_SPACE_COMMERCIAL,
  BUILDING_COLD_RENT_PER_YEAR_LIVING,
  BUILDING_RENTED_SPACE_LIVING,
  BUILDING_CONSTRUCTION_YEAR,
  BUILDING_LATEST_SUBSTENCIAL_MODERNISATION,
  BUILDING_STREET_AND_HOUSE_NUMBER,
  BUILDING_POSTAL_CODE,
  BUILDING_PLACE,
  BUILDING_PURCHASE_PRICE,
  BUILDING_PLOT_PRICE,
  BUILDING_ADDITIONAL_COSTS,
  BUILDING_CONSTRUCTION_MANUFACTURE_COST,
  BUILDING_MODERNISATION_COSTS,
  BUILDING_DEBT_REDEMPTION,
  BUILDING_EQUITY,
  BUILDING_PERSONAL_CONTRIBUTION,
  BUILDING_PROPERTY,
  BUILDING_BORROWED_CAPITAL,
  BUILDING_DEBT_CAPITAL_OTHER,
  PURPOSE_KIND__DEBT_RESCHEDULING,
  DEBT_RESCHEDULING_DESCRIPTION,
  PURPOSE_KIND__CORONA,
  CORONA_FOUNDING_YEAR,
  CORONA_FOUNDING_MONTH,
  CORONA_VIABILTIY,
  CORONA_VIABILTIY_2020,
  CORONA_NUMBER_OF_EMPLOYEES,
  CORONA_REVENUE_OF_2019,
  CORONA_FINANCING_OPTION,
  CORONA_FINANCING_OPTION__QUICK_CREDIT,
  CORONA_FINANCING_OPTION__KFW_PROGRAM,
  CORONA_TOTAL_ASSETS,
  CORONA_EXPENDITURE_OF_EMPLOYEES,
  CORONA_LIQUIDITY_NEED,
  PURPOSE_KIND__GUARANTY,
  PURPOSE_KIND__OVERDRAFT_LIQUIDITY,
  PURPOSE_KIND__PROJECT_FINANCING,
  PROJECT_FINANCING_ROLE,
  PROJECT_FINANCING_INVESTMENT_AMOUNT,
  PROJECT_FINANCING_OWN_FUNDS,
  PROJECT_FINANCING_OWN_WORK,
  PROJECT_FINANCING_MEZZANINE,
  PROJECT_FINANCING_SUBORDINATED_CAPITAL,
  PROJECT_FINANCING_OBJECT_ADDRESS,
  PROJECT_FINANCING_OBJECT_ZIP_CODE,
  PROJECT_FINANCING_OBJECT_CITY,
  PROJECT_FINANCING_OBJECT_DESCRIPTION,
  PROJECT_FINANCING_LOT_SIZE,
  PROJECT_FINANCING_TYPE_OF_USAGE,
  PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS,
  PROJECT_FINANCING_USAGE_SPACE,
  PROJECT_FINANCING_PERCENTAGE_OF_RENT,
  PROJECT_FINANCING_RENT_NOW,
  PROJECT_FINANCING_RENT_FUTURE,
  PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING_LIST_OF_RENTERS,
  PROJECT_FINANCING_KNOWS_ANNUAL_RENT,
  PROJECT_FINANCING_ANNUAL_RENT_AMOUNT,
  PROJECT_FINANCING_PLAN_TO_INCREASE_ANNUAL_RENT,
  PROJECT_FINANCING_DESCRIPTION_OF_THE_MEASURE,
  PROJECT_FINANCING_ANCHOR_TENANT,
  PROJECT_FINANCING_RENTAL_CONTRACTS,
  PROJECT_FINANCING_RENOVATION_PLANNED,
  PROJECT_FINANCING_RENOVATION_DESCRIPTION,
  PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE,
  PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST,
  PROJECT_FINANCING_HAS_BUILD_PERMIT,
  PROJECT_FINANCING__BUILDING_YEAR,
  PROJECT_FINANCING__MODERNIZATION_YEAR,
  PROJECT_FINANCING__FURNISHING_QUALITY,
  PROJECT_FINANCING__QUALITY_CONDITION,
  PROJECT_FINANCING__GUEST_BATHROOM,
  PROJECT_FINANCING__BALKONY,
  PROJECT_FINANCING__LIFT,
  PROJECT_FINANCING__CELLAR,
  PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
  PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
  PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
  PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
  PROJECT_FINANCING__GARAGES_RENT_NOW,
  PROJECT_FINANCING__GARAGES_RENT_FUTURE,
  PROJECT_FINANCING__NUMBER_OF_GARAGES,
  PROJECT_FINANCING__NUMBER_OF_UNITS,
  PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES,
  PROJECT_FINANCING__OBJECT_USAGE,
  PURPOSE_KIND__VEHICLE,
  VEHICLE_PURCHASE_PRICE,
  VEHICLE_COUNT,
  VEHICLE_NEW,
  VEHICLE_YEAR,
  VEHICLE_KIND,
  VEHICLE_OTHER_DESCRIPTION,
  VEHICLE_MANUFACTURER,
  VEHICLE_MODEL,
  VEHICLE_YEARLY_MILEAGE,
  REQUEST_DETAILS,
  PRODUCT_KIND,
  PRODUCT_KIND__FACTORING,
  PRODUCT_KIND__PROPERTY_LOAN,
  PRODUCT_KIND__LOAN,
  PRODUCT_KIND__HIRE_PURCHASE,
  PRODUCT_KIND__INVESTMENT_LOAN,
  PRODUCT_KIND__PROJECT_FINANCING,
  PRODUCT_KIND__MEZZANINE,
  PRODUCT_KIND__OTHER,
  PRODUCT_KIND__OVERDRAFT,
  PRODUCT_KIND__LEASING,
  OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING,
  OVERDRAFT_END_AT,
  OVERDRAFT_TERMINABILITY,
  MEZZANINE_DESCRIPTION,
  PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING,
  OTHER_PRODUCT_DESCRIPTION,
  COMPANY_DETAILS,
  COMPANY_DETAILS_EMAIL,
  COMPANY_DETAILS_GENDER,
  COMPANY_DETAILS_FIRST_NAME,
  COMPANY_DETAILS_LAST_NAME,
  USER_PROFILE,
  FAVORED_DECISION_DATE,
  USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
  USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
  USER_PROFILE_TURNOVER_CLASS,
  USER_PROFILE_FOUNDING_YEAR,
  USER_PROFILE_FOUNDING_MONTH,
  USER_PROFILE_LEGAL_FORM,
  USER_PROFILE_INDUSTRY,
  BFS_USER_PROFILE_INDUSTRY,
  CONTACT_PERSON_STEP,
  BANK_DETAILS_IBAN,
  USER_PROFILE_PHONE_NUMBER,
  PEOPLE_DETAILS,
  USER_PROFILE_BIRTHDATE,
  VEHICLE_INTERESTED_IN_INSURANCE,
  MACHINE_INTERESTED_IN_INSURANCE,
  INTERESTED_IN_INSURANCE,
  PURPOSE_KIND__KFINANZ,
  PURPOSE_KIND__ENERGY_EFFICIENCY,
  PURPOSE_KIND__CREFO_FACTORING,
  CLIENT_ROLE,
  CLIENT_ROLE__CONTRACTOR,
  CLIENT_ROLE__COMPANY,
  NUMBER_OF_TECHNOLOGIES,
  MACHINE_CUSTOM_CATEGORY,
  TECHNOLOGY_DESCRIPTION,
  PRODUCT_KIND__AVAL,
  FUNDING_CLOSING_DATE,
  CONTACT_PERSON_DETAILS,
  CONTACT_PERSON_FIRST_NAME,
  CONTACT_PERSON_EMAIL,
  CONTACT_PERSON_PHONE,
  CONTACT_PERSON_LAST_NAME,
  PARTNER_SPECIFIC_IBAN,
  PRODUCT_KIND__LIQUIDITY,
  INVESTMENT_LOAN_TERM_IN_YEARS,
  FURTHER_NEED,
  PUBLIC_FUNDING,
  FUNDRAISING,
  OTHER_FINANCING_SOLUTIONS,
  EXPERT_ADVICE_ON_ALL,
  PAYMENT_TRANSACTIONS,
  INVESTMENT_AND_SECURITIES,
  FURTHER_NEED_VALUES,
  LIQUIDITY_DESCRIPTION,
  VEHICLE_DESCRIPTION,
  MACHINE_DESCRIPTION,
  MACHINE_COUNT,
  REAL_ESTATE_TOTAL_COST,
  REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION,
  GUARANTY_DESCRIPTION,
  GUARANTY_OTHER_TYPE,
  GUARANTY_RENTAL_DEPOSIT,
  GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS,
  GUARANTY_PERFORMANCE_OF_CONTRACT,
  GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS,
  GUARANTY_WARRANTY,
  GUARANTY_LITIGATION_BOND,
  GUARANTY_TYPE,
  PRODUCT_KIND__CREFO_FACTORING,
  OFFER_FACTORING_LINE,
  OFFER_FACTORING_FEE,
  OFFER_PAYOUT_RATIO,
  USER_PROFILE_FOUNDING_TWO_YEAR_OLD,
  PRODUCT_KIND__KFINANZ,
  PURPOSE_KIND__FACTOMAT,
  PURPOSE_KIND_BFS_SERVICE,
  BFS_SERVICE__MONTHLY_TURNOVER,
  BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
  PRE_FINANCING_PERIOD,
  BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
  BFS_SERVICE__USE_FACTORING_AS_FINANCING,
  BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER,
  BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
  PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING,
  BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING,
  BFS_SERVICE__COMPANY_OTHER_INDUSTRY,
  COMPANY_DETAILS_COMPANY_TAX_NUMBER,
  COMPANY_DETAILS_INSTITUTIONAL_NUMBERS,
  END_USER_GENDER,
  END_USER_LAST_NAME,
  END_USER_FIRST_NAME,
  END_USER_EMAIL,
  END_USER_PHONE_NUMBER,
  PRODUCT_KIND__BFSS,
  BFS_SERVICE__FACTORING_OFFER,
  BFS_SERVICE__SOURCE,
  LEGAL_REPRESENTATIVE_DETAILS,
  PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
  BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE,
  BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT,
  BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE,
  BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE,
  BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS,
  BFS_SERVICE__EXPLICIT_NEED_SEMINARS,
} from 'modules/Inquiry/Form/formFields';
import { InquiryLane } from 'modules/Inquiry/InquiryLane';
import {
  ALREADY_ASSIGNED,
  ALREADY_FACTORING,
  BUSINESS_SPECIFICS,
  DOWN_PAYMENT,
  INVOICES_PER_YEAR,
  MASTER_CRAFTSMAN,
  NUMBER_OF_CUSTOMERS,
  NUMBER_OF_INVOICES,
  NUMBER_OF_LIFTS,
  PAYMENT_TERM,
  PAYMENT_TERMS,
  PERCENTAGE_OF_INVOICES_FOR_SELL,
  PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
  PERFORM_TUV_EXAMINATIONS,
  PURPOSE_STEP,
  RECEIVABLES_ASSIGNED_ELSEWHERE,
  SHARE_OF_INSURANCE_CLAIMS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS,
  SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
  SHARE_OF_SALES_FOREIGN_CUSTOMERS,
  SHARE_OF_SALES_MAIN_CUSTOMERS,
  TRADE_CREDIT_INSURANCE,
  VOLUME_OF_SALES,
} from 'onlinefactoring/formFields';

import FurtherNeedsValuesMap from './FurtherNeedsValuesMap';
import GuarantyTypeValuesMap from './GuarantyTypeValuesMap';

const partnerHiddenFields: Record<string, (lane: InquiryLane) => string[]> = {
  dvag: () => [
    VEHICLE_INTERESTED_IN_INSURANCE,
    MACHINE_INTERESTED_IN_INSURANCE,
    USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
    FAVORED_DECISION_DATE,
    INTERESTED_IN_INSURANCE,
    LEASING_INTERESTED_IN_CREDIT_FINANCING,
    HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
    LEASING_TAKEOVER,
  ],
  crefofactoring: (lane: InquiryLane) =>
    lane === InquiryLane.lead ? [USER_PROFILE_BIRTHDATE] : [],
  bfss: () => [PARTNER_SPECIFIC_IBAN],
};

const isGuarantyTypeSelected = (formValues: any) => {
  const guarantyTypeValues = [
    GUARANTY_RENTAL_DEPOSIT,
    GUARANTY_PARTIAL_RETIREMENT_ACCOUNTS,
    GUARANTY_PERFORMANCE_OF_CONTRACT,
    GUARANTY_DEPOSIT_AND_ADVANCE_PAYMENTS,
    GUARANTY_WARRANTY,
    GUARANTY_LITIGATION_BOND,
  ];
  let guarantyType = guarantyTypeValues.find((key: string) => formValues[key] && key);
  if (guarantyType) {
    let translatedValue = GuarantyTypeValuesMap(guarantyType);
    return (formValues[GUARANTY_TYPE] = translatedValue);
  }
};

const isFurtherNeedSelected = (formValues: any) => {
  const isFurtherNeed =
    !!formValues[FURTHER_NEED] || !!formValues[BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES];

  const furtherNeedvalues = [
    PUBLIC_FUNDING,
    FUNDRAISING,
    PRODUCT_KIND__LEASING,
    PRODUCT_KIND__FACTORING,
    OTHER_FINANCING_SOLUTIONS,
    EXPERT_ADVICE_ON_ALL,
    PAYMENT_TRANSACTIONS,
    INVESTMENT_AND_SECURITIES,
  ];
  let furtherNeeds: string = '';
  if (isFurtherNeed) {
    furtherNeeds = Object.values(furtherNeedvalues)
      .filter((key: string) => formValues[key] && key)
      .map(FurtherNeedsValuesMap)
      .join(', ');
  }
  return (formValues[FURTHER_NEED_VALUES] = furtherNeeds);
};

const areReceivablesAlreadyAssigned = (formValues: any) => {
  const areReceivablesAssigned = !!formValues[BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES];

  const furtherNeedvalues = [
    BFS_SERVICE__EXPLICIT_NEED_BILLING_SOFTWARE,
    BFS_SERVICE__EXPLICIT_NEED_CURRENT_ACCOUNT_AND_PAYMENT,
    BFS_SERVICE__EXPLICIT_NEED_EXPERT_ADVICE,
    BFS_SERVICE__EXPLICIT_NEED_FACTORING_EXCHANGE_SERVICE,
    BFS_SERVICE__EXPLICIT_NEED_OTHER_FINANCING_SOLUTIONS,
    BFS_SERVICE__EXPLICIT_NEED_SEMINARS,
  ];
  let furtherNeeds: string = '';
  if (areReceivablesAssigned) {
    furtherNeeds = Object.values(furtherNeedvalues)
      .filter((key: string) => formValues[key] && key)
      .map(FurtherNeedsValuesMap)
      .join(', ');
  }
  return (formValues[FURTHER_NEED_VALUES] = furtherNeeds);
};

const chooseBasedOnPurpose = (
  formValues: any,
  map: { [purpose in PurposeKind | 'default']?: string[] },
) => {
  const purpose = formValues[PURPOSE_KIND] as PurposeKind;
  isGuarantyTypeSelected(formValues);
  return map[purpose] ?? map.default ?? [];
};

const chooseBasedOnProduct = (
  formValues: any,
  map: { [product in ProductKindType]?: string[] },
) => {
  const product = formValues[PRODUCT_KIND] as ProductKindType;

  if (product === PRODUCT_KIND__BFSS) {
    areReceivablesAlreadyAssigned(formValues);
  } else {
    isFurtherNeedSelected(formValues);
  }

  return map[product] ?? [];
};

const chooseBasedOnPartner = (fields: string[], partnerName: string, lane: InquiryLane) =>
  fields.filter((item: string) =>
    partnerHiddenFields[partnerName]
      ? !partnerHiddenFields[partnerName](lane).includes(item)
      : item,
  );

type SummaryStepFieldsMap = {
  [stepName: string]: (formValues: any) => string[];
};

export const summaryStepFieldsMap: SummaryStepFieldsMap = {
  [FINANCING_NEED]: (formValues) =>
    chooseBasedOnPurpose(formValues, {
      [PURPOSE_KIND__OTHER]: [FINANCING_AMOUNT, PURPOSE_KIND, OTHER_PURPOSE_DESCRIPTION],
      [PURPOSE_KIND__OFFICE_EQUIPMENT]: [
        PURPOSE_KIND,
        OFFICE_EQUIPMENT_PRICE,
        OFFICE_EQUIPMENT_DESCRIPTION,
      ],
      [PURPOSE_KIND__MACHINE]: [
        PURPOSE_KIND,
        MACHINE_DESCRIPTION,
        MACHINE_CATEGORY,
        MACHINE_PRICE,
        MACHINE_PERIOD_OF_USE,
        MACHINE_KIND,
        MACHINE_MANUFACTURER,
        MACHINE_NEW,
        MACHINE_AGE,
        MACHINE_ORIGINAL_PRICE,
        MACHINE_IS_ORDERED,
        MACHINE_DELIVERY_DATE,
        MACHINE_COUNT,
      ],
      [PURPOSE_KIND__KFINANZ]: [
        NUMBER_OF_LIFTS,
        MASTER_CRAFTSMAN,
        SHARE_OF_SALES_BUSINESS_CUSTOMERS_STEP,
        PERFORM_TUV_EXAMINATIONS,
        SHARE_OF_INSURANCE_CLAIMS,
        PERCENTAGE_OF_INVOICES_FOR_SELL,
        PERCENTAGE_OF_SERVICES_PAID_BY_INVOICE,
        INVOICES_PER_YEAR,
        PAYMENT_TERMS,
        ALREADY_ASSIGNED,
        BUSINESS_SPECIFICS,
      ],
      [PURPOSE_KIND__CREFO_FACTORING]: [
        VOLUME_OF_SALES,
        SHARE_OF_SALES_BUSINESS_CUSTOMERS,
        SHARE_OF_SALES_MAIN_CUSTOMERS,
        SHARE_OF_SALES_FOREIGN_CUSTOMERS,
        NUMBER_OF_CUSTOMERS,
        NUMBER_OF_INVOICES,
        PAYMENT_TERM,
        ALREADY_FACTORING,
        RECEIVABLES_ASSIGNED_ELSEWHERE,
        DOWN_PAYMENT,
        TRADE_CREDIT_INSURANCE,
        BUSINESS_SPECIFICS,
      ],
      [PURPOSE_KIND__ENERGY_EFFICIENCY]: [
        CLIENT_ROLE,
        CLIENT_ROLE__COMPANY,
        CLIENT_ROLE__CONTRACTOR,
        FINANCING_AMOUNT,
        NUMBER_OF_TECHNOLOGIES,
        MACHINE_CUSTOM_CATEGORY,
        MACHINE_PRICE,
        MACHINE_PERIOD_OF_USE,
        MACHINE_MANUFACTURER,
        MACHINE_KIND,
        MACHINE_NEW,
        MACHINE_AGE,
        MACHINE_IS_ORDERED,
        MACHINE_DELIVERY_DATE,
        TECHNOLOGY_DESCRIPTION,
      ],
      [PURPOSE_KIND__REAL_ESTATE]: [
        PURPOSE_KIND,
        REAL_ESTATE_PROJECT,
        REAL_ESTATE_KIND,
        REAL_ESTATE_SCHEDULED_DATE,
        REAL_ESTATE_LAND_SIZE,
        REAL_ESTATE_CONSTRUCTION_DATE,
        REAL_ESTATE_BUILDING_SIZE,
        REAL_ESTATE_HERITABLE_BUILDING_RIGHT,
        REAL_ESTATE_HERITABLE_BUILDING_RIGHT_OBLIGATION,
        REAL_ESTATE_RENTED_OUT_AREA,
        REAL_ESTATE_COLD_RENT,
        REAL_ESTATE_ZIP_CODE,
        REAL_ESTATE_USAGE_KIND,
        REAL_ESTATE_TAXATION,
        REAL_ESTATE_PURCHASE_PRICE,
        REAL_ESTATE_FOLLOW_UP_AMOUNT,
        REAL_ESTATE_RECONSTRUCTION_COST,
        REAL_ESTATE_TOTAL_COST,
        REAL_ESTATE_PROJECT_DESCRIPTION,
        REAL_ESTATE_PROJECT_DEVELOPMENT_AMOUNT,
        REAL_ESTATE_PROJECT_DEVELOPMENT_DESCRIPTION,
        REAL_ESTATE_PLANNED_START_OF_CONSTRUCTION,
      ],
      [PURPOSE_KIND__GOODS]: [
        FINANCING_AMOUNT,
        PURPOSE_KIND,
        GOODS__PURCHASE_PRICE,
        GOODS__DESCRIPTION,
        GOODS__SUPPLIER,
        GOODS__ESTIMATED_DELIVERY_DATE,
      ],
      [PURPOSE_KIND__BUILDING]: [
        PURPOSE_KIND,
        BUILDING_PROJECT,
        BUILDING_TYPE,
        BUILDING_TYPE_DESCRIPTION,
        BUILDING_COOWNERSHIP,
        BUILDING_CONDOMINIUM_NUM,
        BUILDING_NUMBER_OF_RESIDENTIAL_UNITS,
        BUILDING_TOTAL_USABLE_SPACE,
        BUILDING_LIVING_SPACE,
        BUILDING_COMMERCIAL_SPACE,
        BUILDING_SHARE_OF_COMMERCIAL_SPACE,
        BUILDING_TYPE_OF_USE,
        BUILDING_COLD_RENT_PER_YEAR_COMMERCIAL,
        BUILDING_RENTED_SPACE_COMMERCIAL,
        BUILDING_COLD_RENT_PER_YEAR_LIVING,
        BUILDING_RENTED_SPACE_LIVING,
        BUILDING_CONSTRUCTION_YEAR,
        BUILDING_LATEST_SUBSTENCIAL_MODERNISATION,
        BUILDING_STREET_AND_HOUSE_NUMBER,
        BUILDING_POSTAL_CODE,
        BUILDING_PLACE,
        BUILDING_PURCHASE_PRICE,
        BUILDING_PLOT_PRICE,
        BUILDING_ADDITIONAL_COSTS,
        BUILDING_CONSTRUCTION_MANUFACTURE_COST,
        BUILDING_MODERNISATION_COSTS,
        BUILDING_DEBT_REDEMPTION,
        BUILDING_EQUITY,
        BUILDING_PERSONAL_CONTRIBUTION,
        BUILDING_PROPERTY,
        BUILDING_BORROWED_CAPITAL,
        BUILDING_DEBT_CAPITAL_OTHER,
      ],
      [PURPOSE_KIND__DEBT_RESCHEDULING]: [
        FINANCING_AMOUNT,
        PURPOSE_KIND,
        DEBT_RESCHEDULING_DESCRIPTION,
      ],
      [PURPOSE_KIND__CORONA]: [
        PURPOSE_KIND,
        CORONA_FOUNDING_YEAR,
        CORONA_FOUNDING_MONTH,
        CORONA_VIABILTIY,
        CORONA_VIABILTIY_2020,
        CORONA_NUMBER_OF_EMPLOYEES,
        CORONA_REVENUE_OF_2019,
        CORONA_FINANCING_OPTION,
        CORONA_FINANCING_OPTION__QUICK_CREDIT,
        CORONA_FINANCING_OPTION__KFW_PROGRAM,
        CORONA_TOTAL_ASSETS,
        CORONA_EXPENDITURE_OF_EMPLOYEES,
        CORONA_LIQUIDITY_NEED,
      ],
      [PURPOSE_KIND__GUARANTY]: [
        PURPOSE_KIND,
        GUARANTY_DESCRIPTION,
        GUARANTY_OTHER_TYPE,
        GUARANTY_TYPE,
      ],
      [PURPOSE_KIND__OVERDRAFT_LIQUIDITY]: [PURPOSE_KIND, FINANCING_AMOUNT, LIQUIDITY_DESCRIPTION],
      [PURPOSE_KIND__PROJECT_FINANCING]: [
        PROJECT_FINANCING_ROLE,
        PROJECT_FINANCING_INVESTMENT_AMOUNT,
        PROJECT_FINANCING_OWN_FUNDS,
        PROJECT_FINANCING_MEZZANINE,
        PROJECT_FINANCING_OWN_WORK,
        PROJECT_FINANCING_SUBORDINATED_CAPITAL,
        PROJECT_FINANCING_OBJECT_ADDRESS,
        PROJECT_FINANCING_OBJECT_ZIP_CODE,
        PROJECT_FINANCING_OBJECT_CITY,
        PROJECT_FINANCING_OBJECT_DESCRIPTION,
        PROJECT_FINANCING_LOT_SIZE,
        PROJECT_FINANCING_TYPE_OF_USAGE,
        PROJECT_FINANCING_TYPE_OF_USAGE_DETAILS,
        PROJECT_FINANCING_USAGE_SPACE,
        PROJECT_FINANCING_PERCENTAGE_OF_RENT,
        PROJECT_FINANCING_RENT_NOW,
        PROJECT_FINANCING_RENT_FUTURE,
        PROJECT_FINANCING__PLANNED_SALES_PRICE_PER_UNIT,
        PROJECT_FINANCING_LIST_OF_RENTERS,
        PROJECT_FINANCING_KNOWS_ANNUAL_RENT,
        PROJECT_FINANCING_ANNUAL_RENT_AMOUNT,
        PROJECT_FINANCING_PLAN_TO_INCREASE_ANNUAL_RENT,
        PROJECT_FINANCING_DESCRIPTION_OF_THE_MEASURE,
        PROJECT_FINANCING_ANCHOR_TENANT,
        PROJECT_FINANCING_RENTAL_CONTRACTS,
        PROJECT_FINANCING_RENOVATION_PLANNED,
        PROJECT_FINANCING_RENOVATION_DESCRIPTION,
        PROJECT_FINANCING_DOCUMENTS_ARE_AVAILABLE,
        PROJECT_FINANCING_LIST_OF_CONTAMINATED_SITES_EXIST,
        PROJECT_FINANCING_HAS_BUILD_PERMIT,
        PROJECT_FINANCING__BUILDING_YEAR,
        PROJECT_FINANCING__MODERNIZATION_YEAR,
        PROJECT_FINANCING__FURNISHING_QUALITY,
        PROJECT_FINANCING__QUALITY_CONDITION,
        PROJECT_FINANCING__GUEST_BATHROOM,
        PROJECT_FINANCING__BALKONY,
        PROJECT_FINANCING__LIFT,
        PROJECT_FINANCING__CELLAR,
        PROJECT_FINANCING__NUMBER_OF_PARKING_LOTS,
        PROJECT_FINANCING__PARKING_LOTS_SALES_PRICE_PER_UNIT,
        PROJECT_FINANCING__PARKING_LOTS_RENT_NOW,
        PROJECT_FINANCING__PARKING_LOTS_RENT_FUTURE,
        PROJECT_FINANCING__GARAGES_SALES_PRICE_PER_UNIT,
        PROJECT_FINANCING__GARAGES_RENT_NOW,
        PROJECT_FINANCING__GARAGES_RENT_FUTURE,
        PROJECT_FINANCING__NUMBER_OF_GARAGES,
        PROJECT_FINANCING__NUMBER_OF_UNITS,
        PROJECT_FINANCING__OBJECT_USAGE,
        PROJECT_FINANCING__HAS_LIST_OF_BUILDING_ENCUMBRANCES,
        PROJECT_FINANCING__HAS_ENERGY_CERTIFICATE,
      ],
      [PURPOSE_KIND__VEHICLE]: [
        PURPOSE_KIND,
        VEHICLE_PURCHASE_PRICE,
        VEHICLE_COUNT,
        VEHICLE_NEW,
        VEHICLE_YEAR,
        VEHICLE_KIND,
        VEHICLE_OTHER_DESCRIPTION,
        VEHICLE_MANUFACTURER,
        VEHICLE_MODEL,
        VEHICLE_YEARLY_MILEAGE,
        VEHICLE_DESCRIPTION,
      ],
      [PURPOSE_KIND__FACTOMAT]: [
        PURPOSE_KIND_BFS_SERVICE,
        BFS_SERVICE__MONTHLY_TURNOVER,
        BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
        PRE_FINANCING_PERIOD,
        BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
        BFS_SERVICE__USE_FACTORING_AS_FINANCING,
        BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER,
        PAYMENT_TERM,
        BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
        OTHER_PURPOSE_DESCRIPTION,
      ],
      [PURPOSE_KIND__BFS_SERVICE_ONLINE_FACTORING]: [
        PURPOSE_KIND_BFS_SERVICE,
        BFS_SERVICE__COMPANY_OTHER_INDUSTRY,
        BFS_SERVICE__MONTHLY_TURNOVER,
        BFS_SERVICE__INDUSTRY_SOFTWARE_FOR_BILLING,
        BFS_SERVICE__OTHER_INDUSTRY_SOFTWARE_FOR_BILLING,
        BFS_SERVICE__PURCHASED_INVOICES_PAID_OUT_ON,
        BFS_SERVICE__USE_FACTORING_AS_FINANCING,
        BFS_SERVICE__FACTORING_PROVIDER_CUSTOMER,
        PAYMENT_TERM,
        BFS_SERVICE__ALREADY_ASSIGNED_RECEIVABLES,
        OTHER_PURPOSE_DESCRIPTION,
      ],
    }),
  [REQUEST_DETAILS]: (formValues) =>
    chooseBasedOnProduct(formValues, {
      [PRODUCT_KIND__LOAN]: [
        PRODUCT_KIND,
        FINANCING_AMOUNT,
        LOAN_TYPE,
        LOAN_TERM,
        LOAN_FIXED_INTEREST_RATE,
        LOAN_INTEREST_PERIOD_IN_MONTHS,
        LOAN_CONSIDER_SUBSIDIES,
        LOAN_DESCRIPTION,
        FAVORED_DECISION_DATE,
        INVESTMENT_LOAN_TERM_IN_YEARS,
        FUNDING_CLOSING_DATE,
        FURTHER_NEED_VALUES,
      ],
      [PRODUCT_KIND__PROPERTY_LOAN]: [
        PRODUCT_KIND,
        PROPERTY_LOAN_KIND,
        PROPERTY_LOAN_TERM_IN_MONTHS,
        PROPERTY_LOAN_DESCRIPTION,
        FAVORED_DECISION_DATE,
      ],
      [PRODUCT_KIND__LEASING]: [
        PRODUCT_KIND,
        LEASING_ADVANCE_PAYMENT,
        LEASING_TERM_IN_MONTHS,
        LEASING_AMORTISATION_KIND,
        LEASING_RESIDUAL_VALUE,
        LEASING_TAKEOVER,
        LEASING_DESCRIPTION,
        LEASING_INTERESTED_IN_CREDIT_FINANCING,
        LEASING_INSTRESTED_IN_INSURANCE_HELPER,
        LEASING_VEHICLE_INTERESTED_IN_INSURANCE,
        LEASING_MACHINE_INTERESTED_IN_INSURANCE,
        FAVORED_DECISION_DATE,
      ],
      [PRODUCT_KIND__OVERDRAFT]: [
        PRODUCT_KIND,
        OVERDRAFT_TERMINABILITY,
        OVERDRAFT_END_AT,
        OVERDRAFT_INTERESTED_IN_ALTERNATIVE_FINANCING,
        FAVORED_DECISION_DATE,
      ],
      [PRODUCT_KIND__FACTORING]: [
        PRODUCT_KIND,
        FACTORING_TURNOVER_CLASS,
        FACTORING_ANNUAL_NET_PROFIT_OR_LOSS_CLASS,
        FACTORING_FOREIGN_SHARE,
        FACTORING_TURNOVER_SHARE_WITH_LARGEST_CUSTOMER,
        FACTORING_AVERAGE_PERIOD_OF_PAYMENT,
        FACTORING_COMMERCIAL_CREDIT_INSURANCE,
        FACTORING_ACTIVE_CUSTOMERS,
        FACTORING_BILLS_RANGE,
        FACTORING_ALREADY_IN_USE,
        FACTORING_ALREADY_ON_HAND_AT_FACTORING_COMPANY,
        FACTORING_ADDITIONAL_INFORMATION_FOR_COMPEON,
        FACTORING_ADDITIONAL_INFORMATION_FOR_BANKS,
        FAVORED_DECISION_DATE,
      ],
      [PRODUCT_KIND__OTHER]: [PRODUCT_KIND, OTHER_PRODUCT_DESCRIPTION, FAVORED_DECISION_DATE],
      [PRODUCT_KIND__MEZZANINE]: [PRODUCT_KIND, MEZZANINE_DESCRIPTION, FAVORED_DECISION_DATE],
      [PRODUCT_KIND__PROJECT_FINANCING]: [
        PRODUCT_KIND,
        PROJECT_FINANCING_INTERESTED_IN_ALTERNATIVE_FINANCING,
        FAVORED_DECISION_DATE,
      ],
      [PRODUCT_KIND__AVAL]: [
        PRODUCT_KIND,
        FINANCING_AMOUNT,
        FUNDING_CLOSING_DATE,
        FURTHER_NEED_VALUES,
      ],
      [PRODUCT_KIND__INVESTMENT_LOAN]: [
        PRODUCT_KIND,
        FINANCING_AMOUNT,
        INVESTMENT_LOAN_ADVANCE_PAYMENT,
        INVESTMENT_LOAN_TERM_IN_MONTHS,
        INVESTMENT_LOAN_TERM_IN_YEARS,
        INVESTMENT_LOAN_AMORTISATION_KIND,
        INVESTMENT_LOAN_AMORTISATION_KIND__FULL_AMORTISATION,
        INVESTMENT_LOAN_AMORTISATION_KIND__PARTIAL_AMORTISATION,
        INVESTMENT_LOAN_RESIDUAL_VALUE,
        INVESTMENT_LOAN_DESCRIPTION,
        INVESTMENT_LOAN_INTERESTED_IN_OTHER_PRODUCTS,
        FAVORED_DECISION_DATE,
        FUNDING_CLOSING_DATE,
        FURTHER_NEED_VALUES,
      ],
      [PRODUCT_KIND__HIRE_PURCHASE]: [
        PRODUCT_KIND,
        HIRE_PURCHASE_ADVANCE_PAYMENT,
        HIRE_PURCHASE_TERM_IN_MONTHS,
        HIRE_PURCHASE_AMORTISATION_KIND,
        HIRE_PURCHASE_AMORTISATION_KIND__FULL_AMORTISATION,
        HIRE_PURCHASE_AMORTISATION_KIND__PARTIAL_AMORTISATION,
        HIRE_PURCHASE_RESIDUAL_VALUE,
        HIRE_PURCHASE_DESCRIPTION,
        HIRE_PURCHASE_INTERESTED_IN_INVESTMENT_LOAN,
        FAVORED_DECISION_DATE,
      ],
      [PRODUCT_KIND__LIQUIDITY]: [
        PRODUCT_KIND,
        FINANCING_AMOUNT,
        FUNDING_CLOSING_DATE,
        FURTHER_NEED_VALUES,
      ],
      [PRODUCT_KIND__CREFO_FACTORING]: [
        OFFER_FACTORING_LINE,
        OFFER_FACTORING_FEE,
        OFFER_PAYOUT_RATIO,
      ],
      [PRODUCT_KIND__KFINANZ]: [OFFER_FACTORING_FEE, OFFER_FACTORING_LINE, OFFER_PAYOUT_RATIO],
      [PRODUCT_KIND__BFSS]: [
        OFFER_FACTORING_FEE,
        OFFER_FACTORING_LINE,
        OFFER_PAYOUT_RATIO,
        BFS_SERVICE__FACTORING_OFFER,
        BFS_SERVICE__SOURCE,
        FURTHER_NEED_VALUES,
      ],
    }),
  [USER_PROFILE]: () => [
    COMPANY_DETAILS_GENDER,
    COMPANY_DETAILS_FIRST_NAME,
    COMPANY_DETAILS_LAST_NAME,
    COMPANY_DETAILS_EMAIL,
  ],
  [COMPANY_DETAILS]: (formValues) =>
    chooseBasedOnPurpose(formValues, {
      default: [
        USER_PROFILE_LEGAL_FORM,
        USER_PROFILE_INDUSTRY,
        BFS_USER_PROFILE_INDUSTRY,
        USER_PROFILE_TURNOVER_CLASS,
        USER_PROFILE_FOUNDING_YEAR,
        USER_PROFILE_FOUNDING_MONTH,
        USER_PROFILE_COMPANY_ALREADY_CUSTOMER,
        USER_PROFILE_ADDITIONAL_ASSOCIATION_COMPANY,
        PARTNER_SPECIFIC_IBAN,
        BANK_DETAILS_IBAN,
        COMPANY_DETAILS_COMPANY_TAX_NUMBER,
        COMPANY_DETAILS_INSTITUTIONAL_NUMBERS,
      ],
      [PURPOSE_KIND__KFINANZ]: [
        USER_PROFILE_LEGAL_FORM,
        USER_PROFILE_FOUNDING_TWO_YEAR_OLD,
        PARTNER_SPECIFIC_IBAN,
        BANK_DETAILS_IBAN,
      ],
    }),
  [CONTACT_PERSON_STEP]: () => [
    COMPANY_DETAILS_GENDER,
    COMPANY_DETAILS_FIRST_NAME,
    COMPANY_DETAILS_LAST_NAME,
    COMPANY_DETAILS_EMAIL,
    USER_PROFILE_PHONE_NUMBER,
  ],
  [PEOPLE_DETAILS]: () => [
    COMPANY_DETAILS_GENDER,
    COMPANY_DETAILS_FIRST_NAME,
    COMPANY_DETAILS_LAST_NAME,
    COMPANY_DETAILS_EMAIL,
    USER_PROFILE_PHONE_NUMBER,
    USER_PROFILE_BIRTHDATE,
    END_USER_GENDER,
    END_USER_FIRST_NAME,
    END_USER_LAST_NAME,
    END_USER_EMAIL,
    END_USER_PHONE_NUMBER,
  ],
  [PURPOSE_STEP]: () => [
    VOLUME_OF_SALES,
    NUMBER_OF_CUSTOMERS,
    NUMBER_OF_INVOICES,
    PAYMENT_TERM,
    ALREADY_FACTORING,
    RECEIVABLES_ASSIGNED_ELSEWHERE,
    DOWN_PAYMENT,
    TRADE_CREDIT_INSURANCE,
    BUSINESS_SPECIFICS,
  ],
  [CONTACT_PERSON_DETAILS]: () => [
    CONTACT_PERSON_FIRST_NAME,
    CONTACT_PERSON_LAST_NAME,
    CONTACT_PERSON_EMAIL,
    CONTACT_PERSON_PHONE,
  ],
  [LEGAL_REPRESENTATIVE_DETAILS]: () => [],
};

export const summaryMap = (
  stepName: string,
  formValues: any,
  partnerName: string,
  lane: InquiryLane,
) => chooseBasedOnPartner(summaryStepFieldsMap[stepName](formValues), partnerName, lane);
