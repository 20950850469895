import { useFormConfig } from 'config/formConfig/hooks';
import { fieldIsValid } from 'modules/Inquiry/Form/formConditions';
import { VM_NUMBER } from 'modules/Inquiry/Form/formFields';
import { validateNotLoggedInUserDetails } from 'modules/Inquiry/Form/Validations/default/CompanyDetails/notLoggedInUserDetails.validator';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import { validatePasswordSection } from 'pages/inquiryFlow/CompanyDetails/PasswordSection/PasswordSection';
import { validateConsentSection } from 'pages/inquiryFlow/CompanyDetails/sections/TermsAndConditions';
import { useSelectedInquiryTypeSpecificValue } from 'shared/chooseSelectedInquiryTypeSpecificComponent';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';
import { isLoggedInUser } from 'utils/user/conditionals';

const dvagValidateUserInfoSection = (form) => fieldIsValid(VM_NUMBER)({ form });

export const useCompanyDetailsSectionValidators = () => {
  const isLoggedIn = isLoggedInUser();
  const { selectedInquiryType } = useFormConfig();

  const { makeCall } = useDispatchApiCall({
    showErrorNotification: false,
    errorMessage: '',
    isPendingInitially: false,
  });

  return useSelectedInquiryTypeSpecificValue({
    [InquiryType.dvag]: () => [dvagValidateUserInfoSection],
    default: () =>
      isLoggedIn
        ? [validateConsentSection(selectedInquiryType)]
        : [
            validateNotLoggedInUserDetails(makeCall),
            validatePasswordSection,
            validateConsentSection(selectedInquiryType),
          ],
  });
};
