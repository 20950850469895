import { useCallback, useEffect } from 'react';

import normalize from 'json-api-normalizer';
import { useDispatch, useSelector } from 'react-redux';

import { usePartnerConfig } from 'config/partner/hooks';
import { PARTNERS } from 'constants/partner';
import { USER_SCOPE } from 'constants/userScopes';
import type { IFile, IPrivateFile, IInternalFile, IAssessmentFile } from 'models/File.model';
import type {
  ApiFile,
  ApiPrivateFile,
  ApiAssessmentFile,
  ApiInternalFile,
} from 'models/FileApi.model';
import { InquiryType } from 'modules/Inquiry/Inquiry.type';
import {
  mapApiFile,
  mapApiPrivateFile,
  mapApiAssessmentFile,
  mapApiInternalFile,
} from 'shared/documentExchange/documentExchange.service';
import {
  setFilesAction,
  setInternalFilesAction,
  setPrivateFilesAction,
  setAssessmentFilesAction,
  getDocumentsToDownloadAction,
  getInternalesFilesToDownloadAction,
  getPrivateFilesToDownloadAction,
  getAssessmentFilesToDownloadAction,
  getUpdatedFileAction,
  getPrivateUpdatedFileAction,
  updateFileAction,
  updatePrivateFileAction,
} from 'store/documentExchange/documentExchange.actions';
import { getInquiryIdSelector, getInquiryFormTypeSelector } from 'store/inquiryDetails/selectors';
import useDispatchApiCall from 'utils/hooks/useDispatchApiCallHook';

const mapFromApi = (rawData: any): Array<IFile> => {
  if (!rawData.data.length) {
    return [];
  }

  const normalizedResponse = normalize(rawData, { camelizeKeys: false });
  return Object.values(normalizedResponse.uploaded_files)
    .map((file) => mapApiFile(file as ApiFile))
    .filter(Boolean);
};

const mapPrivateFilesFromApi = (rawData: any): Array<IPrivateFile> => {
  if (!rawData.data.length) {
    return [];
  }

  const normalizedResponse = normalize(rawData, { camelizeKeys: false });
  return Object.values(normalizedResponse.private_uploaded_files)
    .map((file) => mapApiPrivateFile(file as ApiPrivateFile))
    .filter(Boolean);
};

const mapAssessmentFilesFromApi = (rawData: any): Array<IAssessmentFile> => {
  if (!Object.keys(rawData.data).length) {
    return [];
  }
  const normalizedResponse = normalize(rawData, { camelizeKeys: false });
  return Object.values(normalizedResponse.process_specific_uploaded_files)
    .map((file) => mapApiAssessmentFile(file as ApiAssessmentFile))
    .filter(Boolean);
};

const mapInternalFilesFromApi = (rawData: any): IInternalFile[] => {
  if (!rawData.data.length) {
    return [];
  }

  const normalizedResponse = normalize(rawData, { camelizeKeys: false });
  return Object.values(normalizedResponse.external_service_files)
    .map((file) => mapApiInternalFile(file as ApiInternalFile))
    .filter(Boolean);
};

interface IUseFilesForInquiry {
  uploadedBy?: USER_SCOPE;
}
export const useFetchInquiryFiles = ({ uploadedBy }: IUseFilesForInquiry) => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();
  const { makeCall, error, isPending } = useDispatchApiCall();

  const fetchFileListForInquiry = useCallback(
    // FIXME
    // @ts-ignore
    (id, uploadedByUser) =>
      makeCall(getDocumentsToDownloadAction(id, uploadedByUser), ({ payload }: any) => {
        const mappedResponse = mapFromApi(payload.data);
        dispatch(setFilesAction(mappedResponse));
      }),
    [dispatch, makeCall],
  );

  useEffect(() => {
    fetchFileListForInquiry(inquiryId, uploadedBy);
  }, [inquiryId, uploadedBy, fetchFileListForInquiry]);

  if (error) {
    throw error;
  }

  return { isLoading: isPending };
};

export const useFetchInquiryInternalFiles = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const inquiryType = useSelector(getInquiryFormTypeSelector);
  const dispatch = useDispatch();
  const { makeCall, error, isPending } = useDispatchApiCall();
  const {
    details: { id: partnerName },
  } = usePartnerConfig();

  const fetchFileListForInquiry = useCallback(
    (id: string) =>
      makeCall(getInternalesFilesToDownloadAction(id), ({ payload }: any) => {
        const mappedResponse = mapInternalFilesFromApi(payload.data);
        dispatch(setInternalFilesAction(mappedResponse));
      }),
    [dispatch, makeCall],
  );

  useEffect(() => {
    if (inquiryType === InquiryType.bigPicture || partnerName === PARTNERS.CREFOFACTORING) {
      fetchFileListForInquiry(inquiryId);
    }
    fetchFileListForInquiry(inquiryId);
  }, [inquiryId, fetchFileListForInquiry, inquiryType, partnerName]);

  if (error) {
    throw error;
  }

  return { isLoading: isPending };
};

export const useFetchUpdatedFile = () => {
  const dispatch = useDispatch();
  const { makeCall } = useDispatchApiCall();

  const fetchUpdatedFile = useCallback(
    (fileId: string) =>
      makeCall(getUpdatedFileAction(fileId), ({ payload }: any) => {
        dispatch(updateFileAction(mapApiFile(payload.data.data)));
      }),
    [dispatch, makeCall],
  );

  return { fetchUpdatedFile };
};

export const useFetchPrivateFiles = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();
  const { makeCall, error, isPending } = useDispatchApiCall();

  const fetchPrivateFileListForInquiry = useCallback(
    (id: string) =>
      makeCall(getPrivateFilesToDownloadAction(id), ({ payload }: any) => {
        const mappedResponse = mapPrivateFilesFromApi(payload.data);
        dispatch(setPrivateFilesAction(mappedResponse));
      }),
    [dispatch, makeCall],
  );

  useEffect(() => {
    fetchPrivateFileListForInquiry(inquiryId);
  }, [inquiryId, fetchPrivateFileListForInquiry]);

  if (error) {
    throw error;
  }

  return { isLoading: isPending };
};

export const useFetchAssessmentFiles = () => {
  const inquiryId = useSelector(getInquiryIdSelector);
  const dispatch = useDispatch();
  const { makeCall, error, isPending } = useDispatchApiCall({ showErrorNotification: false });

  const fetchAssessmentFileListForInquiry = useCallback(
    (id: string) =>
      makeCall(getAssessmentFilesToDownloadAction(id), ({ payload }: any) => {
        const mappedResponse = mapAssessmentFilesFromApi(payload.data);
        dispatch(setAssessmentFilesAction(mappedResponse));
      }),
    [dispatch, makeCall],
  );

  useEffect(() => {
    fetchAssessmentFileListForInquiry(inquiryId);
  }, [inquiryId, fetchAssessmentFileListForInquiry]);

  if (error) return;

  return { isLoading: isPending };
};

export const useFetchPrivateUpdatedFile = () => {
  const dispatch = useDispatch();
  const { makeCall } = useDispatchApiCall();

  const fetchPrivateUpdatedFile = useCallback(
    (fileId: string) =>
      makeCall(getPrivateUpdatedFileAction(fileId), ({ payload }: any) => {
        dispatch(updatePrivateFileAction(mapApiPrivateFile(payload.data)));
      }),
    [dispatch, makeCall],
  );

  return { fetchPrivateUpdatedFile };
};
